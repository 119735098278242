import { Button, Col, DatePicker, Form, Input, Pagination, Row, Select, Tag, message } from 'antd';
import {
  SearchOutlined,
} from '@ant-design/icons';
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { partnerService } from '../../services/partner.service';
import { AppState } from '../../store';
import { AccountState } from '../../store/account/types';
import { ColumnsType } from 'antd/es/table';
import { currencyFormat, formatDateTime } from '../../helpers/FunctionUtils';
import { ButtonPlatedGreenStyled, PagingStyled, TableContainer, TableContentStyled } from '../../components/CustomAntStyled';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { FaDownload } from 'react-icons/fa';
import TextTruncate from '../../components/TextTruncate';

export const PartnerTransationsPage = () => {
  const [formSearch] = Form.useForm();
  const dispatch = useDispatch();

  const accountState = useSelector<AppState>((state) => state.account) as AccountState;
  const ITEMS_PER_PAGE = 10;
  const [totalItems, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lstData, setLstData] = useState<any[]>([]);
  const columns: ColumnsType<any> = [
    {
      title: <p>Mã<br /> giao dịch</p>,
      dataIndex: 'transCodePartner',
      className: 'center-data',
      render: (transCodePartner: any) =>
        <TextTruncate text={transCodePartner} maxWords={8} />,
    },
    {
      title: <p>Loại<br /> giao dịch</p>,
      dataIndex: 'type',
      className: 'center-data',
      render: (type: any) =>
        <span>
          {
            type === 'TOPUP' ? 'Topup'
              : type === 'TOPUP_BY_FILE' ? 'Nạp file'
                : 'Mua thẻ'
          }
        </span>,
    },
    {
      title: <p>Số lượng</p>,
      dataIndex: 'quantity',
      className: 'center-data',
      render: (quantity: any) =>
        <span>{quantity}</span>,
    },
    {
      title: <p>Mệnh giá</p>,
      dataIndex: 'cardAmount',
      className: 'center-data',
      render: (cardAmount: any) =>
        <span>{currencyFormat(cardAmount, 'VND')}</span>,
    },
    {
      title: <p>Nhà mạng</p>,
      dataIndex: 'productType',
      className: 'center-data',
      render: (productType: any) =>
        <span>{productType}</span>,
    },
    {
      title: <p>SĐT<br />thụ hưởng</p>,
      dataIndex: 'phoneBeneficiary',
      className: 'center-data',
      render: (phoneBeneficiary: any, record: any) =>
        <Fragment>
          <span>
            {phoneBeneficiary}
          </span> <br />
          {record.phoneBeneficiaryType === 'PRE_PAID' ? 'Trả trước' : 'Trả sau'}
        </Fragment>,
    },
    {
      title: <p>Chiết khấu</p>,
      className: 'center-data',
      dataIndex: 'partnerDiscountPercent',
      render: (partnerDiscountPercent: any) => <span>{`${partnerDiscountPercent}%`}</span>,
    },
    {
      title: <p>Số tiền<br /> thanh toán</p>,
      dataIndex: 'partnerAmountPayment',
      className: 'center-data',
      render: (partnerAmountPayment: any) => <span>{currencyFormat(partnerAmountPayment, 'VND')}</span>,
    },
    {
      title: <p>Trạng thái</p>,
      dataIndex: 'transactionStatus',
      className: 'center-data',
      render: (transactionStatus: string, record: any) =>
        <Fragment>
          {record.evn === 'TEST' ? 'TEST' : null}<br />
          <Tag color={transactionStatus === 'THANH_CONG' || transactionStatus === 'THANH_CONG_BUT_MISS' ? 'rgb(52 166 55)'
            : transactionStatus === 'THAT_BAI' ? 'rgba(206, 206, 206, 1)'
              : 'rgb(255 181 42)'}>
            {
              transactionStatus === 'THANH_CONG' ? 'Thành công'
                : transactionStatus === 'THANH_CONG_BUT_MISS' ? 'Nạp thiếu'
                  : transactionStatus === 'THAT_BAI' ? 'Thất bại' : 'Đang xử lý'
            }
          </Tag><br />
          <TextTruncate text={record.message} maxWords={8} />
        </Fragment>
    },
    {
      title: <p>Khởi tạo</p>,
      dataIndex: 'createdDate',
      className: 'center-data',
      render: (createdDate: any) => <span>{formatDateTime(createdDate)}</span>,
    },
    {
      title: <p>Hoàn thành</p>,
      dataIndex: 'createdDate',
      className: 'center-data',
      render: (createdDate: any, record: any) =>
        <span>
          {dayjs(record.lastModifiedDate).diff(dayjs(createdDate), 'second') + ' s'}
        </span>,
    }
  ];

  const getDataList = async (page: number, productType: any, type: any, status: any, keySearch: any, tuNgay: any, denNgay: any) => {
    try {
      const response = await partnerService.filterTransForPartner(page - 1, ITEMS_PER_PAGE, accountState?.user?.id, productType, type, status, keySearch, tuNgay, denNgay)
      if (response.status === 200) {
        setTotal(response.headers['x-total-count']);
        setLstData(response.data.data);
      } else {
        message.error({ content: response.message, duration: 3 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 5 });
    }
  };

  useEffect(() => {
    const initData = async () => {
      dispatch(setLoading(true));
      try {
        formSearch.setFieldsValue({
          tuNgay: dayjs(),
          denNgay: dayjs(),
        });
        await getDataList(0, null, null, null, null, dayjs().format('DD/MM/YYYY 00:00:00'), dayjs().format('DD/MM/YYYY 00:00:00'));
      }
      catch (error: any) {
        message.error({ content: error.message as string, duration: 2 });
      } finally {
        dispatch(setLoading(false));
      }
    };
    initData();
  }, []);

  const onPageChanged = async (pageNumber: number) => {
    dispatch(setLoading(true));
    try {
      setCurrentPage(pageNumber);
      const values = await formSearch.validateFields();
      const dayCheck = dayjs(values.denNgay).diff(dayjs(values.tuNgay), 'day');
      if (dayCheck > 90) {
        message.error("Khoản thời gian tìm kiếm không được quá 90 ngày");
        return;
      }
      await getDataList(pageNumber, values.productType, values.type, values.status, values.keySearch,
        values.tuNgay.format('DD/MM/YYYY 00:00:00'), values.denNgay.format('DD/MM/YYYY 00:00:00'));
    } catch (error) {
      message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onExportExcel = async () => {
    try {
      const values = await formSearch.validateFields();
      const dayCheck = dayjs(values.denNgay).diff(dayjs(values.tuNgay), 'day');
      if (dayCheck > 90) {
        message.error("Khoản thời gian tìm kiếm không được quá 90 ngày");
        return;
      }

      const result = await Swal.fire({
        title: 'Kiểm tra dữ liệu search, xác nhận xuất theo form search',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Xuất',
        cancelButtonText: 'Hủy',
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        dispatch(setLoading(true));
        const response = await partnerService.filterTransPartnerExport(
          accountState?.user?.id, values.productType, values.type, values.status, values.keySearch,
          values.tuNgay.format('DD/MM/YYYY 00:00:00'), values.denNgay.format('DD/MM/YYYY 00:00:00')
        )
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download = `${dayjs().format('DDMMYYYYHHmmss')}.xlsx`;
          link.click();
          window.URL.revokeObjectURL(url);
        } else {
          message.error({ content: response.message, duration: 3 });
        }
      }
    } catch (error) {
      message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Fragment>
      <Form
        form={formSearch}
        layout='vertical'>
        <Row gutter={[20, 20]}>
          <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
            <Form.Item
              name="tuNgay" // Tên của trường trong form
              label='Từ ngày'
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn ngày đăng',
                }
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                showTime={false}
                inputReadOnly={true}
                size='large'
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
            <Form.Item
              name="denNgay" // Tên của trường trong form
              label='Đến ngày'
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn ngày đăng',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const tuNgayValue = getFieldValue('tuNgay');
                    if (value && value.isBefore(tuNgayValue, 'day')) {
                      return Promise.reject('Phải lớn hơn thời gian bắt đầu');
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}>
              <DatePicker
                format="DD/MM/YYYY"
                showTime={false}
                inputReadOnly={true}
                size='large'
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
            <Form.Item name="type"
              label="Loại giao dịch">
              <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                placeholder="Loại giao dịch">
                <Select.Option key={'ALL'} value='ALL'>
                  -- All --
                </Select.Option>
                <Select.Option key={'TOPUP'} value='TOPUP'>
                  Topup
                </Select.Option>
                <Select.Option key={'BUY_CARD'} value='BUY_CARD'>
                  Mua thẻ cào
                </Select.Option>
                <Select.Option key={'TOPUP_BY_FILE'} value='TOPUP_BY_FILE'>
                  Nạp file
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
            <Form.Item name="keySearch" label='Từ khóa tìm kiếm'>
              <Input size='large' placeholder='Mã GD/ SĐT' />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
            <Form.Item name="productType" label="Nhà mạng">
              <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                placeholder="Nhà mạng">
                <Select.Option key={'ALL'} value='ALL'>
                  -- All --
                </Select.Option>
                <Select.Option key={'VIETTEL'} value='VIETTEL'>
                  VIETTEL
                </Select.Option>
                <Select.Option key={'VINAPHONE'} value='VINAPHONE'>
                  VINAPHONE
                </Select.Option>
                <Select.Option key={'MOBIFONE'} value='MOBIFONE'>
                  MOBIFONE
                </Select.Option>
                <Select.Option key={'VIETNAMOBILE'} value='VIETNAMOBILE'>
                  VIETNAMOBILE
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
            <Form.Item name="status" label="Trạng thái">
              <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                placeholder="Trạng thái">
                <Select.Option key={'ALL'} value='ALL'>
                  -- All --
                </Select.Option>
                <Select.Option key={'THANH_CONG'} value='THANH_CONG'>
                  Thành công
                </Select.Option>
                <Select.Option key={'THANH_CONG_BUT_MISS'} value='THANH_CONG_BUT_MISS'>
                  Nạp thiếu
                </Select.Option>
                <Select.Option key={'DANG_XU_LY'} value='DANG_XU_LY'>
                  Đang xử lý
                </Select.Option>
                <Select.Option key={'THAT_BAI'} value='THAT_BAI'>
                  Thất bại
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end" style={{ marginBottom: '10px' }}>
          <ButtonPlatedGreenStyled style={{ marginRight: '10px' }} onClick={() => onExportExcel()} type="primary" icon={<FaDownload />}>
            Xuất excel
          </ButtonPlatedGreenStyled>
          <Button onClick={() => onPageChanged(1)} type="primary" icon={<SearchOutlined />}>
            Search
          </Button>
        </Row>
      </Form>

      <TableContainer>
        <TableContentStyled
          columns={columns}
          dataSource={lstData}
          rowKey="id"
          bordered
          pagination={false} />

        <PagingStyled>
          <Pagination
            current={currentPage}
            total={totalItems}
            pageSize={ITEMS_PER_PAGE}
            onChange={onPageChanged}
            showSizeChanger={false}
          />
        </PagingStyled>
      </TableContainer>
    </Fragment>
  )
}
