import { Button, Col, DatePicker, Flex, Form, Input, Modal, Pagination, Row, Select, Tag, message } from 'antd';
import {
    SearchOutlined,
    EyeTwoTone,
    EyeInvisibleOutlined,
    UploadOutlined
} from '@ant-design/icons';
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { AccountState } from '../../store/account/types';
import Table, { ColumnsType } from 'antd/es/table';
import { currencyFormat, formatDateTime, generateRandomString, isValidVietnamPhoneNumber } from '../../helpers/FunctionUtils';
import dayjs from 'dayjs';
import { partnerService } from '../../services/partner.service';
import { setLoading } from '../../store/loading/actions';
import { ButtonPlatedGreenStyled, ButtonYellowStyled, PagingStyled, TableContainer, TableContentStyled } from '../../components/CustomAntStyled';
import { FaDownload, FaUpload } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import Dragger from 'antd/es/upload/Dragger';
import { adminService } from '../../services/admin.service';
import Swal from 'sweetalert2';
import TextTruncate from '../../components/TextTruncate';

export const PartnerTopupByFilePage = () => {
    const [formSearch] = Form.useForm();
    const dispatch = useDispatch();

    const accountState = useSelector<AppState>((state) => state.account) as AccountState;
    const ITEMS_PER_PAGE = 10;

    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lstData, setLstData] = useState<any[]>([]);

    const columns: ColumnsType<any> = [
        {
            title: <p>Mã<br /> file</p>,
            dataIndex: 'fileCode',
            className: 'center-data',
            render: (fileCode: string) => {
                const [maFile] = fileCode.split('|');
                return (
                    <span>
                        {maFile}
                    </span>
                );
            },
        },
        {
            title: <p>Mã<br /> giao dịch</p>,
            dataIndex: 'transaction',
            className: 'center-data',
            render: (transaction: any) =>
                <span>
                    {transaction ?
                        <TextTruncate text={transaction.transCodePartner} maxWords={8} />
                        : '--'}
                </span>,
        },
        {
            title: <p>Số lượng</p>,
            dataIndex: 'transaction',
            className: 'center-data',
            render: (transaction: any) =>
                <span>
                    {transaction ? transaction.quantity : '--'}
                </span>,
        },
        {
            title: <p>Mệnh giá</p>,
            dataIndex: 'transaction',
            className: 'center-data',
            render: (transaction: any) =>
                <span>
                    {transaction ? currencyFormat(transaction.cardAmount, 'VND') : '--'}
                </span>,
        },
        {
            title: <p>Nhà mạng</p>,
            dataIndex: 'transaction',
            className: 'center-data',
            render: (transaction: any) =>
                <span>
                    {transaction ? transaction.productType : '--'}
                </span>,
        },
        {
            title: <p>SĐT<br />thụ hưởng</p>,
            dataIndex: 'phone',
            className: 'center-data',
            render: (phone: any) =>
                <span>
                    {phone}
                </span>,
        },
        {
            title: <p>Chiết khấu</p>,
            className: 'center-data',
            dataIndex: 'transaction',
            render: (transaction: any) =>
                <span>
                    {transaction ? `${transaction.partnerDiscountPercent}%` : '--'}
                </span>,
        },
        {
            title: <p>Số tiền<br /> thanh toán</p>,
            dataIndex: 'transaction',
            className: 'center-data',
            render: (transaction: any) =>
                <span>
                    {transaction ? currencyFormat(transaction.partnerAmountPayment, 'VND') : '--'}
                </span>,
        },
        {
            title: <p>Message</p>,
            dataIndex: 'transaction',
            className: 'center-data',
            render: (transaction: any, record: any) =>
                <span>
                    {transaction ?
                        <TextTruncate text={transaction.message} maxWords={8} />
                        : <TextTruncate text={record.message} maxWords={8} />}
                </span>,
        },
        {
            title: <p>Trạng thái</p>,
            dataIndex: 'transaction',
            className: 'center-data',
            render: (transaction: any, record: any) => {
                return transaction ? (
                    <Fragment>
                        <Tag
                            color={
                                transaction.transactionStatus === 'THANH_CONG' || transaction.transactionStatus === 'THANH_CONG_BUT_MISS'
                                    ? 'rgb(52 166 55)'
                                    : transaction.transactionStatus === 'THAT_BAI'
                                        ? 'rgba(206, 206, 206, 1)'
                                        : 'rgb(255 181 42)'
                            }
                        >
                            {
                                transaction.transactionStatus === 'THANH_CONG' ? 'Thành công'
                                    : transaction.transactionStatus === 'THANH_CONG_BUT_MISS' ? 'Nạp thiếu'
                                        : transaction.transactionStatus === 'THAT_BAI' ? 'Thất bại'
                                            : 'Đang xử lý'
                            }
                        </Tag>
                    </Fragment>
                ) : (
                    <Tag color='rgba(206, 206, 206, 1)'>
                        Thất bại
                    </Tag>
                );
            }
        },
        {
            title: <p>Khởi tạo</p>,
            dataIndex: 'createdDate',
            className: 'center-data',
            render: (createdDate: any) => <span>{formatDateTime(createdDate)}</span>,
        },
        {
            title: <p>Hoàn thành</p>,
            dataIndex: 'transaction',
            className: 'center-data',
            render: (transaction: any) =>
                <span>
                    {
                        transaction ? (dayjs(transaction.lastModifiedDate).diff(dayjs(transaction.createdDate), 'second') + ' s') : '0s'
                    }
                </span>,
        }
    ];

    const getDataList = async (page: number, productType: any, status: any, keySearch: any, tuNgay: any, denNgay: any) => {
        try {
            const response = await partnerService.filterTopupByFile(page - 1, ITEMS_PER_PAGE, accountState?.user?.id, productType, status, keySearch, tuNgay, denNgay)
            if (response.status === 200) {
                setTotal(response.headers['x-total-count']);
                setLstData(response.data.data);
            } else {
                message.error({ content: response.message, duration: 3 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 5 });
        }
    };

    const onPageChanged = async (pageNumber: number) => {
        dispatch(setLoading(true));
        try {
            setCurrentPage(pageNumber);
            const values = await formSearch.validateFields();
            const dayCheck = dayjs(values.denNgay).diff(dayjs(values.tuNgay), 'day');
            if (dayCheck > 90) {
                message.error("Khoản thời gian tìm kiếm không được quá 90 ngày");
                return;
            }
            await getDataList(pageNumber, values.productType, values.status, values.keySearch,
                values.tuNgay.format('DD/MM/YYYY 00:00:00'), values.denNgay.format('DD/MM/YYYY 00:00:00'));
        } catch (error) {
            message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleDownload = () => {
        dispatch(setLoading(true));
        try {
            const zipFilePath = process.env.PUBLIC_URL + '/sourceCodeExample/Topup_File_Mau.xlsx'; // Thay thế 'yourfile.zip' bằng tên thực tế của file zip của bạn
            const link = document.createElement('a');
            link.href = zipFilePath;
            link.download = 'Topup_File_Mau.xlsx'; // Thay thế 'yourfile.zip' bằng tên bạn muốn đặt cho file khi được tải về
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            message.success("Download thành công")
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        const initData = async () => {
            dispatch(setLoading(true));
            try {
                formSearch.setFieldsValue({
                    tuNgay: dayjs(),
                    denNgay: dayjs(),
                });
                await getDataList(0, null, null, null, dayjs().format('DD/MM/YYYY 00:00:00'), dayjs().format('DD/MM/YYYY 00:00:00'));
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }
        };
        initData();
    }, []);

    const [formPushTopup] = Form.useForm();
    const [showPushTopup, setShowPushTopup] = useState(false);
    const onSubmitPushTopup = async (values: any) => {
        const result = await Swal.fire({
            title: 'Xác nhận nạp theo file',
            html: '<p>Kiểm trả mạng trươc khi xác nhận</p> </br><strong style="color: #2400ff;">Tuyệt đối không được tắt trình duyệt khi đang xử lý.</strong>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok đã hiểu',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            let loadingModal;
            try {
                if (lstTopup.length === 0) {
                    message.error('Bạn chưa upload file');
                    dispatch(setLoading(false));
                    return;
                }
                // Hiển thị Modal
                loadingModal = Modal.info({
                    title: 'Quá trình nạp đang diễn ra',
                    content: (
                        <div dangerouslySetInnerHTML={{ __html: '<strong style="color: #2400ff;">Tuyệt đối không được tắt trình duyệt.</strong>' }} />
                    ),
                    maskClosable: false,
                    keyboard: false,
                    okButtonProps: { style: { display: 'none' } }, // Ẩn nút OK
                    onOk: () => { },
                    centered: true
                });

                for (let i = 0; i < lstTopup.length; i++) {
                    const topupObjec = lstTopup[i];
                    const fileCode = `${values.fileCode}|${generateRandomString(16)}|${i}|`
                    const res = await partnerService.topupItemByFile(topupObjec.productCode, topupObjec.phone, topupObjec.type, values.currentPassword, fileCode);
                    if (res.code === -1) {
                        message.error({ content: res.message as string, duration: 3 });
                        return;
                    } else {
                        if (i === 2) {
                            setShowPushTopup(false);
                        }
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    }
                }
                setShowPushTopup(false);
                onPageChanged(1);
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                if (loadingModal) {
                    loadingModal.destroy();
                }
                dispatch(setLoading(false));
            }
        }
    };

    const [lstTopup, setLstTopup] = useState<any[]>([]);
    const excelFileAccept = '.xlsx';
    const uploadProps = {
        name: 'file',
        multiple: false,
        accept: excelFileAccept
    };

    const handleBeforeUpload = async (file: File) => {
        setLstTopup([]);
        dispatch(setLoading(true));
        const response = await adminService.filterProductByPartner(0, 9999, accountState?.user?.id, null, null, 'ACTIVE')
        let productCodeList: string[] = [];
        if (response.status === 200) {
            productCodeList = response.data.data.map((item: any) => item.productRoot.productCode);
            if (productCodeList.length === 0) {
                message.error('Tài khoản chưa được dùng dịch vụ nào');
                dispatch(setLoading(false));
                return;
            }
        } else {
            message.error({ content: response.message, duration: 5 });
            dispatch(setLoading(false));
            return;
        }

        const reader = new FileReader();
        let msg = ''
        reader.onload = (e: ProgressEvent<FileReader>): void => {
            const data = e.target?.result;
            if (data) {
                const workbook = XLSX.read(data as string, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
                const newLstCustomer = excelData.slice(1).map((row: any, rowIndex: number) => {
                    const productCode = row[0]?.trim();

                    if (productCode === '') {
                        msg = msg + `Lỗi dòng ${rowIndex + 2}: Mã sản phẩm rỗng </br>`
                    }

                    const phone = row[1]?.trim();
                    if (phone === '' || !isValidVietnamPhoneNumber(phone)) {
                        msg = msg + `Lỗi dòng ${rowIndex + 2}: Số điện thoại không hợp lệ </br>`
                    }

                    const type = row[2]?.trim()
                    if (type === '' || (type !== 'PRE_PAID' && type !== 'POST_PAID')) {
                        msg = msg + `Lỗi dòng ${rowIndex + 2}: Loại thuê bao không đúng định dạng chọn 'PRE_PAID' hoặc 'POST_PAID'</br>`
                    }

                    // Kiểm tra xem productCode có tồn tại trong productCodeList hay không
                    const isProductCodeExist = productCodeList.includes(productCode);
                    if (isProductCodeExist) {
                        return {
                            productCode: productCode,
                            phone: row[1],
                            type: type
                        };
                    } else {
                        msg = msg + `Lỗi dòng ${rowIndex + 2}: Mã sản phẩm không tồn tại </br>`
                        return null;
                    }
                });

                if (msg !== '') {
                    Swal.fire({
                        icon: "error",
                        title: "Lỗi file upload",
                        html: msg
                    });
                    dispatch(setLoading(false));
                    return;
                }
                setLstTopup(newLstCustomer);
            }
        };
        reader.readAsBinaryString(file);
        dispatch(setLoading(false));
        return false;
    };

    const columnsTopup: ColumnsType<any> = [
        {
            title: <p>Mã sản phẩm</p>,
            dataIndex: 'productCode',
            render: (productCode: string) => <a>{productCode}</a>,
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            render: (phone: string) =>
                <span>{phone}</span>,
        },
        {
            title: 'Loại thuê báo',
            dataIndex: 'type',
            render: (type: string) =>
                <span>{type}</span>,
        }
    ];

    return (
        <Fragment>
            <Modal
                title={'Đẩy file Topup By File'}
                open={showPushTopup}
                onCancel={() => setShowPushTopup(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formPushTopup.submit()} type="primary">Xác nhận</Button>
                        <Button onClick={() => setShowPushTopup(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}>
                <Form form={formPushTopup}
                    layout="vertical"
                    onFinish={onSubmitPushTopup}>
                    <Form.Item
                        name="fileCode"
                        label="Mã file (Đối tác nhập vào mã file phải là duy nhất)"
                        rules={[
                            { required: true, message: 'Nhập mã file!' },
                            { pattern: /^[a-zA-Z0-9_]+$/, message: 'Không được chứa ký tự đặc biệt và khoảng trắng!' },
                            { min: 5, message: 'Tối thiểu 5 ký tự!' }
                        ]}>
                        <Input
                            size="large"
                            placeholder='Mã file là duy nhất'
                        />
                    </Form.Item>

                    <Form.Item
                        name="currentPassword"
                        label="Xác nhận mật khẩu"
                        rules={[
                            { required: true, message: 'Nhập mật khẩu xác nhận!' },
                            {
                                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
                                message: 'Mật khẩu phải trên 8 ký tự và phải chứa chữ và số!',
                            },
                        ]}>
                        <Input.Password
                            size="large"
                            placeholder='Mật khẩu hiện tại'
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>

                    <Form.Item name='fileTopup'>
                        <Dragger {...uploadProps}
                            beforeUpload={handleBeforeUpload}
                            maxCount={1}
                            onRemove={() => setLstTopup([])}
                            name='fileTopup'>
                            <p className='ant-upload-drag-icon'>
                                <UploadOutlined />
                            </p>
                        </Dragger>
                    </Form.Item>
                    <Table
                        columns={columnsTopup}
                        dataSource={lstTopup}
                        pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30'] }}
                    />
                </Form>
            </Modal>

            <Form
                form={formSearch}
                layout='vertical'>
                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item
                            name="tuNgay" // Tên của trường trong form
                            label='Từ ngày'
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn ngày đăng',
                                }
                            ]}
                        >
                            <DatePicker
                                format="DD/MM/YYYY"
                                showTime={false}
                                inputReadOnly={true}
                                size='large'
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item
                            name="denNgay" // Tên của trường trong form
                            label='Đến ngày'
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn ngày đăng',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const tuNgayValue = getFieldValue('tuNgay');
                                        if (value && value.isBefore(tuNgayValue, 'day')) {
                                            return Promise.reject('Phải lớn hơn thời gian bắt đầu');
                                        } else {
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}>
                            <DatePicker
                                format="DD/MM/YYYY"
                                showTime={false}
                                inputReadOnly={true}
                                size='large'
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item name="productType" label="Nhà mạng">
                            <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                                placeholder="Nhà mạng">
                                <Select.Option key={'ALL'} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'VIETTEL'} value='VIETTEL'>
                                    VIETTEL
                                </Select.Option>
                                <Select.Option key={'VINAPHONE'} value='VINAPHONE'>
                                    VINAPHONE
                                </Select.Option>
                                <Select.Option key={'MOBIFONE'} value='MOBIFONE'>
                                    MOBIFONE
                                </Select.Option>
                                <Select.Option key={'VIETNAMOBILE'} value='VIETNAMOBILE'>
                                    VIETNAMOBILE
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item name="status" label="Trạng thái">
                            <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                                placeholder="Trạng thái">
                                <Select.Option key={'ALL'} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'THANH_CONG'} value='THANH_CONG'>
                                    Thành công
                                </Select.Option>
                                <Select.Option key={'THANH_CONG_BUT_MISS'} value='THANH_CONG_BUT_MISS'>
                                    Nạp thiếu
                                </Select.Option>
                                <Select.Option key={'DANG_XU_LY'} value='DANG_XU_LY'>
                                    Đang xử lý
                                </Select.Option>
                                <Select.Option key={'THAT_BAI'} value='THAT_BAI'>
                                    Thất bại
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item name="keySearch" label='Từ khóa tìm kiếm'>
                            <Input size='large' placeholder='Mã File/ SĐT' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end" style={{ marginBottom: '10px' }}>
                    <ButtonPlatedGreenStyled style={{ marginRight: '10px' }} onClick={() => setShowPushTopup(true)} icon={<FaUpload />}>
                        Đẩy file Topup
                    </ButtonPlatedGreenStyled>
                    <ButtonYellowStyled style={{ marginRight: '10px' }} onClick={handleDownload} type="primary" icon={<FaDownload />}>
                        Lấy file mẫu
                    </ButtonYellowStyled>
                    <Button onClick={() => onPageChanged(1)} type="primary" icon={<SearchOutlined />}>
                        Search
                    </Button>
                </Row>
            </Form>

            <TableContainer>
                <TableContentStyled
                    columns={columns}
                    dataSource={lstData}
                    rowKey="id"
                    bordered
                    pagination={false} />

                <PagingStyled>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={ITEMS_PER_PAGE}
                        onChange={onPageChanged}
                        showSizeChanger={false}
                    />
                </PagingStyled>
            </TableContainer>
        </Fragment>
    )
}