import React, { Fragment, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { login as loginAction, logout } from '../../store/account/actions';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { partnerService } from '../../services/partner.service';
import { AppState } from '../../store';
import "./LoginStype.css";
import { InputOTP } from 'antd-input-otp';
import ReCAPTCHA from 'react-google-recaptcha';

export const Login: React.FC = () => {
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);

  const dispatch = useDispatch();
  // dispatch(setLoading(false));

  const [formLogin] = Form.useForm();
  const [formRegister] = Form.useForm();
  const [isLogin, setIsLogin] = useState(true);
  const error = useSelector<AppState>((state) => state.account.error);
  const userName = useSelector<AppState>((state) => state.account.userName) as string;
  const pwd = useSelector<AppState>((state) => state.account.password) as string;

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    const signup = document.querySelector(".signup") as HTMLButtonElement;
    const login = document.querySelector(".login") as HTMLButtonElement;
    const slider = document.querySelector(".slider") as HTMLElement;
    const formSection = document.querySelector(".form-section") as HTMLElement;

    const handleSignupClick = () => {
      slider?.classList.add("moveslider");
      formSection?.classList.add("form-section-move");
      setIsLogin(false);
    };

    const handleLoginClick = () => {
      slider?.classList.remove("moveslider");
      formSection?.classList.remove("form-section-move");
      setIsLogin(true);
    };

    signup?.addEventListener("click", handleSignupClick);
    login?.addEventListener("click", handleLoginClick);

    // Cleanup the event listeners when the component is unmounted
    return () => {
      signup?.removeEventListener("click", handleSignupClick);
      login?.removeEventListener("click", handleLoginClick);
    };
  }, []);

  const onSubmitRegister = async (values: any) => {
    if (!captchaValue) {
      message.error({ content: 'Please complete the reCAPTCHA', duration: 2 });
      return;
    };

    dispatch(setLoading(true));
    try {
      const response = await partnerService.register(values.nameLogin, values.fullName, values.email, values.phone, values.password)
      if (response.code === 0) {
        message.success({ content: response.message, duration: 10 })
        formRegister.resetFields();
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        message.error({ content: response.message, duration: 2 });
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      message.error({ content: error.message as string, duration: 1 });
    }
  };

  const onSubmitLogin = async (values: any) => {
    if (!captchaValue) {
      message.error({ content: 'Please complete the reCAPTCHA', duration: 2 });
      return;
    };
    dispatch(setLoading(true));
    const login = loginAction(values.nameLogin, values.password, '');
    await login(dispatch);
    dispatch(setLoading(false));
  };

  const handleClickForgotPassword = () => {
    message.warning("Tính năng đang được phát triển");
  };

  const [form] = Form.useForm();
  const handleFinish = async (values: any) => {
    dispatch(setLoading(true));
    const login = loginAction(userName, pwd, values.otp.join(''));
    await login(dispatch);
    dispatch(setLoading(false));
  };

  return (
    <div className='login-body' id='login-body'>
      <img src='/imgs/logo_full.png' alt='icon' style={{ width: '200px' }} />
      {!userName ?
        (
          <Fragment>
            <div className="container">
              <div className="slider" />
              <div className="btn-lol">
                <button className="login">Login</button>
                <button className="signup">Register</button>
              </div>
              <div className="form-section">
                <Form form={formLogin}
                  onFinish={onSubmitLogin}>
                  <div className="login-box">
                    <>
                      {error && (
                        <div className="alert alert-danger" role="alert">
                          {error.toString()}
                        </div>
                      )}
                    </>
                    <Form.Item
                      name="nameLogin"
                      rules={[
                        { required: true, message: 'Nhập tên đăng nhập!' },
                        {
                          pattern: /^[a-zA-Z0-9._]+$/,
                          message: 'Tên đăng nhập không được chứa khoảng trắng và ký tự đặc biệt!',
                        },
                      ]}>
                      <Input className='email ele' placeholder="Tên đăng nhập" autoComplete="off" />
                    </Form.Item>

                    <Form.Item
                      name="password"
                      rules={[
                        { required: true, message: 'Nhập password!' },
                      ]}>
                      <Input.Password
                        className='password ele'
                        size="large"
                        placeholder='Mật khẩu'
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      />
                    </Form.Item>
                    <ReCAPTCHA style={{ textAlign: 'left' }} sitekey="6LdLIboqAAAAAMgsr4sluciGlWOKWlWi3_mgtCkA" onChange={value => setCaptchaValue(value)} />
                    <div style={{ width: '90%' }} className="d-between">
                      <Checkbox>
                        <p>Remember me</p>
                      </Checkbox>
                      <div>
                        <p style={{ color: 'blue', cursor: 'pointer' }} onClick={handleClickForgotPassword}>
                          Forgot Password
                        </p>
                      </div>
                    </div>
                    <button type="submit" className="clkbtn">Login</button>
                  </div>
                </Form>

                <Form form={formRegister}
                  onFinish={onSubmitRegister}>
                  <div className="signup-box">
                    <Form.Item
                      name="nameLogin"
                      rules={[
                        { required: true, message: 'Nhập tên đăng nhập!' },
                        {
                          pattern: /^[a-zA-Z0-9._]+$/,
                          message: 'Tên đăng nhập không được chứa khoảng trắng và ký tự đặc biệt!',
                        },
                        {
                          min: 6,
                          message: 'Tên đăng nhập phải có ít nhất 6 ký tự!',
                        },
                      ]}>
                      <Input className='email ele' placeholder="Tên đăng nhập" autoComplete="off" />
                    </Form.Item>

                    <Form.Item
                      name="fullName"
                      rules={[
                        { required: true, message: 'Nhập Tên đối tác!' }
                      ]}>
                      <Input className='email ele' placeholder="Tên đối tác" autoComplete="off" />
                    </Form.Item>

                    {isLogin === false && (
                      <Fragment>
                        <Form.Item
                          name="email"
                          rules={[
                            { required: true, message: 'Nhập Email nhận thông báo!' },
                            {
                              type: 'email',
                              message: 'Email không hợp lệ. Vui lòng kiểm tra lại!',
                            },
                          ]}>
                          <Input className='email ele' placeholder="Email nhận thông báo" autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                          name="phone"
                          rules={[
                            { required: true, message: 'Nhập Số điện thoại!' },
                            {
                              pattern: /^(0[1-9])+([0-9]{8})\b/,
                              message: 'Số điện thoại không hợp lệ. Vui lòng kiểm tra lại!',
                            },
                          ]}>
                          <Input className='email ele' placeholder="Số điện thoại" autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                          name="password"
                          rules={[
                            { required: true, message: 'Nhập mật khẩu!' },
                            {
                              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
                              message: 'Mật khẩu phải trên 8 ký tự và phải chứa chữ và số!',
                            },
                          ]}>
                          <Input.Password
                            className='password ele'
                            size="large"
                            placeholder='Mật khẩu'
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                          />
                        </Form.Item>

                        <Form.Item
                          name="confrimPassword"
                          rules={[
                            { required: true, message: 'Nhập xác nhận mật khẩu!' },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error('Xác nhận mật khẩu không khớp!'));
                              },
                            }),
                          ]}>
                          <Input.Password
                            className='password ele'
                            size="large"
                            placeholder='Xác Nhận Mật khẩu'
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                          />
                        </Form.Item>
                        <ReCAPTCHA style={{ textAlign: 'left' }} sitekey="6LdLIboqAAAAAMgsr4sluciGlWOKWlWi3_mgtCkA" onChange={value => setCaptchaValue(value)} />
                        <button type='submit' className="clkbtn">Register</button>
                      </Fragment>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </Fragment>
        )
        :
        (
          <Fragment>
            <div style={{ textAlign: 'center' }}>
              <p style={{ marginBottom: '10px' }}>Nhập xác thực Google Authy</p>
              <Form onFinish={handleFinish} form={form}>
                <Form.Item name="otp">
                  <InputOTP length={6} inputType="numeric" required />
                </Form.Item>

                <Form.Item>
                  <>
                    {error && (
                      <div className="alert alert-danger" role="alert">
                        {error.toString()}
                      </div>
                    )}
                  </>
                  <button type='submit' className="clkbtn">Xác thực</button>
                </Form.Item>
              </Form>
            </div>
          </Fragment>
        )
      }
    </div>
  );
};