import { Card, Col, Row, Tabs, Typography, message } from 'antd'
import styled from 'styled-components';
import { ButtonPlatedGreenStyled, ColContentEndStyled, TableContainer, TableStyled, Title1Styled } from '../../components/CustomAntStyled';
import requestCheckBalance from '../../example/exampleRequestCheckBalance.json';
import responseCheckBalance from '../../example/exampleResponseCheckBalance.json';
import requestTopup from '../../example/exampleRequestTopup.json';
import responseTopup from '../../example/exampleResponseTopup.json';
import { Fragment } from 'react';
import { FaDownload } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';

const { Title, Paragraph, Text } = Typography;

export const PartnerDocumentPage = () => {
    return (
        <ContentStyled>
            <Tabs
                className='tab-credit-card'
                tabPosition={'left'}
                items={[
                    {
                        label: 'Tổng quan',
                        key: 'Key_RSA',
                        children: <TongQuanTab />,
                    },
                    {
                        label: 'API kiểm tra số dư',
                        key: 'Key_check_balance',
                        children: <ApiCheckBalance />,
                    },
                    {
                        label: 'API nạp điện thoại',
                        key: 'Key_direct_topup',
                        children: <ApiDirectTopup />,
                    },
                    {
                        label: 'API callback',
                        key: 'Key_callback',
                        children: <ApiCallBack />,
                    },

                    {
                        label: <p style={{ textAlign: 'left' }}>API Kiểm tra GD<br />nạp tiền điện thoại</p>,
                        key: 'Key_check_direct_topup',
                        children: <ApiCheckDirectTopup />,
                    },
                    {
                        label: 'API mua mã thẻ',
                        key: 'Key_buy_card',
                        children: <ApiBuyCard />,
                    },
                    {
                        label: <p style={{ textAlign: 'left' }}>API Kiểm tra GD<br />mua mã thẻ</p>,
                        key: 'Key_check_buy_card',
                        children: <ApiCheckBuyCard />,
                    },
                    {
                        label: <p style={{ textAlign: 'left' }}>Mã lỗi</p>,
                        key: 'Key_error_code',
                        children: <BangMaLoiTab />,
                    }
                    ,
                    {
                        label: <p style={{ textAlign: 'left' }}>Source code mẫu</p>,
                        key: 'Key_code_mau',
                        children: <CodeMauTab />,
                    }
                ]}
            />
        </ContentStyled>
    )
}

export const TongQuanTab = () => {
    return (
        <Typography>
            <Title level={3}>Phương thức bảo mật</Title>
            <ParagraphTextStyled>
                - Đối tác kết nối với hệ thống Link Pay thông qua RESTFUL giao thức POST. <br />
                - Định dạng thông điệp kiểu application/json
                - Thông điệp được nhận diện bằng chữ ký điện tử sử dụng giải thuật mã hóa chuẩn RSA với Instance là SHA256withRSA<br />
                - Đối với các dữ liệu quan trọng ví dụ như mã pin thẻ cào sẽ được encrypt RSA với Instance là "RSA/ECB/PKCS1Padding"
            </ParagraphTextStyled>

            <Title level={3}>Quy trình kết nối với hệ thống Link Pay được thực hiện theo các bước sau:</Title>
            <ParagraphTextStyled>
                1) Phía đối tác đăng ký tài khoản trên hệ thống của Link Pay. Sau khi nhận được thông báo xác thực tài khoản thành công sẽ có thể đăng nhập vào hệ thống Link Pay.
            </ParagraphTextStyled>
            <ParagraphTextStyled>
                2) Phía đối tác phát sinh cặp khóa RSA và cập nhật tại menu "Cài Đặt".<br />
                Link Pay sử dụng public key của đối tác để xác thực chữ ký điện tử trên thông
                điệp được gửi từ phía đối tác và mã hóa dữ liệu cần thiết trên thông điệp trả về cho
                đối tác. <br />RSA private key đối tác tự bảo quản để tạo chữ ký điện tử và giải mã dữ liệu
                mã hóa từ Link Pay.
            </ParagraphTextStyled>
            <ParagraphTextStyled>
                3) RSA public key phía Link Pay gửi cũng nằm tại menu "Cài Đặt".
                Đối tác sử dụng public key đó để xác thực chữ ký điện tử thông điệp được gửi từ phía Link Pay
            </ParagraphTextStyled>
            <ParagraphTextStyled>
                <strong style={{ color: 'red' }}>* </strong>Trang thao khảo gen RSA online: <a href='https://www.lddgo.net/en/encrypt/rsakey' target='_blank'> https://www.lddgo.net/en/encrypt/rsakey </a>
            </ParagraphTextStyled>
        </Typography>
    )
}

export const ApiCheckBalance = () => {
    return (
        <Typography>
            <Title level={2}>API kiểm tra số dự hiện tại</Title>
            <ParagraphTitleStyled>
                <ul>
                    <li>
                        POST: <a>https://api.linkpay.vn/v1/check-balance</a>
                    </li>
                </ul>
            </ParagraphTitleStyled>
            <ParagraphTitleStyled>
                JSON Body Request
            </ParagraphTitleStyled>
            <TableContainer>
                <TableStyled>
                    <tr>
                        <th>Tên biến</th>
                        <th>Kiểu dữ liệu</th>
                        <th>Mô tả</th>
                    </tr>
                    <tr>
                        <td>partnerCode</td>
                        <td>String</td>
                        <td>Mã đối tác</td>
                    </tr>
                    <tr>
                        <td>signature</td>
                        <td>String</td>
                        <td>Chữ ký điện tử RSA dữ liệu ký là: <Text strong>partnerCode</Text> + <Text strong>secretKey</Text></td>
                    </tr>
                </TableStyled>
            </TableContainer>
            <Paragraph style={{ paddingLeft: '20px' }}>
                <Title level={4}>Ví dụ:</Title>
                <pre>
                    {JSON.stringify(requestCheckBalance, null, 2)}
                </pre>
            </Paragraph>

            <ParagraphTitleStyled>
                JSON Response
            </ParagraphTitleStyled>
            <TableContainer>
                <TableStyled>
                    <tr>
                        <th>Tên biến</th>
                        <th>Kiểu dữ liệu</th>
                        <th>Mô tả</th>
                    </tr>
                    <tr>
                        <td>code</td>
                        <td>Integer</td>
                        <td>Mã lỗi</td>
                    </tr>
                    <tr>
                        <td>message</td>
                        <td>String</td>
                        <td>Mô tả lỗi</td>
                    </tr>
                    <tr>
                        <td>currentBalance</td>
                        <td>Real</td>
                        <td>Số dư đối tác hiện tại</td>
                    </tr>
                    <tr>
                        <td>signature</td>
                        <td>String</td>
                        <td>Chữ ký điện tử RSA dữ liệu ký là: <Text strong>currentBalance</Text> + <Text strong>secretKey</Text></td>
                    </tr>
                </TableStyled>
            </TableContainer>
            <Paragraph style={{ paddingLeft: '20px' }}>
                <Title level={4}>Ví dụ:</Title>
                <pre>
                    {JSON.stringify(responseCheckBalance, null, 2)}
                </pre>
            </Paragraph>
        </Typography>
    )
}

export const ApiDirectTopup = () => {
    return (
        <Typography>
            <Title level={2}>API giao dịch nạp tiền điện thoại trực tiếp</Title>
            <p>
                <span style={{ color: 'red', fontSize: '16px', fontWeight: '500' }}> * Lưu ý:</span><br />
                <span>- Đối với giao dịch trả về code = 1 (Mã lỗi: giao dịch đang xử lý, chỉ xảy ra với giao dịch topup). Sau khi có kết quả thì sẽ được trả thông qua Callback API mà đối tác đã thêm tại màn hình "Cài đặt"</span><br />
                <span>- Đối với các trường hợp nhà cung cấp lỗi dẫn đến nạp thiếu thì số dư của đối tác sẽ được hoàn tiền lại đúng số tiền nạp thiếu và mệnh giá giao dịch sẽ được cập nhật về mệnh giá đã nạp</span>
            </p>
            <ParagraphTitleStyled>
                <ul>
                    <li>
                        POST: <a>https://api.linkpay.vn/v1/direct-topup</a>
                    </li>
                </ul>
            </ParagraphTitleStyled>
            <ParagraphTitleStyled>
                JSON Body Request
            </ParagraphTitleStyled>
            <TableContainer>
                <TableStyled>
                    <tr>
                        <th>Tên biến</th>
                        <th>Kiểu dữ liệu</th>
                        <th>Mô tả</th>
                    </tr>
                    <tr>
                        <td>partnerCode</td>
                        <td>String</td>
                        <td>Mã đối tác</td>
                    </tr>
                    <tr>
                        <td>partnerTransId</td>
                        <td>String</td>
                        <td>Mã giao dịch của đối tác tối đa 50 ký tự</td>
                    </tr>
                    <tr>
                        <td>productCode</td>
                        <td>String</td>
                        <td>
                            Mã sản phẩm
                        </td>
                    </tr>
                    <tr>
                        <td>phone</td>
                        <td>String</td>
                        <td>Số điện thoại cần nạp (Bắt đâu bằng số 0 và 10 số)</td>
                    </tr>
                    <tr>
                        <td>type</td>
                        <td>String</td>
                        <td>
                            <Text strong>PRE_PAID</Text>: Trả trước <br />
                            <Text strong>POST_PAID</Text>: Trả sau
                        </td>
                    </tr>
                    {/* <tr>
                        <td>typeSub</td>
                        <td>String</td>
                        <td>
                            <Text strong>RECHARGE</Text>: Nạp thẻ hộ <br />
                            <Text strong>AIRTIME</Text>: Topup trực tiếp<br />
                            (Không truyền mặc định là RECHARGE)
                        </td>
                    </tr> */}
                    {/* + <Text strong>typeSub</Text> */}
                    <tr>
                        <td>signature</td>
                        <td>String</td>
                        <td>
                            Chữ ký điện tử RSA dữ liệu ký là: <Text strong>partnerCode</Text> + <Text strong>partnerTransId</Text> + <Text strong>productCode</Text> + <Text strong>phone</Text> + <Text strong>type</Text> + <Text strong>secretKey</Text>
                        </td>
                    </tr>
                </TableStyled>
            </TableContainer>
            <Paragraph style={{ paddingLeft: '20px' }}>
                <Title level={4}>Ví dụ:</Title>
                <pre>
                    {JSON.stringify(requestTopup, null, 2)}
                </pre>
            </Paragraph>

            <ParagraphTitleStyled>
                JSON Response
            </ParagraphTitleStyled>
            <TableContainer>
                <TableStyled>
                    <tr>
                        <th>Tên biến</th>
                        <th>Kiểu dữ liệu</th>
                        <th>Mô tả</th>
                    </tr>
                    <tr>
                        <td>code</td>
                        <td>Integer</td>
                        <td>Mã lỗi</td>
                    </tr>
                    <tr>
                        <td>message</td>
                        <td>String</td>
                        <td>Mô tả lỗi</td>
                    </tr>
                    <tr>
                        <td>partnerTransId</td>
                        <td>String</td>
                        <td>Mã giao dịch của đối tác đã gửi</td>
                    </tr>
                    <tr>
                        <td>discountAmount</td>
                        <td>Real</td>
                        <td>Số tiền chiết khấu</td>
                    </tr>
                    <tr>
                        <td>debitAmount</td>
                        <td>Real</td>
                        <td>Số tiền đã thanh toán</td>
                    </tr>
                    <tr>
                        <td>telcoCode</td>
                        <td>String</td>
                        <td>Nhà mạng</td>
                    </tr>
                    <tr>
                        <td>type</td>
                        <td>String</td>
                        <td>
                            <Text strong>PRE_PAID</Text>: Trả trước <br />
                            <Text strong>POST_PAID</Text>: Trả sau
                        </td>
                    </tr>
                    <tr>
                        <td>phone</td>
                        <td>String</td>
                        <td>Số điện thoại cần nạp</td>
                    </tr>
                    <tr>
                        <td>signature</td>
                        <td>String</td>
                        <td>
                            Chữ ký điện tử RSA dữ liệu ký là: <Text strong>partnerTransId</Text> + <Text strong>discountAmount</Text> + <Text strong>debitAmount</Text> + <Text strong>telcoCode</Text><br /> + <Text strong>type</Text> + <Text strong>phone</Text> + <Text strong>secretKey</Text>
                        </td>
                    </tr>
                </TableStyled>
            </TableContainer>
            <Paragraph style={{ paddingLeft: '20px' }}>
                <Title level={4}>Ví dụ:</Title>
                <pre>
                    {JSON.stringify(responseTopup, null, 2)}
                </pre>
            </Paragraph>
        </Typography>
    )
}

export const ApiCallBack = () => {
    return (
        <Typography>
            <Title level={2}>API CallBack</Title>
            <p>
                <span style={{ fontSize: '16px', fontWeight: '500' }}>Đối tác xây dựng API này, cập nhật url API tại màn hình "Cài đặt" để nhận kết quả khi giao dịch nạp tiền có mã code = 1 (Mã lỗi: giao dịch đang xử lý, chỉ xảy ra với giao dịch topup)</span><br />
            </p>
            <ParagraphTitleStyled>
                <ul>
                    <li>
                        Method POST
                    </li>
                </ul>
            </ParagraphTitleStyled>
            <ParagraphTitleStyled>
                Nhận JSON Body
            </ParagraphTitleStyled>
            <TableContainer>
                <TableStyled>
                    <tr>
                        <th>Tên biến</th>
                        <th>Kiểu dữ liệu</th>
                        <th>Mô tả</th>
                    </tr>
                    <tr>
                        <td>code</td>
                        <td>Integer</td>
                        <td>Mã lỗi</td>
                    </tr>
                    <tr>
                        <td>message</td>
                        <td>String</td>
                        <td>Mô tả lỗi</td>
                    </tr>
                    <tr>
                        <td>partnerTransId</td>
                        <td>String</td>
                        <td>Mã giao dịch của đối tác đã gửi</td>
                    </tr>
                    <tr>
                        <td>discountAmount</td>
                        <td>Real</td>
                        <td>Số tiền chiết khấu</td>
                    </tr>
                    <tr>
                        <td>debitAmount</td>
                        <td>Real</td>
                        <td>Số tiền đã thanh toán</td>
                    </tr>
                    <tr>
                        <td>telcoCode</td>
                        <td>String</td>
                        <td>Nhà mạng</td>
                    </tr>
                    <tr>
                        <td>type</td>
                        <td>String</td>
                        <td>
                            <Text strong>PRE_PAID</Text>: Trả trước <br />
                            <Text strong>POST_PAID</Text>: Trả sau
                        </td>
                    </tr>
                    <tr>
                        <td>phone</td>
                        <td>String</td>
                        <td>Số điện thoại cần nạp</td>
                    </tr>
                    <tr>
                        <td>signature</td>
                        <td>String</td>
                        <td>
                            Chữ ký điện tử RSA dữ liệu ký là: <Text strong>partnerTransId</Text> + <Text strong>discountAmount</Text> + <Text strong>debitAmount</Text> + <Text strong>telcoCode</Text><br /> + <Text strong>type</Text> + <Text strong>phone</Text> + <Text strong>secretKey</Text>
                        </td>
                    </tr>
                </TableStyled>
            </TableContainer>

            <ParagraphTitleStyled>
                Trả về JSON Response
            </ParagraphTitleStyled>
            <TableContainer>
                <TableStyled>
                    <tr>
                        <th>Tên biến</th>
                        <th>Kiểu dữ liệu</th>
                        <th>Mô tả</th>
                    </tr>
                    <tr>
                        <td>code</td>
                        <td>Integer</td>
                        <td>Mã lỗi</td>
                    </tr>
                    <tr>
                        <td>message</td>
                        <td>String</td>
                        <td>Mô tả lỗi</td>
                    </tr>
                </TableStyled>
            </TableContainer>
        </Typography>
    )
}

export const ApiCheckDirectTopup = () => {
    return (
        <Typography>
            <Title level={2}>API kiểm tra giao dịch nạp tiền điện thoại trực tiếp</Title>
            <ParagraphTitleStyled>
                <ul>
                    <li>
                        POST: <a>https://api.linkpay.vn/v1/check-direct-topup</a>
                    </li>
                </ul>
            </ParagraphTitleStyled>
            <ParagraphTitleStyled>
                JSON Body Request
            </ParagraphTitleStyled>
            <TableContainer>
                <TableStyled>
                    <tr>
                        <th>Tên biến</th>
                        <th>Kiểu dữ liệu</th>
                        <th>Mô tả</th>
                    </tr>
                    <tr>
                        <td>partnerCode</td>
                        <td>String</td>
                        <td>Mã đối tác</td>
                    </tr>
                    <tr>
                        <td>partnerTransId</td>
                        <td>String</td>
                        <td>Mã giao dịch của đối tác tối đa 50 ký tự</td>
                    </tr>
                    <tr>
                        <td>signature</td>
                        <td>String</td>
                        <td>
                            Chữ ký điện tử RSA dữ liệu ký là: <Text strong>partnerCode</Text> + <Text strong>partnerTransId</Text> + <Text strong>secretKey</Text>
                        </td>
                    </tr>
                </TableStyled>
            </TableContainer>

            <ParagraphTitleStyled>
                JSON Response
            </ParagraphTitleStyled>
            <TableContainer>
                <TableStyled>
                    <tr>
                        <th>Tên biến</th>
                        <th>Kiểu dữ liệu</th>
                        <th>Mô tả</th>
                    </tr>
                    <tr>
                        <td>code</td>
                        <td>Integer</td>
                        <td>Mã lỗi</td>
                    </tr>
                    <tr>
                        <td>message</td>
                        <td>String</td>
                        <td>Mô tả lỗi</td>
                    </tr>
                    <tr>
                        <td>partnerTransId</td>
                        <td>String</td>
                        <td>Mã giao dịch của đối tác đã gửi</td>
                    </tr>
                    <tr>
                        <td>discountAmount</td>
                        <td>Real</td>
                        <td>Số tiền chiết khấu</td>
                    </tr>
                    <tr>
                        <td>debitAmount</td>
                        <td>Real</td>
                        <td>Số tiền đã thanh toán</td>
                    </tr>
                    <tr>
                        <td>telcoCode</td>
                        <td>String</td>
                        <td>Nhà mạng</td>
                    </tr>
                    <tr>
                        <td>type</td>
                        <td>String</td>
                        <td>
                            <Text strong>PRE_PAID</Text>: Trả trước <br />
                            <Text strong>POST_PAID</Text>: Trả sau
                        </td>
                    </tr>
                    <tr>
                        <td>phone</td>
                        <td>String</td>
                        <td>Số điện thoại cần nạp</td>
                    </tr>
                    <tr>
                        <td>signature</td>
                        <td>String</td>
                        <td>
                            Chữ ký điện tử RSA dữ liệu ký là: <Text strong>partnerTransId</Text> + <Text strong>discountAmount</Text> + <Text strong>debitAmount</Text> + <Text strong>telcoCode</Text><br /> + <Text strong>type</Text> + <Text strong>phone</Text> + <Text strong>secretKey</Text>
                        </td>
                    </tr>
                </TableStyled>
            </TableContainer>
        </Typography>
    )
}

export const ApiBuyCard = () => {
    return (
        <Typography>
            <Title level={2}>API mua mã thẻ</Title>
            <ParagraphTitleStyled>
                <ul>
                    <li>
                        POST: <a>https://api.linkpay.vn/v1/buy-card</a>
                    </li>
                </ul>
            </ParagraphTitleStyled>
            <ParagraphTitleStyled>
                JSON Body Request
            </ParagraphTitleStyled>
            <TableContainer>
                <TableStyled>
                    <tr>
                        <th>Tên biến</th>
                        <th>Kiểu dữ liệu</th>
                        <th>Mô tả</th>
                    </tr>
                    <tr>
                        <td>partnerCode</td>
                        <td>String</td>
                        <td>Mã đối tác</td>
                    </tr>
                    <tr>
                        <td>partnerTransId</td>
                        <td>String</td>
                        <td>Mã giao dịch của đối tác tối đa 50 ký tự</td>
                    </tr>
                    <tr>
                        <td>productCode</td>
                        <td>String</td>
                        <td>
                            Mã sản phẩm
                        </td>
                    </tr>
                    <tr>
                        <td>quantity</td>
                        <td>Integer</td>
                        <td>Số lượng thẻ</td>
                    </tr>
                    <tr>
                        <td>signature</td>
                        <td>String</td>
                        <td>
                            Chữ ký điện tử RSA dữ liệu ký là: <Text strong>partnerCode</Text> + <Text strong>partnerTransId</Text> + <Text strong>productCode</Text> + <Text strong>quantity</Text> + <Text strong>secretKey</Text>
                        </td>
                    </tr>
                </TableStyled>
            </TableContainer>

            <ParagraphTitleStyled>
                JSON Response
            </ParagraphTitleStyled>
            <TableContainer>
                <TableStyled>
                    <tr>
                        <th>Tên biến</th>
                        <th>Kiểu dữ liệu</th>
                        <th>Mô tả</th>
                    </tr>
                    <tr>
                        <td>code</td>
                        <td>Integer</td>
                        <td>Mã lỗi</td>
                    </tr>
                    <tr>
                        <td>message</td>
                        <td>String</td>
                        <td>Mô tả lỗi</td>
                    </tr>
                    <tr>
                        <td>partnerTransId</td>
                        <td>String</td>
                        <td>Mã giao dịch của đối tác đã gửi</td>
                    </tr>
                    <tr>
                        <td>discountAmount</td>
                        <td>Long</td>
                        <td>Số tiền chiết khấu</td>
                    </tr>
                    <tr>
                        <td>debitAmount</td>
                        <td>Long</td>
                        <td>Số tiền đã thanh toán</td>
                    </tr>
                    <tr>
                        <td>cardInfo</td>
                        <td>List Object</td>
                        <td>
                            <TableStyled>
                                <tr>
                                    <th>Tên biến</th>
                                    <th>Kiểu dữ liệu</th>
                                    <th>Mô tả</th>
                                </tr>
                                <tr>
                                    <td>serial</td>
                                    <td>String</td>
                                    <td>Số serial</td>
                                </tr>
                                <tr>
                                    <td>pinCode</td>
                                    <td>String</td>
                                    <td>Mã thẻ được mã hóa bằng RSA</td>
                                </tr>
                                <tr>
                                    <td>expireDate</td>
                                    <td>String</td>
                                    <td>
                                        Ngày hết hạn
                                    </td>
                                </tr>
                            </TableStyled>
                        </td>
                    </tr>
                    <tr>
                        <td>signature</td>
                        <td>String</td>
                        <td>
                            Chữ ký điện tử RSA dữ liệu ký là: <Text strong>partnerTransId</Text> + <Text strong>discountAmount</Text><br /> + <Text strong>debitAmount</Text> + <Text strong>secretKey</Text>
                        </td>
                    </tr>
                </TableStyled>
            </TableContainer>
        </Typography>
    )
}

export const ApiCheckBuyCard = () => {
    return (
        <Typography>
            <Title level={2}>API mua mã thẻ</Title>
            <ParagraphTitleStyled>
                <ul>
                    <li>
                        POST: <a>https://api.linkpay.vn/v1/check-buy-card</a>
                    </li>
                </ul>
            </ParagraphTitleStyled>
            <ParagraphTitleStyled>
                JSON Body Request
            </ParagraphTitleStyled>
            <TableContainer>
                <TableStyled>
                    <tr>
                        <th>Tên biến</th>
                        <th>Kiểu dữ liệu</th>
                        <th>Mô tả</th>
                    </tr>
                    <tr>
                        <td>partnerCode</td>
                        <td>String</td>
                        <td>Mã đối tác</td>
                    </tr>
                    <tr>
                        <td>partnerTransId</td>
                        <td>String</td>
                        <td>Mã giao dịch của đối tác tối đa 50 ký tự</td>
                    </tr>
                    <tr>
                        <td>signature</td>
                        <td>String</td>
                        <td>
                            Chữ ký điện tử RSA dữ liệu ký là: <Text strong>partnerCode</Text> + <Text strong>partnerTransId</Text> + <Text strong>secretKey</Text>
                        </td>
                    </tr>
                </TableStyled>
            </TableContainer>

            <ParagraphTitleStyled>
                JSON Response
            </ParagraphTitleStyled>
            <TableContainer>
                <TableStyled>
                    <tr>
                        <th>Tên biến</th>
                        <th>Kiểu dữ liệu</th>
                        <th>Mô tả</th>
                    </tr>
                    <tr>
                        <td>code</td>
                        <td>Integer</td>
                        <td>Mã lỗi</td>
                    </tr>
                    <tr>
                        <td>message</td>
                        <td>String</td>
                        <td>Mô tả lỗi</td>
                    </tr>
                    <tr>
                        <td>partnerTransId</td>
                        <td>String</td>
                        <td>Mã giao dịch của đối tác đã gửi</td>
                    </tr>
                    <tr>
                        <td>discountAmount</td>
                        <td>Long</td>
                        <td>Số tiền chiết khấu</td>
                    </tr>
                    <tr>
                        <td>debitAmount</td>
                        <td>Long</td>
                        <td>Số tiền đã thanh toán</td>
                    </tr>
                    <tr>
                        <td>cardInfo</td>
                        <td>List Object</td>
                        <td>
                            <TableStyled>
                                <tr>
                                    <th>Tên biến</th>
                                    <th>Kiểu dữ liệu</th>
                                    <th>Mô tả</th>
                                </tr>
                                <tr>
                                    <td>serial</td>
                                    <td>String</td>
                                    <td>Số serial</td>
                                </tr>
                                <tr>
                                    <td>pinCode</td>
                                    <td>String</td>
                                    <td>Mã thẻ được mã hóa bằng RSA</td>
                                </tr>
                                <tr>
                                    <td>expireDate</td>
                                    <td>String</td>
                                    <td>
                                        Ngày hết hạn
                                    </td>
                                </tr>
                            </TableStyled>
                        </td>
                    </tr>
                    <tr>
                        <td>signature</td>
                        <td>String</td>
                        <td>
                            Chữ ký điện tử RSA dữ liệu ký là: <Text strong>partnerTransId</Text> + <Text strong>discountAmount</Text><br /> + <Text strong>debitAmount</Text> + <Text strong>secretKey</Text>
                        </td>
                    </tr>
                </TableStyled>
            </TableContainer>
        </Typography>
    )
}

export const BangMaLoiTab = () => {
    return (
        <Typography>
            <Title level={2}>Bản mã lỗi</Title>
            <TableContainer>
                <TableStyled>
                    <tr>
                        <th style={{ textAlign: 'center' }}>code</th>
                        <th>message</th>
                        <th>Mô tả</th>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>0</td>
                        <td>Success</td>
                        <td>Thành công</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>1</td>
                        <td>Transaction is processing</td>
                        <td>Giao dịch đang trong quá trình xử lý, kết quả sẽ được trả ra tại API call back</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>2</td>
                        <td>Successful but miss amount</td>
                        <td>Nạp thành công nhưng không đủ số tiền yêu cầu</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>3</td>
                        <td>Partner code does not exist</td>
                        <td>Mã đối tác không tồn tại</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>4</td>
                        <td>Account is locked</td>
                        <td>Tài khoản đối tác đã bị tạm khóa</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>5</td>
                        <td>IP address not allow</td>
                        <td>Địa chỉ IP chưa được thêm vào whitelist</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>6</td>
                        <td>Public key RSA not found</td>
                        <td>Public key chưa được đối tác thêm vào</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>7</td>
                        <td>Signature not valid</td>
                        <td>Chữ ký không hợp lệ</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>8</td>
                        <td>Transaction partner ID already exists</td>
                        <td>Mã giao dịch của đối tác đã tồn tại</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>9</td>
                        <td>Transaction partner ID not valid</td>
                        <td>Mã giao dịch của đối tác không hợp lệ</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>10</td>
                        <td>Public key not valid</td>
                        <td>Lỗi phát sinh do Public Key đối tác gửi không hợp lệ</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>11</td>
                        <td>Product code not found</td>
                        <td>Mã sản phẩm không tồn tại hoặc đã bị đóng</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>12</td>
                        <td>Product has been locked</td>
                        <td>Sản phẩm đã tạm khóa</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>13</td>
                        <td>Provider maintenance</td>
                        <td>Nhà cung cấp đang bảo trì</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>14</td>
                        <td>Balance not available</td>
                        <td>Số dư không khả dụng</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>15</td>
                        <td>Phone not valid</td>
                        <td>Số điện thoại không hợp lệ</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>16</td>
                        <td>Transaction not found</td>
                        <td>Mã giao dịch không tồn tại</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>17</td>
                        <td>Provider update error</td>
                        <td>Nhà cung cấp trả kết quả thất bại</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>18</td>
                        <td>Provider not found product</td>
                        <td>Dịch vụ đang tạm khóa tại nhà cung cấp</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>19</td>
                        <td>Navigation failed</td>
                        <td>Lỗi phát sinh do không gọi được nhà cung cấp</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>20</td>
                        <td>The System is upgrading. Please try again later.</td>
                        <td>Hệ thống đang nâng cấp. hãy thử lại sau</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>21</td>
                        <td>Services are evolving.</td>
                        <td>Dịch vụ đang phát triển</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>98</td>
                        <td>System external error</td>
                        <td>Lỗi hệ thống EXTERNAL tại IPay Service</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>99</td>
                        <td>System internal error</td>
                        <td>Lỗi hệ thống INTERNAL tại IPay Service</td>
                    </tr>
                </TableStyled>
            </TableContainer>
        </Typography>
    )
}

export const CodeMauTab = () => {
    const dispatch = useDispatch();

    const handleDownload = () => {
        dispatch(setLoading(true));
        try {
            const zipFilePath = process.env.PUBLIC_URL + '/sourceCodeExample/DemoJava.zip'; // Thay thế 'yourfile.zip' bằng tên thực tế của file zip của bạn
            const link = document.createElement('a');
            link.href = zipFilePath;
            link.download = 'DemoJava.zip'; // Thay thế 'yourfile.zip' bằng tên bạn muốn đặt cho file khi được tải về
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            message.success("Download thành công")
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleDownloadASP = () => {
        dispatch(setLoading(true));
        try {
            const zipFilePath = process.env.PUBLIC_URL + '/sourceCodeExample/DemoLinkPayAPI.zip'; // Thay thế 'yourfile.zip' bằng tên thực tế của file zip của bạn
            const link = document.createElement('a');
            link.href = zipFilePath;
            link.download = 'DemoLinkPayAPI.zip'; // Thay thế 'yourfile.zip' bằng tên bạn muốn đặt cho file khi được tải về
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            message.success("Download thành công")
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <Fragment>
            <Row gutter={[20, 20]} style={{ marginBottom: '10px' }}>
                <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                    <Card>
                        <Row gutter={[20, 20]}>
                            <Col span={20}>
                                <Title1Styled>Source code Java</Title1Styled>
                            </Col>
                            <ColContentEndStyled span={4}>
                                <ButtonPlatedGreenStyled>
                                    <FaDownload onClick={handleDownload} />
                                </ButtonPlatedGreenStyled>
                            </ColContentEndStyled>
                        </Row>
                    </Card>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                    <Card>
                        <Row gutter={[20, 20]}>
                            <Col span={20}>
                                <Title1Styled>Source code C#</Title1Styled>
                            </Col>
                            <ColContentEndStyled span={4}>
                                <ButtonPlatedGreenStyled>
                                    <FaDownload onClick={handleDownloadASP} />
                                </ButtonPlatedGreenStyled>
                            </ColContentEndStyled>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

const ContentStyled = styled.div`
    .tab-credit-card .ant-tabs-tab .ant-tabs-tab-btn {
        font-size: 16px;
    }

    .tab-credit-card .ant-tabs-nav {
        background: rgba(246, 247, 252, 1);
        width: 235px;
        min-height: 100vh;
    }

    .tab-credit-card .ant-tabs-tab-active {
        background: rgb(45 177 229);
    }

    .tab-credit-card .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #ffffff;
    }
`;

const ParagraphTitleStyled = styled(Paragraph)`
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
`
const ParagraphTextStyled = styled(Paragraph)`
    font-size: 15px;
`