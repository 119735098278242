import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, DatePicker, Form, Row, Select, Spin, Tag, message } from 'antd';
import dayjs from 'dayjs';
import {
    SearchOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { adminService } from '../../services/admin.service';
import { ButtonPlatedGreenStyled, DividerStyled, TabContentStyled, TableContainer, TableContentStyled, TableStyled } from '../../components/CustomAntStyled';
import { FaDownload } from 'react-icons/fa';
import { currencyFormat, formatDateTime, formatNumber } from '../../helpers/FunctionUtils';
import { ColumnsType } from 'antd/es/table';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

export const AminForControlProviderTab = () => {
    const dispatch = useDispatch();
    const [lstProvider, setLstProvider] = useState<any[]>([]);
    const [dataObj, setDataObj] = useState<any>(null);
    const [formSearch] = Form.useForm();

    useEffect(() => {
        const initData = async () => {
            dispatch(setLoading(true));
            try {
                formSearch.setFieldsValue({
                    tuNgay: dayjs(),
                    denNgay: dayjs(),
                });
                onSearch();

                const resProvider = await adminService.filterProvider(0, 999, null, null)
                if (resProvider.status === 200) {
                    setLstProvider(resProvider.data.data);
                } else {
                    message.error({ content: resProvider.message, duration: 3 });
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }
        };
        initData();
    }, []);

    const onSearch = async () => {
        dispatch(setLoading(true));
        try {
            const values = await formSearch.validateFields();
            const dayCheck = dayjs(values.denNgay).diff(dayjs(values.tuNgay), 'day');
            if (dayCheck > 90) {
                message.error("Khoản thời gian tìm kiếm không được quá 90 ngày");
                return;
            }
            const response = await adminService.adminForControl(
                values.tuNgay.format('DD/MM/YYYY 00:00:00'), values.denNgay.format('DD/MM/YYYY 00:00:00'),
                null, values.providerId, values.productType, values.type
            )
            if (response.code === 0) {
                setDataObj(response.data);
            } else {
                message.error({ content: response.message, duration: 3 });
            }
        } catch (error) {
            message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const columns: ColumnsType<any> = [
        {
            title: <p>Mã GD nhà cung cấp</p>,
            dataIndex: 'transCodeProvider',
            className: 'center-data',
            render: (transCodeProvider: any) => <span>{transCodeProvider}</span>,
        },
        {
            title: <p>Loại<br /> giao dịch</p>,
            dataIndex: 'type',
            className: 'center-data',
            render: (type: any) =>
                <span>
                    {
                        type === 'TOPUP' ? 'Topup'
                            : type === 'TOPUP_BY_FILE' ? 'Nạp file'
                                : 'Mua thẻ'
                    }
                </span>,
        },
        {
            title: <p>Số lượng</p>,
            dataIndex: 'quantity',
            className: 'center-data',
            render: (quantity: any) =>
                <span>{quantity}</span>,
        },
        {
            title: <p>Mệnh giá</p>,
            dataIndex: 'cardAmount',
            className: 'center-data',
            render: (cardAmount: any) =>
                <span>{currencyFormat(cardAmount, 'VND')}</span>,
        },
        {
            title: <p>Nhà mạng</p>,
            dataIndex: 'productType',
            className: 'center-data',
            render: (productType: any) =>
                <span>{productType}</span>,
        },
        {
            title: <p>SĐT<br />thụ hưởng</p>,
            dataIndex: 'phoneBeneficiary',
            className: 'center-data',
            render: (phoneBeneficiary: any) => <span>{phoneBeneficiary}</span>,
        },
        {
            title: <p>Nhà CC</p>,
            className: 'center-data',
            render: (record: any) =>
                <span>
                    {
                        record.evn === 'TEST' ? 'TEST' : record.provider.user.firstName
                    }
                </span>,
        },
        {
            title: <p>Chiết khấu mua</p>,
            className: 'center-data',
            dataIndex: 'providerDiscountPercent',
            render: (providerDiscountPercent: any, record: any) =>
                <span>
                    {`${providerDiscountPercent}%`}
                </span>,
        },
        {
            title: <p>Thanh toán nhà CC</p>,
            dataIndex: 'providerAmountBuy',
            className: 'center-data',
            render: (providerAmountBuy: any) => <span>{currencyFormat(providerAmountBuy, 'VND')}</span>,
        },
        {
            title: <p>Trạng thái</p>,
            dataIndex: 'transactionStatus',
            className: 'center-data',
            render: (transactionStatus: string, record: any) =>
                <Fragment>
                    <Tag color={transactionStatus === 'THANH_CONG' || transactionStatus === 'THANH_CONG_BUT_MISS' ? 'rgb(52 166 55)'
                        : transactionStatus === 'THAT_BAI' ? 'rgba(206, 206, 206, 1)'
                            : 'rgb(255 181 42)'}>
                        {
                            transactionStatus === 'THANH_CONG' ? 'Thành công'
                                : transactionStatus === 'THANH_CONG_BUT_MISS' ? 'Nạp thiếu'
                                    : transactionStatus === 'THAT_BAI' ? 'Thất bại' : 'Đang xử lý'
                        }
                    </Tag><br />
                    {record.message}
                </Fragment>
        },
        {
            title: <p>Khởi tạo</p>,
            dataIndex: 'createdDate',
            className: 'center-data',
            render: (createdDate: any) => <span>{formatDateTime(createdDate)}</span>,
        },
        {
            title: <p>Hoàn thành</p>,
            dataIndex: 'createdDate',
            className: 'center-data',
            render: (createdDate: any, record: any) =>
              <span>
                {dayjs(record.lastModifiedDate).diff(dayjs(createdDate), 'second') + ' s'}
              </span>,
          }
    ];

    const onExportExcel = async () => {
        try {
            const result = await Swal.fire({
                title: 'Xác nhận xuất excel',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Xuất',
                cancelButtonText: 'Hủy',
                reverseButtons: true,
            });
            if (result.isConfirmed) {
                dispatch(setLoading(true));

                const headerRow = ['Mã giao dịch', 'Loại giao dịch', 'Số lượng', 'Mệnh giá', 'Nhà mạng',
                    'SĐT thụ hưởng', 'Nhà CC', 'Chiết khấu mua (%)', 'Thanh toán', 'Trạng thái', 'Thời gian', 'Hoàn thành'];
                const filteredData = dataObj?.listTransAdminThanhCong.map((item: any) =>
                    [
                        item.transCodeProvider,
                        item.type === 'TOPUP' ? 'Topup'
                            : item.type === 'TOPUP_BY_FILE' ? 'Nạp file'
                                : 'Mua thẻ',
                        item.quantity,
                        item.cardAmount,
                        item.productType,
                        item.phoneBeneficiary,
                        item.provider.user.firstName,
                        item.providerDiscountPercent,
                        item.providerAmountBuy,
                        item.transactionStatus === 'THANH_CONG' ? 'Thành công'
                            : item.transactionStatus === 'THANH_CONG_BUT_MISS' ? 'Nạp thiếu'
                                : item.transactionStatus === 'THAT_BAI' ? 'Thất bại' : 'Đang xử lý',
                        formatDateTime(item.createdDate),
                        dayjs(item.lastModifiedDate).diff(dayjs(item.createdDate), 'second')
                    ]);
                const reversedData = filteredData.reverse();
                const dataWithHeader: string[][] = [headerRow, ...reversedData];
                const ws = XLSX.utils.aoa_to_sheet(dataWithHeader);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
                XLSX.writeFile(wb, `${dayjs().format('DDMMYYYYHHmmss')}.xlsx`);
            }
        } catch (error) {
            message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <TabContentStyled>
            <Form
                form={formSearch}
                layout='vertical'>
                <Row gutter={[20, 0]}>
                    <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item
                            name="tuNgay" // Tên của trường trong form
                            label='Từ ngày'
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn ngày đăng',
                                }
                            ]}>
                            <DatePicker
                                format="DD/MM/YYYY"
                                showTime={false}
                                inputReadOnly={true}
                                size='large'
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item
                            name="denNgay" // Tên của trường trong form
                            label='Đến ngày'
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn ngày đăng',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const tuNgayValue = getFieldValue('tuNgay');
                                        if (value && value.isBefore(tuNgayValue, 'day')) {
                                            return Promise.reject('Phải lớn hơn thời gian bắt đầu');
                                        } else {
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}>
                            <DatePicker
                                format="DD/MM/YYYY"
                                showTime={false}
                                inputReadOnly={true}
                                size='large'
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item name="type"
                            label="Loại giao dịch">
                            <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                                placeholder="Loại giao dịch">
                                <Select.Option key={'ALL'} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'TOPUP'} value='TOPUP'>
                                    Topup
                                </Select.Option>
                                <Select.Option key={'BUY_CARD'} value='BUY_CARD'>
                                    Mua thẻ cào
                                </Select.Option>
                                <Select.Option key={'TOPUP_BY_FILE'} value='TOPUP_BY_FILE'>
                                    Nạp file
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                            name="providerId"
                            label="Nhà cung cấp">
                            <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                                <option value="" label=''>-- All --</option>
                                {lstProvider?.map((item: any, index) => (
                                    <Select.Option key={index} value={item.id} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item name="productType" label="Nhà mạng">
                            <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                                placeholder="Nhà mạng">
                                <Select.Option key={'ALL'} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'VIETTEL'} value='VIETTEL'>
                                    VIETTEL
                                </Select.Option>
                                <Select.Option key={'VINAPHONE'} value='VINAPHONE'>
                                    VINAPHONE
                                </Select.Option>
                                <Select.Option key={'MOBIFONE'} value='MOBIFONE'>
                                    MOBIFONE
                                </Select.Option>
                                <Select.Option key={'VIETNAMOBILE'} value='VIETNAMOBILE'>
                                    VIETNAMOBILE
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Row justify="end">
                {/* <ButtonPlatedGreenStyled onClick={onExportExcel} style={{ marginRight: '10px' }} type="primary" icon={<FaDownload />}>
                    Xuất excel
                </ButtonPlatedGreenStyled> */}
                <Button onClick={onSearch} type="primary" icon={<SearchOutlined />}>
                    Xem đối soát
                </Button>
            </Row>

            <Row gutter={20}>
                <DividerStyled orientation="left" orientationMargin="0" dashed style={{ fontSize: '18px' }}>
                    Tổng quan số liệu
                </DividerStyled>
                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <TableStyled>

                        <tr style={{ fontWeight: '600', color: 'rgb(0 155 45)' }}>
                            <td>Số giao dịch thành công</td>
                            <td>
                                {
                                    dataObj ? formatNumber(dataObj?.tongThanhCong) : <Spin />
                                } <br />
                            </td>

                            <td>
                                {
                                    dataObj ? (
                                        dataObj?.tongGiaoDich > 0 ?
                                            formatNumber((dataObj?.tongThanhCong / dataObj?.tongGiaoDich) * 100) + ' %'
                                            : 0)
                                        : <Spin />
                                }
                            </td>
                        </tr>
                        <tr style={{ fontWeight: '600', color: 'rgb(227 193 0)' }}>
                            <td>Số giao dịch thành công nhưng sai mệnh giá</td>
                            <td>
                                {dataObj ? formatNumber(dataObj?.tongThanhCongButSaiMenhGia) : <Spin />}
                            </td>
                            <td>
                                {
                                    dataObj ?
                                        (
                                            dataObj?.tongGiaoDich > 0 ?
                                                formatNumber((dataObj?.tongThanhCongButSaiMenhGia / dataObj?.tongGiaoDich) * 100) + ' %'
                                                : 0
                                        )
                                        : <Spin />
                                }
                            </td>
                        </tr>

                        <tr style={{ fontWeight: '600', color: 'rgb(243 0 0)' }}>
                            <td>Số giao dịch thất bại</td>
                            <td>
                                {dataObj ? formatNumber(dataObj?.tongThatBai) : <Spin />}
                            </td>
                            <td>
                                {
                                    dataObj ?
                                        (
                                            dataObj?.tongGiaoDich > 0 ?
                                                formatNumber((100 - (dataObj?.tongThanhCong / dataObj?.tongGiaoDich) * 100) - (dataObj?.tongThanhCongButSaiMenhGia / dataObj?.tongGiaoDich)) + ' %'
                                                : 0
                                        )
                                        : <Spin />
                                }
                            </td>
                        </tr>

                        <tr style={{ fontWeight: '600' }}>
                            <td>Tổng số giao dịch</td>
                            <td>
                                {dataObj ? formatNumber(dataObj?.tongGiaoDich) : <Spin />}
                            </td>
                            <td>
                                {
                                    dataObj ?
                                        (
                                            dataObj?.tongGiaoDich > 0 ?
                                                '100 %' : '0 %'
                                        )
                                        : <Spin />
                                }
                            </td>
                        </tr>
                        <tr>
                            <th>Trung bình thời gian trả kết quả</th>
                            <th colSpan={2}>
                                {dataObj ? formatNumber(dataObj?.thoiGianTrungBinhTraKetQua) + ' s' : <Spin />}
                            </th>
                        </tr>
                        <tr>
                            <th>Trung bình thời gian thành công</th>
                            <th colSpan={2}>{dataObj ? formatNumber(dataObj?.thoiGianThanhCongTrungBinh) + ' s' : <Spin />}</th>
                        </tr>
                    </TableStyled>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <TableStyled>
                        <tr>
                            <th>Loại thẻ</th>
                            <th>Mệnh giá</th>
                            <th>Số lượng</th>
                        </tr>
                        {dataObj?.countTheoMenhGia && (
                            Object.keys(dataObj?.countTheoMenhGia).map((menhGiaKey: any, index: any) => {
                                const innerMap = dataObj?.countTheoMenhGia[menhGiaKey];
                                return Object.keys(innerMap).map((menhGiaValueKey, innerIndex) => {
                                    const count = innerMap[menhGiaValueKey];
                                    return (
                                        <tr key={index * 100 + innerIndex}>
                                            <td>{menhGiaKey}</td>
                                            <td>{currencyFormat(menhGiaValueKey, 'VND')}</td>
                                            <td>{count}</td>
                                        </tr>
                                    );
                                });
                            })
                        )}
                        <tr>
                            <th>Tổng tiền mệnh giá</th>
                            <th colSpan={2}>{dataObj ? currencyFormat(dataObj?.tongTienMenhGia, 'VND') : <Spin />}</th>
                        </tr>
                        <tr>
                            <th>Tổng tiền đã thanh toán cho nhà cung cấp</th>
                            <th colSpan={2}>{dataObj ? currencyFormat(dataObj?.tongTienMuaHangTuNCC, 'VND') : <Spin />}</th>
                        </tr>
                        <tr>
                            <th>Lợi nhuận</th>
                            <th colSpan={2}>{dataObj ? currencyFormat(dataObj?.loiNhuan, 'VND') : <Spin />}</th>
                        </tr>
                    </TableStyled>
                </Col>
            </Row>

            {/* <Row gutter={20}>
                <DividerStyled orientation="left" orientationMargin="0" dashed style={{ fontSize: '18px' }}>
                    Chi tiết giao dịch
                </DividerStyled>

                <TableContainer>
                    <TableContentStyled
                        columns={columns}
                        dataSource={dataObj?.listTransAdminThanhCong}
                        rowKey="id"
                        bordered
                    />
                </TableContainer>
            </Row> */}
        </TabContentStyled>
    )
}
