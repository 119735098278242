import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { adminService } from '../../services/admin.service';
import { Breadcrumb, Button, Col, DatePicker, Form, Row, Select, Table, message, Typography, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { currencyFormat } from '../../helpers/FunctionUtils';
const { Text } = Typography;
const { RangePicker } = DatePicker;

export const PartnerBalanceStartOfDay: React.FC = () => {
    const dispatch = useDispatch();
    const [lstPartner, setLstPartner] = useState<any[]>([]);
    const [formSearch] = Form.useForm();
    const [dataBalance, setData] = useState<any[]>([]);

    // Function to generate all dates in the given range
    const getDatesInRange = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
        const dates = [];
        let currentDate = startDate;

        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
            dates.push(currentDate.format('YYYY-MM-DD'));
            currentDate = currentDate.add(1, 'day');
        }

        return dates;
    };

    const getData = async () => {
        try {
            dispatch(setLoading(true));
            const values = await formSearch.validateFields();
            const dateRange = values.strDates;
            const formattedDates = getDatesInRange(dateRange[0], dateRange[1]).join(',');
            const response = await adminService.balanceStartOfDay(values.partnerId, formattedDates);
            if (response.status === 200) {
                const sortedData = response.data.sort((a: any, b: any) => dayjs(b.day).diff(dayjs(a.day)));
                setData(sortedData);
                console.log(sortedData); // Kiểm tra dữ liệu
            } else {
                message.error({ content: response.message, duration: 3 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 3 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        const initData = async () => {
            dispatch(setLoading(true));
            formSearch.setFieldsValue({
                strDates: [dayjs(), dayjs()], // Set current date as initial value for RangePicker
            });
            try {
                const resPartner = await adminService.filterPartner(0, 9999, null, null, 0);
                if (resPartner.status === 200) {
                    setLstPartner(resPartner.data.data);
                } else {
                    message.error({ content: resPartner.message, duration: 3 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }
        };
        initData();
    }, [dispatch, formSearch]);

    const disabledDate = (current: dayjs.Dayjs) => {
        // Can not select future dates
        return current && current > dayjs().endOf('day');
    };

    const columns = [
        {
            title: 'Ngày',
            dataIndex: 'day',
            key: 'day',
            render: (text: string) => <Text>{dayjs(text).format('DD/MM/YYYY')}</Text>
        },
        {
            title: 'Số dư đầu ngày',
            dataIndex: 'balance',
            key: 'balance',
            render: (balance: number) => (
                <Tooltip title={currencyFormat(balance, 'VND')}>
                    <Text>{currencyFormat(balance, 'VND')}</Text>
                </Tooltip>
            )
        }
    ];

    return (
        <Fragment>
            <Breadcrumb style={{ marginBottom: '10px' }}>
                <Breadcrumb.Item>Đối tác</Breadcrumb.Item>
                <Breadcrumb.Item>Số dư đầu ngày</Breadcrumb.Item>
            </Breadcrumb>

            <Form form={formSearch} layout='vertical'>
                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                            name="partnerId"
                            label="Đối tác"
                            rules={[{ required: true, message: "Chọn đối tác" }]}>
                            <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                                <option value="" label=''>-- All --</option>
                                {lstPartner.map((item: any, index) => (
                                    <Select.Option key={index} value={item.id} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                            name="strDates"
                            label="Chọn khoảng thời gian"
                            rules={[{ required: true, message: 'Vui lòng chọn khoảng thời gian' }]}>
                            <RangePicker
                                format="DD/MM/YYYY"
                                inputReadOnly={true}
                                style={{ width: '100%' }}
                                disabledDate={disabledDate}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end" style={{ marginBottom: '10px' }}>
                    <Button onClick={getData} type="primary" icon={<SearchOutlined />}>Search</Button>
                </Row>
            </Form>
            <Table dataSource={dataBalance} columns={columns} rowKey="day" />
        </Fragment>
    );
};

export default PartnerBalanceStartOfDay;
