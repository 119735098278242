import React, { Fragment, useEffect, useState } from 'react'
import { PagingStyled, TableContainer, TableContentStyled } from '../../components/CustomAntStyled'
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Pagination, Row, Select, Tag, message } from 'antd'
import { ColumnsType } from 'antd/es/table';
import { currencyFormat } from '../../helpers/FunctionUtils';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { adminService } from '../../services/admin.service';
import { AppState } from '../../store';
import { AccountState } from '../../store/account/types';

export const DiscountPage = () => {
    const dispatch = useDispatch();
    const [formSearch] = Form.useForm();

    const accountState = useSelector<AppState>((state) => state.account) as AccountState;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotal] = useState(0);
    const [lstData, setLstData] = useState<any[]>([]);
    const COUPON_ITEMS_PER_PAGE = 10;
    const columns: ColumnsType<any> = [
        {
            title: <p>Nhà mạng</p>,
            dataIndex: 'productRoot',
            className: 'center-data',
            render: (productRoot: any) => <span>{productRoot.type}</span>,
        },
        {
            title: <p>Mã sản phẩm</p>,
            dataIndex: 'productRoot',
            className: 'center-data',
            render: (productRoot: any) => <span>{productRoot.productCode}</span>,
        },
        {
            title: <p>Tên</p>,
            dataIndex: 'productRoot',
            className: 'center-data',
            render: (productRoot: any) => <span>{productRoot.name}</span>,
        },
        {
            title: <p>Mệnh giá</p>,
            dataIndex: 'productRoot',
            className: 'center-data',
            render: (productRoot: any) => <span>{currencyFormat(productRoot.cardAmount, 'VND')}</span>,
        },
        {
            title: <p>Chiết khấu<br />topup (%)</p>,
            dataIndex: 'discountTopupPercent',
            className: 'center-data',
            render: (discountPercent: any) => <span>{`${discountPercent} %`}</span>,
        },
        {
            title: <p>Chiết khấu<br />topup (VNĐ)</p>,
            dataIndex: 'discountTopupAmount',
            className: 'center-data',
            render: (discountAmount: any) => <span>{currencyFormat(discountAmount, 'VND')}</span>,
        },
        {
            title: <p>Chiết khấu<br />mua thẻ (%)</p>,
            dataIndex: 'discountPercent',
            className: 'center-data',
            render: (discountPercent: any) => <span>{`${discountPercent} %`}</span>,
        },
        {
            title: <p>Chiết khấu<br />Mua thẻ (VNĐ)</p>,
            dataIndex: 'discountAmount',
            className: 'center-data',
            render: (discountAmount: any) => <span>{currencyFormat(discountAmount, 'VND')}</span>,
        },
        {
            title: <p>Phí %</p>,
            dataIndex: 'feePercent',
            className: 'center-data',
            render: (feePercent: any) => <span>{`${feePercent} %`}</span>,
        },
        {
            title: <p>Phí VNĐ</p>,
            dataIndex: 'feeAmount',
            className: 'center-data',
            render: (feeAmount: any) => <span>{currencyFormat(feeAmount, 'VND')}</span>,
        },
        {
            title: <p>Trạng thái</p>,
            dataIndex: 'status',
            className: 'center-data',
            render: (status: string) =>
                <Tag color={status === 'ACTIVE' ? 'rgb(52 166 55)' : 'rgba(206, 206, 206, 1)'}>
                    {status === 'ACTIVE' ? 'Hoạt động' : 'Tạm khóa'}
                </Tag>
        }
    ];

    const getDataList = async (type: any, status: any, page: number) => {
        try {
            const response = await adminService.filterProductByPartner(page - 1, COUPON_ITEMS_PER_PAGE, accountState?.user?.id, null, type, status)
            if (response.status === 200) {
                setTotal(response.headers['x-total-count']);
                setLstData(response.data.data);
            } else {
                message.error({ content: response.message, duration: 3 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 3 });
        }
    };

    useEffect(() => {
        const initData = async () => {
            dispatch(setLoading(true));
            try {
                await getDataList(null, null, 0);
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }
        };
        initData();
    }, []);

    const onPageChanged = async (pageNumber: number) => {
        dispatch(setLoading(true));
        try {
            setCurrentPage(pageNumber);
            const values = await formSearch.validateFields();
            await getDataList(values.type, values.status, pageNumber);
        } catch (error) {
            message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <Fragment>
            <Form form={formSearch}
                layout='vertical'>
                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item name="type" label="Nhà mạng">
                            <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}>
                                <Select.Option key={'ALL'} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'VIETTEL'} value='VIETTEL'>
                                    VIETTEL
                                </Select.Option>
                                <Select.Option key={'VINAPHONE'} value='VINAPHONE'>
                                    VINAPHONE
                                </Select.Option>
                                <Select.Option key={'MOBIFONE'} value='MOBIFONE'>
                                    MOBIFONE
                                </Select.Option>
                                <Select.Option key={'VIETNAMOBILE'} value='VIETNAMOBILE'>
                                    VIETNAMOBILE
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item name="status"
                            label="Trạng thái">
                            <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                                placeholder="Trạng thái">
                                <Select.Option key={''} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'ACTIVE'} value='ACTIVE'>
                                    Hoạt động
                                </Select.Option>
                                <Select.Option key={'INACTIVE'} value='INACTIVE'>
                                    Tạm khóa
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end" style={{ marginBottom: '10px' }}>
                    <Button onClick={() => onPageChanged(1)} type="primary" icon={<SearchOutlined />}>
                        Search
                    </Button>
                </Row>
            </Form>

            <TableContainer>
                <TableContentStyled
                    columns={columns}
                    bordered
                    dataSource={lstData}
                    rowKey="id"
                    pagination={false} />

                <PagingStyled>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={COUPON_ITEMS_PER_PAGE}
                        onChange={onPageChanged}
                        showSizeChanger={false}
                    />
                </PagingStyled>
            </TableContainer>
        </Fragment>
    )
}
