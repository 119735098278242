import {
    PageLoadActionTypes,
    START_LOADING,
    END_LOADING,
} from './types';

export const setLoading = (flag: boolean) : any => {
    if (flag) {
        return async (dispatch: React.Dispatch<PageLoadActionTypes>) => {
            dispatch({
                type: START_LOADING,
                payload: {
                    loading: flag
                },
            });
        };
    } else {
        return async (dispatch: React.Dispatch<PageLoadActionTypes>) => {
            dispatch({
                type: END_LOADING,
                payload: {
                    loading: flag
                },
            });
        };
    }
};