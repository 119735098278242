import { Breadcrumb, Button, Col, Flex, Form, Input, Modal, Pagination, Row, Select, Tag, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { Fragment, useState } from 'react'
import { ButtonStyled1, PagingStyled, TableContainer, TableContentStyled } from '../../components/CustomAntStyled';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { setLoading } from '../../store/loading/actions';
import { useDispatch } from 'react-redux';
import { adminService } from '../../services/admin.service';


export const WarehouseListPage = () => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [formSearch] = Form.useForm();

    const [totalItems, setTotal] = useState(0);
    const [lstData, setLstData] = useState<any[]>([]);
    const COUPON_ITEMS_PER_PAGE = 10;

    const getDataList = async (partnerId: any, keySearch: any, type: any, status: any, page: number, size: number) => {
        try {
            const response = await adminService.filterProductByPartner(page - 1, size, partnerId, keySearch, type, status)
            if (response.status === 200) {
                setTotal(response.headers['x-total-count']);
                setLstData(response.data.data);
            } else {
                message.error({ content: response.message, duration: 3 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 3 });
        }
    };

    const columns: ColumnsType<any> = [
        {
            title: <p>Tên kho</p>,
            dataIndex: 'name',
            className: 'center-data',
            render: (name: any) => <span>{name}</span>,
        },
        {
            title: <p>Trạng thái</p>,
            dataIndex: 'status',
            className: 'center-data',
            render: (status: string) =>
                <Tag color={status === 'ACTIVE' ? 'rgb(52 166 55)' : 'rgba(206, 206, 206, 1)'}>
                    {status === 'ACTIVE' ? 'Hoạt động' : 'Tạm khóa'}
                </Tag>
        },

    ];

    const onPageChanged = async (pageNumber: number) => {
        dispatch(setLoading(true));
        try {
            setCurrentPage(pageNumber);
            const values = await formSearch.validateFields();
            await getDataList(values.partnerId, values.keySearch, values.type, values.status, pageNumber, COUPON_ITEMS_PER_PAGE);
        } catch (error) {
            message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [formNew] = Form.useForm();
    const [showNewWarehouse, setShowNewWarehouse] = useState(false);
    const onSubmitNew = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await adminService.createWarehouse(values.name, values.description, 'ACTIVE');
            if (response.status === 201) {
                onPageChanged(currentPage);
                setShowNewWarehouse(false);
                formNew.resetFields();
                message.success({ content: "Thêm mới thành công", duration: 3 });
            } else {
                message.error({ content: response.message, duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <Fragment>
            <Modal
                title={'Tạo mới kho hàng'}
                open={showNewWarehouse}
                onCancel={() => setShowNewWarehouse(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formNew.submit()} type="primary">Thêm mới</Button>
                        <Button onClick={() => setShowNewWarehouse(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}
                width={750}>
                <Form form={formNew}
                    layout="vertical"
                    onFinish={onSubmitNew}>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Tên kho"
                                name="name"
                                rules={[
                                    { required: true, message: 'Nhập tên kho' }
                                ]}>
                                <Input size='large' autoComplete='off' />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Mô tả"
                                name="description"
                                rules={[
                                    { required: true, message: 'Nhập mô tả' }
                                ]}>
                                <Input size='large' autoComplete='off' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Breadcrumb style={{ marginBottom: '10px' }}>
                <Breadcrumb.Item>Kho hàng</Breadcrumb.Item>
                <Breadcrumb.Item>Danh sách kho hàng</Breadcrumb.Item>
            </Breadcrumb>

            <TableContainer>
                <Form
                    form={formSearch}
                    layout='vertical'>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item name="type"
                                label="Loại dịch vụ">
                                <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                                    placeholder="Nhà Mạng">
                                    <Select.Option key={''} value='ALL'>
                                        -- All --
                                    </Select.Option>
                                    <Select.Option key={'VIETTEL'} value='VIETTEL'>
                                        VIETTEL
                                    </Select.Option>
                                    <Select.Option key={'VINAPHONE'} value='VINAPHONE'>
                                        VINAPHONE
                                    </Select.Option>
                                    <Select.Option key={'MOBIFONE'} value='MOBIFONE'>
                                        MOBIFONE
                                    </Select.Option>
                                    <Select.Option key={'VIETNAMOBILE'} value='VIETNAMOBILE'>
                                        VIETNAMOBILE
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item name="keySearch"
                                label="Từ khoán tìm kiếm">
                                <Input size='large' placeholder='Tên dịch vụ' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="end" style={{ marginBottom: '10px' }}>
                        <ButtonStyled1 onClick={() => setShowNewWarehouse(true)} style={{ marginRight: '10px' }} type='primary' icon={<PlusOutlined />}>
                            Tạo mới kho
                        </ButtonStyled1>
                        <Button onClick={() => onPageChanged(1)} type="primary" icon={<SearchOutlined />}>
                            Search
                        </Button>
                    </Row>
                </Form>

                <TableContentStyled
                    columns={columns}
                    dataSource={lstData}
                    rowKey="id"
                    bordered
                    pagination={false} />

                <PagingStyled>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={COUPON_ITEMS_PER_PAGE}
                        onChange={onPageChanged}
                        showSizeChanger={false}
                    />
                </PagingStyled>

            </TableContainer>

        </Fragment>
    )
}
