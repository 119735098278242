import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { PrivateRoute } from './components/PrivateRoute';
import { PartnerListPage } from './pages/admin/PartnerList';
import { BalanceOrderListPage } from './pages/admin/PartnerBalanceOrder';
import { SystemProductListPage } from './pages/admin/SystemProductList';
import { PartnerDiscountListPage } from './pages/admin/PartnerDiscount';
import { NotFoundPage } from './pages/NotFoundPage';
import { WarehouseListPage } from './pages/admin/Warehouse.list';
import { PartnerDashboardPage } from './pages/partner/dashboard';
import { PartnerSettingPage } from './pages/partner/setting';
import { PartnerDocumentPage } from './pages/partner/document';
import { PartnerTransationsPage } from './pages/partner/transations';
import { DiscountPage } from './pages/partner/discount';
import { ProviderListPage } from './pages/admin/ProviderList';
import { ProviderDiscountPage } from './pages/admin/ProviderDiscount';
import { AdminTransactionsPage } from './pages/admin/Transactions';
import { AdmindashboardPage } from './pages/admin/Admindashboard';
import { DepositPage } from './pages/partner/deposit';
import { AdminFluctuationsPage } from './pages/admin/AdminFluctuations';
import { PartnerFluctuationsPage } from './pages/partner/fluctuations';
import { AdminForControlPage } from './pages/admin/AdminForControl';
import { PartnerForControlPage } from './pages/partner/partnerForControl';
import { PartnerTopupByFilePage } from './pages/partner/topupByFile';
import { RateChangeSchedulePage } from './pages/admin/RateChangeSchedule';
import { PartnerBalanceStartOfDay } from './pages/admin/PartnerBalanceStartOfDay';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={
          <PrivateRoute children={null} />
        } />

        <Route path='/login' element={
          <PrivateRoute children={null} />
        } />

        <Route path='/admin/dashboard' element={
          <PrivateRoute children={<AdmindashboardPage />} />
        } />

        <Route path='/admin/partner-list' element={
          <PrivateRoute children={<PartnerListPage />} />
        } />

        <Route path='/admin/balance-partner' element={
          <PrivateRoute children={<BalanceOrderListPage />} />
        } />

        <Route path='/admin/products' element={
          <PrivateRoute children={<SystemProductListPage />} />
        } />

        <Route path='/admin/partner-discount' element={
          <PrivateRoute children={<PartnerDiscountListPage />} />
        } />

        <Route path='/admin/balance-start-of-day' element={
          <PrivateRoute children={<PartnerBalanceStartOfDay />} />
        } />

        <Route path='/admin/balance-fluctuations' element={
          <PrivateRoute children={<AdminFluctuationsPage />} />
        } />

        <Route path='/admin/control' element={
          <PrivateRoute children={<AdminForControlPage />} />
        } />

        <Route path='/admin/wasehouse' element={
          <PrivateRoute children={<WarehouseListPage />} />
        } />

        <Route path='/admin/provider-list' element={
          <PrivateRoute children={<ProviderListPage />} />
        } />

        <Route path='/admin/provider-discount' element={
          <PrivateRoute children={<ProviderDiscountPage />} />
        } />

        <Route path='/admin/trans' element={
          <PrivateRoute children={<AdminTransactionsPage />} />
        } />

        <Route path='/admin/rate-change-schedules' element={
          <PrivateRoute children={<RateChangeSchedulePage />} />
        } />

        <Route path='/partner/dashboard' element={
          <PrivateRoute children={<PartnerDashboardPage />} />
        } />

        <Route path='/partner/discount' element={
          <PrivateRoute children={<DiscountPage />} />
        } />

        <Route path='/partner/deposit' element={
          <PrivateRoute children={<DepositPage />} />
        } />

        <Route path='/partner/trans' element={
          <PrivateRoute children={<PartnerTransationsPage />} />
        } />

        <Route path='/partner/setting' element={
          <PrivateRoute children={<PartnerSettingPage />} />
        } />

        <Route path='/partner/document' element={
          <PrivateRoute children={<PartnerDocumentPage />} />
        } />

        <Route path='/partner/balance-fluctuation' element={
          <PrivateRoute children={<PartnerFluctuationsPage />} />
        } />

        <Route path='/partner/control' element={
          <PrivateRoute children={<PartnerForControlPage />} />
        } />

        <Route path='/partner/topup-by-file' element={
          <PrivateRoute children={<PartnerTopupByFilePage />} />
        } />

        <Route path='*' element={<NotFoundPage />} />

      </Routes>
    </Router>
  );
}

export default App;
