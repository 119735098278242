import React, { Fragment, useEffect, useState } from 'react'
import {
    SearchOutlined,
    MinusCircleOutlined,
    PlusOutlined
} from '@ant-design/icons';
import {
    MdLock, FaUnlock, MdModeEdit, MdVpnKey, MdDelete
} from 'react-icons/all'
import { Breadcrumb, Button, Col, Flex, Form, Input, Modal, Pagination, Row, Select, Tag, message } from 'antd'
import { adminService } from '../../services/admin.service';
import { ColumnsType } from 'antd/es/table';
import { TableContentStyled, PagingStyled, TableContainer } from '../../components/CustomAntStyled';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { currencyFormat, formatDateTime } from '../../helpers/FunctionUtils';
import DropdownActions from '../../components/DropdownActions';
import Swal from 'sweetalert2';

export const PartnerListPage = () => {
    const dispatch = useDispatch();

    const COUPON_ITEMS_PER_PAGE = 10;
    const [formSearch] = Form.useForm();
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lstData, setLstData] = useState<any[]>([]);

    const [lstPartner, setLstPartner] = useState<any[]>([]);

    const handleEdit = (id: any) => async () => {
        dispatch(setLoading(true));
        try {
            const response = await adminService.getPartnerById(id);
            if (response.status === 200) {
                formEditPartner.setFieldsValue({
                    partnerId: id,
                    partnerCode: response.data.partnerCode,
                    firstName: response.data.user.firstName,
                    maxBuyCard: response.data.maxBuyCard,
                    balanceReminder: response.data.balanceReminder,
                    email: response.data.user.email,
                    phone: response.data.phone
                });
                setShowEditPartner(true);
            } else {
                message.error({ content: response.statusText, duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 5 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleLockAccount = (id: any) => async () => {
        const result = await Swal.fire({
            title: 'Xác nhận Khóa tài khoản',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'red',
            confirmButtonText: 'Khóa',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await adminService.inActivePartner(id);
                if (response.code === 0) {
                    onPageChanged(currentPage);
                    message.success({ content: response.message, duration: 3 });
                } else {
                    message.error({ content: response.message, duration: 5 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 5 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handleResetPass = (id: any) => () => {
        message.warning("Tính năng đang được phát triển!")
    };

    const handleDelete = (login: any) => async () => {
        const result = await Swal.fire({
            title: 'Xác nhận xóa tài khoản này',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await adminService.deletePartnerInit(login);
                if (response.code === 0) {
                    message.success(response.message);
                    onPageChanged(currentPage);
                } else {
                    message.error({ content: response.message, duration: 5 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 5 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handleActivePartner = (id: any, status: string) => async () => {
        if (status === 'INIT') {
            setShowActivePartner(true);
            formActive.setFieldsValue({
                partnerId: id
            });
        } else {
            const result = await Swal.fire({
                title: 'Xác mở Khóa tài khoản',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Mở khóa',
                cancelButtonText: 'Hủy',
                reverseButtons: true,
            });
            if (result.isConfirmed) {
                dispatch(setLoading(true));
                try {
                    const response = await adminService.activePartner(id);
                    if (response.code === 0) {
                        onPageChanged(currentPage);
                        message.success({ content: response.message, duration: 3 });
                    } else {
                        message.error({ content: response.message, duration: 5 });
                    }
                } catch (error: any) {
                    message.error({ content: error.message as string, duration: 1 });
                } finally {
                    dispatch(setLoading(false));
                }
            }
        }
    };

    const renderActionModal = (item: any) => {
        const props = [
            {
                icon: <MdModeEdit size={20} color='#006aff' />,
                label: 'Edit',
                onClick: () => handleEdit(item.id),
            },
            (item.status === 'INACTIVE' || item.status === 'INIT')
                ? {

                    icon: <FaUnlock size={20} color='green' />,
                    label: 'Mở khóa',
                    onClick: () => handleActivePartner(item.id, item.status),
                }
                :
                {
                    icon: <MdLock size={20} color='#34343' />,
                    label: 'Khóa tài khoản',
                    onClick: () => handleLockAccount(item.id),
                },
            {
                icon: <MdVpnKey size={20} color='#bfa900' />,
                label: 'Reset pass',
                onClick: () => handleResetPass(item.id),
            },
            {
                icon: <MdDelete size={20} color='rgb(217 4 4)' />,
                label: 'Delete',
                onClick: () => handleDelete(item.user.login),
            },
        ];

        return <DropdownActions items={props} />;
    };

    const columns: ColumnsType<any> = [
        {
            title: <p>Tên<br /> đăng nhập</p>,
            dataIndex: 'user',
            className: 'center-data',
            render: (user: any) => <span>{user.login}</span>,
        },
        {
            title: <p>Info<br /> đối tác</p>,
            dataIndex: 'user',
            render: (user: any, record: any) =>
                <span>
                    {user.firstName} <br />
                    {record.user.email}<br />
                    {record.phone}
                </span>,
        },
        {
            title: <p>Mã<br />đối tác</p>,
            dataIndex: 'partnerCode',
            className: 'center-data',
            render: (partnerCode: any) => <span>{partnerCode ? partnerCode : "--"}</span>,
        },

        {
            title: <p style={{ textAlign: 'center' }}>Hạn mức<br /> mua thẻ</p>,
            dataIndex: 'maxBuyCard',
            className: 'center-data',
            render: (maxBuyCard: any) => <span>{maxBuyCard}</span>,
        },
        {
            title: <p>Số dư</p>,
            dataIndex: 'balance',
            className: 'center-data',
            render: (balance: any) => <span>{currencyFormat(balance, 'VND')}</span>,
        },
        {
            title: <p style={{ textAlign: 'center' }}>Cảnh báo<br /> số dư</p>,
            dataIndex: 'balanceReminder',
            className: 'center-data',
            render: (balanceReminder: any) => <span>{currencyFormat(balanceReminder, 'VND')}</span>,
        },
        {
            title: <p style={{ textAlign: 'center' }}>IP kết nối</p>,
            dataIndex: 'id',
            className: 'center-data',
            render: (id: any) =>
                <Button onClick={handleShowIpWhitelist(id)}>Show</Button>
        },
        {
            title: <p>Trạng thái</p>,
            dataIndex: 'status',
            className: 'center-data',
            render: (status: string) =>
                <Tag color={status === 'ACTIVE' ? 'rgb(52 166 55)' : status === 'INACTIVE' ? 'rgba(206, 206, 206, 1)' : 'rgb(255 181 42)'}>
                    {status === 'ACTIVE' ? 'Hoạt động' : status === 'INACTIVE' ? 'Tạm khóa' : 'Mới tạo'}
                </Tag>
        },
        {
            title: <p>Chế độ</p>,
            dataIndex: 'environment',
            className: 'center-data',
            render: (status: string) =>
                <Tag color={status === 'PRODUCTION' ? '#006aff' : '#ffb300'}>
                    {status === 'PRODUCTION' ? 'Production' : 'Test'}
                </Tag>
        },
        {
            title: <p>Ngày tạo</p>,
            dataIndex: 'createdDate',
            className: 'createdDate',
            render: (createdDate: any) => <span>{formatDateTime(createdDate)}</span>,
        },
        {
            title: <p>Actions</p>,
            dataIndex: 'id',
            className: 'action-dots',
            render: (id: any, record: any) =>
                renderActionModal(record)
            ,
        },
    ];

    const getDataList = async (keySearch: any, status: any, page: number, size: number) => {
        try {
            const response = await adminService.filterPartner(page - 1, size, keySearch, status, 1);
            if (response.status === 200) {
                setTotal(response.headers['x-total-count']);
                setLstData(response.data.data);
            } else {
                message.error({ content: response.message, duration: 3 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 5 });
        }
    };

    const handleShowIpWhitelist = (id: any) => async () => {
        setIpWhiteListFields([]);
        dispatch(setLoading(true));
        try {
            const response = await adminService.getPartnerById(id);
            if (response.status === 200) {
                if (response.data.ipWhiteList) {
                    const strWhiteList = response.data.ipWhiteList.split(',');
                    const itemsTemp = strWhiteList.map((item: string) => ({
                        ip: item
                    }));
                    setIpWhiteListFields(itemsTemp);
                }
                setShowUpdateIpWhiteList(true);
                formUpdateIpWhiteList.setFieldsValue({
                    partnerId: id
                });
            } else {
                message.error({ content: response.statusText, duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        const initData = async () => {
            dispatch(setLoading(true));
            try {
                const resPartner = await adminService.filterPartner(0, 9999, null, null, 0)
                if (resPartner.status === 200) {
                    setLstPartner(resPartner.data.data);
                } else {
                    message.error({ content: resPartner.message, duration: 3 });
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }
        };
        initData();
    }, []);

    const onPageChanged = async (pageNumber: number) => {
        dispatch(setLoading(true));
        try {
            setCurrentPage(pageNumber);
            const values = await formSearch.validateFields();
            await getDataList(values.keySearch, values.status, pageNumber, COUPON_ITEMS_PER_PAGE);
        } catch (error) {
            message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [formActive] = Form.useForm();
    const [showActivePartner, setShowActivePartner] = useState(false);
    const onSubmitActive = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await adminService.initActivePartner(values.partnerId, values.partnerCode)
            if (response.code === 0) {
                onPageChanged(currentPage);
                setShowActivePartner(false);
                message.success({ content: response.message, duration: 3 });
            } else {
                message.error({ content: response.message, duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [formEditPartner] = Form.useForm();
    const [showEditPartner, setShowEditPartner] = useState(false);
    const onSubmitEditPartner = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await adminService.editPartner(values.partnerId, values.firstName, values.maxBuyCard,
                values.balanceReminder, values.email, values.phone)
            if (response.code === 0) {
                onPageChanged(currentPage);
                setShowEditPartner(false);
                message.success({ content: response.message, duration: 3 });
            } else {
                message.error({ content: response.message, duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [showUpdateIpWhiteList, setShowUpdateIpWhiteList] = useState(false);
    const [formUpdateIpWhiteList] = Form.useForm();
    const [ipWhiteListFields, setIpWhiteListFields] = useState<string[]>([]);

    const removeIpItem = (index: number) => {
        const updatedIpList = [...ipWhiteListFields];
        updatedIpList.splice(index, 1); // Xóa phần tử tại index
        setIpWhiteListFields(updatedIpList);
    };

    const addIpItem = (ip: string) => {
        const updatedIpList = [...ipWhiteListFields, ip];
        setIpWhiteListFields(updatedIpList);
    };

    const onSubmitUpdatedIp = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const ipWhiteListTemp = [];
            // Lặp qua các trường và lấy giá trị
            if (values.ipWhiteListFields) {
                for (let index = 0; index < values.ipWhiteListFields.length; index++) {
                    const ipFieldValue = values.ipWhiteListFields[index];
                    if (ipFieldValue.ip !== '') {
                        ipWhiteListTemp.push(ipFieldValue.ip);
                    }
                }
            }

            const joinedIpWhiteList = ipWhiteListTemp.length > 0 ? ipWhiteListTemp.join(',') : '';
            const response = await adminService.updateIpPartner(values.partnerId, joinedIpWhiteList)
            if (response.code === 0) {
                onPageChanged(currentPage);
                setShowUpdateIpWhiteList(false);
                message.success({ content: response.message, duration: 3 });
            } else {
                message.error({ content: response.message, duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 5 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <Fragment>
            <Modal
                title={'Kích hoạt tài khoản'}
                open={showActivePartner}
                onCancel={() => setShowActivePartner(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formActive.submit()} type="primary">Xác nhận</Button>
                        <Button onClick={() => setShowActivePartner(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}>
                <Form form={formActive}
                    layout="vertical"
                    onFinish={onSubmitActive}>
                    <Form.Item
                        name="partnerId"
                        hidden={true}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="partnerCode"
                        label="Mã đối tác"
                        rules={[
                            { required: true, message: 'Nhập mã đối tác!' },
                            {
                                pattern: /^[a-zA-Z0-9._]+$/,
                                message: 'Mã đối tác không được có khoảng trắng hoặc ký tự đặc biệt!',
                            },
                        ]}>
                        <Input size='large' placeholder='ACBD' autoComplete='off' />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={'Cập nhật thông tin đối tác'}
                open={showEditPartner}
                onCancel={() => setShowEditPartner(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formEditPartner.submit()} type="primary">Xác nhận</Button>
                        <Button onClick={() => setShowEditPartner(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}
                width={720}>
                <Form form={formEditPartner}
                    layout="vertical"
                    onFinish={onSubmitEditPartner}>
                    <Form.Item
                        name="partnerId"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>

                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Mã đối tác"
                                name="partnerCode">
                                <Input size='large' disabled={true} readOnly={true} placeholder='ACBD' autoComplete='off' />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="firstName"
                                label="Tên đối tác"
                                rules={[
                                    { required: true, message: 'Nhập tên đối tác' }
                                ]}
                            >
                                <Input size='large' autoComplete='off' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Hạn mức mua thẻ"
                                name="maxBuyCard"
                                rules={[
                                    { required: true, message: 'Nhập hạn mức mua thẻ' }
                                ]}>
                                <Input type="number" size='large' autoComplete='off' />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="balanceReminder"
                                label="Cảnh báo số dư" rules={[
                                    { required: true, message: 'Nhập cảnh báo số dư' }
                                ]}>
                                <Input type="number" size='large' autoComplete='off' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="email"
                                label="Email nhận thông báo"
                                rules={[
                                    { required: true, message: 'Nhập Email nhận thông báo!' },
                                    {
                                        type: 'email',
                                        message: 'Email không hợp lệ. Vui lòng kiểm tra lại!',
                                    },
                                ]}>
                                <Input size='large' placeholder='ACBD' autoComplete='off' />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="phone"
                                label="Số điện thoại"
                                rules={[
                                    { required: true, message: 'Nhập Số điện thoại!' },
                                    {
                                        pattern: /^(0[1-9])+([0-9]{8})\b/,
                                        message: 'Số điện thoại không hợp lệ. Vui lòng kiểm tra lại!',
                                    },
                                ]}>
                                <Input size='large' placeholder="Số điện thoại" autoComplete="off" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal
                title={'Danh sách IP kết nối dịch vụ'}
                open={showUpdateIpWhiteList}
                onCancel={() => setShowUpdateIpWhiteList(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formUpdateIpWhiteList.submit()} type="primary">Xác nhận</Button>
                        <Button onClick={() => setShowUpdateIpWhiteList(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}>
                <Form form={formUpdateIpWhiteList}
                    onFinish={onSubmitUpdatedIp}
                    layout="vertical">
                    <Form.Item
                        name="partnerId"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>
                    {ipWhiteListFields && ipWhiteListFields.map((field: any, index) => (
                        <Row gutter={[20, 20]}>
                            <Col xs={{ span: 20 }} md={{ span: 20 }} lg={{ span: 20 }} xl={{ span: 20 }}>
                                <Form.Item
                                    key={index}
                                    name={['ipWhiteListFields', index, 'ip']}
                                    rules={[
                                        { required: true, message: 'Nhập IP' }
                                    ]}
                                    initialValue={field.ip}>
                                    <Input placeholder="Địa chỉ IP" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <MinusCircleOutlined
                                    style={{ fontSize: '30px', color: '#bc0000' }}
                                    onClick={() => removeIpItem(index)}
                                />
                            </Col>
                        </Row>

                    ))}
                    <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => addIpItem('')}
                            icon={<PlusOutlined />}>
                            Thêm địa chỉ IP
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Breadcrumb style={{ marginBottom: '10px' }}>
                <Breadcrumb.Item>Đối tác</Breadcrumb.Item>
                <Breadcrumb.Item>Danh sách</Breadcrumb.Item>
            </Breadcrumb>

            <Form form={formSearch} layout="vertical">
                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                            name="keySearch"
                            label="Chọn đối tác"
                            >
                            <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                                <option value="" label=''>-- All --</option>
                                {lstPartner?.map((item: any, index) => (
                                    <Select.Option key={index} value={item.user.email} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item name="status"
                            label="Trạng thái"
                        >
                            <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                                placeholder="Trạng thái">
                                <Select.Option key={''} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'INIT'} value='INIT'>
                                    Đăng ký mới
                                </Select.Option>
                                <Select.Option key={'ACTIVE'} value='ACTIVE'>
                                    Hoạt động
                                </Select.Option>
                                <Select.Option key={'INACTIVE'} value='INACTIVE'>
                                    Tạm khóa
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end" style={{ marginBottom: '10px' }}>
                    <Button onClick={() => onPageChanged(1)} type="primary" icon={<SearchOutlined />}>
                        Search
                    </Button>
                </Row>
            </Form>

            <TableContainer>
                <TableContentStyled
                    columns={columns}
                    dataSource={lstData}
                    rowKey="id"
                    bordered
                    pagination={false} />

                <PagingStyled>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={COUPON_ITEMS_PER_PAGE}
                        onChange={onPageChanged}
                        showSizeChanger={false}
                    />
                </PagingStyled>
            </TableContainer>
        </Fragment>
    )
}
