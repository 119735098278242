import { Button, Col, Divider, Switch, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import styled from 'styled-components';

export const TableContainer = styled.div`
  overflow-x: auto;
`;

export const TableContentStyled = styled(Table)`

  && .action-dots {
    text-align: center;
  }

  && .center-data {
    text-align: center;
  }
`;

export const PagingStyled = styled(Content)`
  text-align: right;
  margin-top: 10px;
`;

export const ButtonStyled1 = styled(Button)`
  background: #5965ee;
`;

export const ColContentEndStyled = styled(Col)`
  text-align: end
`;

export const Title0Styled = styled.span`
  font-weight: 500;
`;

export const Title1Styled = styled.span`
  font-size: 15px;
  font-weight: 500;
`;


export const Title2Styled = styled.span`
  font-size: 18px;
  font-weight: 500;
`;

export const ButtonYellowStyled = styled(Button)`
    background-color: #ffe86d; /* Màu nền mặc định của nút */
    color: #000000; /* Màu chữ mặc định của nút */

    &:hover {
      background-color: #ffcc00; /* Màu nền khi di chuột qua */
      color: #000000; /* Màu chữ khi di chuột qua (có thể giữ nguyên hoặc thay đổi) */
    }
`;

export const ButtonPlatedGreenStyled = styled(Button)`
    background-color: #17a2b8; /* Màu nền mặc định của nút */
    color: #ffffff; /* Màu chữ mặc định của nút */

    &:hover {
      background-color: #007b8f; /* Màu nền khi di chuột qua */
      color: #000000; /* Màu chữ khi di chuột qua (có thể giữ nguyên hoặc thay đổi) */
    }
`;

export const SwitchStyled = styled(Switch)`
  background-color: #b59800;
`;

export const ButtonInclubTextAreaStyled = styled(Button)`
  float: right !important;
  padding: 4px 3px 2px 3px;
  font-size: 14px;
`;

export const ContentTabStyled = styled.div`
  padding: 0.5rem;
`;

export const DividerStyled = styled(Divider)`
  color: rgb(89 101 238) !important;
`;


export const TabContentStyled = styled.div`
  padding: 1em;
`;

export const TableStyled = styled.table`
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    && td, th {
        border: 1px solid #2db1e5;
        text-align: left;
        padding: 8px;
    }
      
    && th {
        background-color: #dddddd;
    }
`;