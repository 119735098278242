import { Tabs } from 'antd';
import React, { Fragment, useState } from 'react'
import { AminForControlProviderTab } from './AminForControlProviderTab';
import { AminForControlPartnerTab } from './AminForControlPartnerTab';

export const AdminForControlPage = () => {
    const [tabDefaulf, setTabDefaulf] = useState<any>('provider_tab');
    const onChange = (key: string) => {
        setTabDefaulf(key);
    };

    return (
        <Fragment>
            <Tabs
                activeKey={tabDefaulf}
                onChange={onChange}
                items={[
                    {
                        label: 'Nhà cung cấp',
                        key: 'provider_tab',
                        children: <AminForControlProviderTab />,
                    },
                    {
                        label: 'Đối tác',
                        key: 'partner_tab',
                        children: <AminForControlPartnerTab />,
                    }
                ]}
            />
        </Fragment>
    )
}
