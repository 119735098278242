import { Avatar, Button, Card, Col, DatePicker, Divider, Flex, Form, Input, Modal, Row, Select, message } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import { FaEdit, FaRegCircle, FaRegCopy, FaWallet } from 'react-icons/fa'
import {
    SearchOutlined,
    AntDesignOutlined,
    EyeTwoTone,
    EyeInvisibleOutlined
} from '@ant-design/icons';
import { ButtonPlatedGreenStyled, ButtonYellowStyled, ColContentEndStyled, SwitchStyled, Title0Styled, Title1Styled, Title2Styled } from '../../components/CustomAntStyled'
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { AccountState } from '../../store/account/types';
import { adminService } from '../../services/admin.service';
import { setLoading } from '../../store/loading/actions';
import { partnerService } from '../../services/partner.service';
import { copyToClipboard, currencyFormat } from '../../helpers/FunctionUtils';
import dayjs from 'dayjs';

export const PartnerDashboardPage = () => {
    const dispatch = useDispatch();
    const [formSearch] = Form.useForm();
    const accountState = useSelector<AppState>((state) => state.account) as AccountState;
    const [accountLogin, setAccountLogin] = useState<any>(null);
    const [environment, setEnvironment] = useState<any>(null);

    const [totalTrans, setTotalTrans] = useState<any>(0);
    const [totalAmount, setTotalAmount] = useState<any>(0);
    const [titleChangeEnvironment, setTitleChangeEnvironment] = useState<any>(0);

    useEffect(() => {
        const initData = async () => {
            dispatch(setLoading(true));
            if (!accountLogin) {
                const response = await adminService.getPartnerById(accountState?.user?.id);
                if (response.status === 200) {
                    setAccountLogin(response.data);
                    setEnvironment(response.data.environment === 'PRODUCTION' ? true : false);
                } else {
                    message.error({ content: response.statusText, duration: 5 });
                }

                formSearch.setFieldsValue({
                    tuNgay: dayjs(),
                    denNgay: dayjs(),
                });

                const response1 = await partnerService.getTotalTransByDate(
                    dayjs().format('DD/MM/YYYY 00:00:00'),
                    dayjs().format('DD/MM/YYYY 00:00:00'),
                    accountState?.user?.id,
                    null, null
                );
                if (response1.code === 0) {
                    setTotalTrans(response1.data.totalTrans);
                    setTotalAmount(response1.data.totalAmount);
                }

            }
            dispatch(setLoading(false));
        };
        initData();
    }, []);

    const [formChangePassword] = Form.useForm();
    const [showChangePassword, setShowChangePassword] = useState(false);
    const onSubmitChangePwd = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await partnerService.changePwd(values.currentPassword, values.newPassword)
            if (response.status === 200) {
                message.success({ content: "Đổi mật khẩu thành công", duration: 3 });
                formChangePassword.resetFields();
                setShowChangePassword(false);
            } else {
                message.error({ content: "Đã có lỗi xin thử lại sau", duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [formChangeEmail] = Form.useForm();
    const [showChangeEmail, setShowChangeEmail] = useState(false);
    const onSubmitChangeEmail = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await partnerService.changeInfo(values.email, null, null)
            if (response.status === 200) {
                setAccountLogin(response.data);
                message.success({ content: "Cập nhật thành công", duration: 3 });
                formChangeEmail.resetFields();
                setShowChangeEmail(false);
            } else {
                message.error({ content: "Đã có lỗi xin thử lại sau", duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [formChangePhone] = Form.useForm();
    const [showChangePhone, setShowChangePhone] = useState(false);
    const onSubmitChangePhone = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await partnerService.changeInfo(null, values.phone, null)
            if (response.status === 200) {
                setAccountLogin(response.data);

                message.success({ content: "Cập nhật thành công", duration: 3 });
                formChangePhone.resetFields();
                setShowChangePhone(false);
            } else {
                message.error({ content: "Đã có lỗi xin thử lại sau", duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [formChangeBalanceReminder] = Form.useForm();
    const [showChangeBalanceReminder, setShowChangeBalanceReminder] = useState(false);
    const onSubmitChangeBalanceReminder = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await partnerService.changeInfo(null, null, values.balanceReminder)
            if (response.status === 200) {
                setAccountLogin(response.data);

                message.success({ content: "Cập nhật thành công", duration: 3 });
                formChangeBalanceReminder.resetFields();
                setShowChangeBalanceReminder(false);
            } else {
                message.error({ content: "Đã có lỗi xin thử lại sau", duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [checkEvn, setCheckEvn] = useState<any>(null);
    const onSearch = async () => {
        dispatch(setLoading(true));
        try {
            const values = await formSearch.validateFields();
            const dayCheck = dayjs(values.denNgay).diff(dayjs(values.tuNgay), 'day');
            if (dayCheck > 90) {
                message.error("Khoản thời gian tìm kiếm không được quá 90 ngày");
                return;
            }
            const response1 = await partnerService.getTotalTransByDate(
                values.tuNgay.format('DD/MM/YYYY 00:00:00'),
                values.denNgay.format('DD/MM/YYYY 00:00:00'),
                accountState?.user?.id,
                values.productType, values.type
            );
            if (response1.code === 0) {
                setTotalTrans(response1.data.totalTrans);
                setTotalAmount(response1.data.totalAmount);
            }
        } catch (error) {
            message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const changeEnvironment = async (checked: any) => {
        let title = '';
        if (environment) {
            title = 'Xác nhận chuyển đổi chế độ từ TEST sang PRODUCTION'
        } else {
            title = 'Xác nhận chuyển đổi chế độ từ PRODUCTION sang TEST'
        }
        setTitleChangeEnvironment(title)
        setCheckEvn(checked);
        setShowChangeEnvironment(true);
    };

    const [formChangeEnvironment] = Form.useForm();
    const [showChangeEnvironment, setShowChangeEnvironment] = useState(false);
    const onSubmitChangeEnvironment = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await partnerService.changeEnvironment(values.password);
            if (response.status === 200) {
                formChangeEnvironment.resetFields();
                setAccountLogin(response.data);
                setEnvironment(response.data.environment === 'PRODUCTION' ? true : false);
                setEnvironment(checkEvn);
                setShowChangeEnvironment(false);
            } else {
                message.error({ content: response.statusText, duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 5 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <Fragment>
            <Modal
                title={'Cập nhật mật khẩu'}
                open={showChangePassword}
                onCancel={() => setShowChangePassword(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formChangePassword.submit()} type="primary">Xác nhận</Button>
                        <Button onClick={() => setShowChangePassword(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}>
                <Form form={formChangePassword}
                    layout="vertical"
                    onFinish={onSubmitChangePwd}>

                    <Form.Item
                        name="currentPassword"
                        rules={[
                            { required: true, message: 'Nhập mật khẩu hiện tại!' },
                            {
                                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
                                message: 'Mật khẩu phải trên 8 ký tự và phải chứa chữ và số!',
                            },
                        ]}>
                        <Input.Password
                            size="large"
                            placeholder='Mật khẩu hiện tại'
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>

                    <Form.Item
                        name="newPassword"
                        rules={[
                            { required: true, message: 'Nhập mật khẩu!' },
                            {
                                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
                                message: 'Mật khẩu phải trên 8 ký tự và phải chứa chữ và số!',
                            },
                        ]}>
                        <Input.Password
                            size="large"
                            placeholder='Mật khẩu mới'
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>

                    <Form.Item
                        name="confrimPassword"
                        rules={[
                            { required: true, message: 'Nhập xác nhận mật khẩu mới!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Xác nhận mật khẩu không khớp!'));
                                },
                            }),
                        ]}>
                        <Input.Password
                            size="large"
                            placeholder='Xác Nhận Mật khẩu mới'
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={'Cập nhật email'}
                open={showChangeEmail}
                onCancel={() => setShowChangeEmail(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formChangeEmail.submit()} type="primary">Xác nhận</Button>
                        <Button onClick={() => setShowChangeEmail(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}>
                <Form form={formChangeEmail}
                    layout="vertical"
                    onFinish={onSubmitChangeEmail}>
                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: 'Nhập Email bạn muốn cập nhật!' },
                            {
                                type: 'email',
                                message: 'Email không hợp lệ. Vui lòng kiểm tra lại!',
                            },
                        ]}>
                        <Input placeholder="Nhập Email bạn muốn cập nhật" autoComplete="off" />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={'Cập nhật email'}
                open={showChangeEmail}
                onCancel={() => setShowChangeEmail(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formChangeEmail.submit()} type="primary">Xác nhận</Button>
                        <Button onClick={() => setShowChangeEmail(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}>
                <Form form={formChangeEmail}
                    layout="vertical"
                    onFinish={onSubmitChangeEmail}>
                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: 'Nhập Email bạn muốn cập nhật!' },
                            {
                                type: 'email',
                                message: 'Email không hợp lệ. Vui lòng kiểm tra lại!',
                            },
                        ]}>
                        <Input placeholder="Nhập Email bạn muốn cập nhật" autoComplete="off" />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={'Cập nhật số điện thoại'}
                open={showChangePhone}
                onCancel={() => setShowChangePhone(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formChangePhone.submit()} type="primary">Xác nhận</Button>
                        <Button onClick={() => setShowChangePhone(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}>

                <Form form={formChangePhone}
                    layout="vertical"
                    onFinish={onSubmitChangePhone}>
                    <Form.Item
                        name="phone"
                        rules={[
                            { required: true, message: 'Nhập Số điện thoại!' },
                            {
                                pattern: /^(0[1-9])+([0-9]{8})\b/,
                                message: 'Số điện thoại không hợp lệ. Vui lòng kiểm tra lại!',
                            },
                        ]}>
                        <Input placeholder="Số điện thoại" autoComplete="off" />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={'Cập nhật cảnh báo số dư'}
                open={showChangeBalanceReminder}
                onCancel={() => setShowChangeBalanceReminder(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formChangeBalanceReminder.submit()} type="primary">Xác nhận</Button>
                        <Button onClick={() => setShowChangeBalanceReminder(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}>

                <Form form={formChangeBalanceReminder}
                    layout="vertical"
                    onFinish={onSubmitChangeBalanceReminder}>
                    <Form.Item
                        name="balanceReminder"
                        label="Cảnh báo số dư" rules={[
                            { required: true, message: 'Nhập cảnh báo số dư' }
                        ]}>
                        <Input type="number" size='large' autoComplete='off' />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={titleChangeEnvironment}
                open={showChangeEnvironment}
                onCancel={() => setShowChangeEnvironment(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formChangeEnvironment.submit()} type="primary">Xác nhận</Button>
                        <Button onClick={() => setShowChangeEnvironment(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}
                width={'750px'}>

                <Form form={formChangeEnvironment}
                    layout="vertical"
                    onFinish={onSubmitChangeEnvironment}>
                    <Form.Item
                        name="password"
                        label="Nhập mật khẩu để xác nhận chuyển đổi" rules={[
                            { required: true, message: 'Nhập mật khẩu để xác nhận chuyển đổi' }
                        ]}>
                        <Input.Password
                            size="large"
                            placeholder='Mật khẩu'
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>
                    <span style={{ color: 'green' }}>
                        - Chế độ Test và chế độ Prod các thông tin kết nối không đổi. <br/>
                        - Giao dịch tại chế độ Test không kiểm tra số dư và không trừ số dư tài khoản của đối tác.<br/>
                        - Mọi API đã gọi thành công tại chế độ Test chắc chắn sẽ thành công ở chế độ Prod.
                    </span>
                </Form>
            </Modal>

            <Row gutter={[20, 20]} style={{ marginBottom: '10px' }}>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                    <Flex gap="middle" align="center">
                        <Avatar
                            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                            icon={<AntDesignOutlined />}
                        />
                        <Flex gap="middle" align="center" vertical>
                            <Title1Styled>{accountState?.user?.fullName}</Title1Styled>
                            <SwitchStyled
                                checked={environment}
                                checkedChildren="Chế độ Prod"
                                unCheckedChildren="Chế độ Test"
                                onChange={(checked: any) => changeEnvironment(checked)}
                            />
                        </Flex>
                    </Flex>
                </Col>
            </Row>

            <Row gutter={[20, 20]} style={{ marginBottom: '10px' }}>
                <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                    <Card>
                        <Row gutter={[20, 20]}>
                            <Col span={20}>
                                <Title1Styled>Partner Code</Title1Styled>
                                <br />
                                {accountLogin?.partnerCode}
                            </Col>
                            <ColContentEndStyled span={4}>
                                <ButtonPlatedGreenStyled onClick={() => copyToClipboard(accountLogin?.partnerCode)}>
                                    <FaRegCopy />
                                </ButtonPlatedGreenStyled>
                            </ColContentEndStyled>
                        </Row>
                    </Card>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                    <Card>
                        <Row gutter={[20, 20]}>
                            <Col span={20}>
                                <Title1Styled>Secret Key</Title1Styled>
                                <br />
                                {accountLogin?.secretKey}
                            </Col>
                            <ColContentEndStyled span={4}>
                                <ButtonPlatedGreenStyled onClick={() => copyToClipboard(accountLogin?.secretKey)}>
                                    <FaRegCopy />
                                </ButtonPlatedGreenStyled>
                            </ColContentEndStyled>
                        </Row>
                    </Card>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                    <Card>
                        <Row gutter={[20, 20]}>
                            <Col span={20}>
                                <Title1Styled>Số dư</Title1Styled>
                                <br />
                                <Title0Styled>{currencyFormat(accountLogin?.balance, 'VND')}</Title0Styled>
                            </Col>
                            <ColContentEndStyled span={4}>
                                <ButtonPlatedGreenStyled>
                                    <FaWallet />
                                </ButtonPlatedGreenStyled>
                            </ColContentEndStyled>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row gutter={[20, 20]} style={{ marginBottom: '10px' }}>
                <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                    <Card>
                        <Row gutter={[20, 20]}>
                            <Col span={20}>
                                <Title1Styled>Password</Title1Styled>
                                <br />
                                <FaRegCircle /> <FaRegCircle /> <FaRegCircle /> <FaRegCircle /> <FaRegCircle />
                            </Col>
                            <ColContentEndStyled span={4}>
                                <ButtonYellowStyled onClick={() => setShowChangePassword(true)}>
                                    <FaEdit />
                                </ButtonYellowStyled>
                            </ColContentEndStyled>
                        </Row>
                    </Card>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                    <Card>
                        <Row gutter={[20, 20]}>
                            <Col span={20}>
                                <Title1Styled>Email</Title1Styled>
                                <br />
                                {accountLogin?.user.email}
                            </Col>
                            <ColContentEndStyled span={4}>
                                <ButtonYellowStyled onClick={() => setShowChangeEmail(true)}>
                                    <FaEdit />
                                </ButtonYellowStyled>
                            </ColContentEndStyled>
                        </Row>
                    </Card>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                    <Card>
                        <Row gutter={[20, 20]}>
                            <Col span={20}>
                                <Title1Styled>Phone number</Title1Styled>
                                <br />
                                {accountLogin?.phone}
                            </Col>
                            <ColContentEndStyled span={4}>
                                <ButtonYellowStyled onClick={() => setShowChangePhone(true)}>
                                    <FaEdit />
                                </ButtonYellowStyled>
                            </ColContentEndStyled>
                        </Row>
                    </Card>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                    <Card>
                        <Row gutter={[20, 20]}>
                            <Col span={20}>
                                <Title1Styled>Cảnh báo số dư</Title1Styled>
                                <br />
                                {currencyFormat(accountLogin?.balanceReminder, 'VND')}
                            </Col>
                            <ColContentEndStyled span={4}>
                                <ButtonYellowStyled onClick={() => setShowChangeBalanceReminder(true)}>
                                    <FaEdit />
                                </ButtonYellowStyled>
                            </ColContentEndStyled>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Divider dashed />
            <Form form={formSearch}
                layout="vertical">

                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                            name="tuNgay" // Tên của trường trong form
                            label='Từ ngày'
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn ngày đăng',
                                }
                            ]}>
                            <DatePicker
                                format="DD/MM/YYYY"
                                showTime={false}
                                inputReadOnly={true}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                            name="denNgay" // Tên của trường trong form
                            label='Đến ngày'
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn ngày đăng',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const tuNgayValue = getFieldValue('tuNgay');
                                        if (value && value.isBefore(tuNgayValue, 'day')) {
                                            return Promise.reject('Phải lớn hơn thời gian bắt đầu');
                                        } else {
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}>
                            <DatePicker
                                format="DD/MM/YYYY"
                                showTime={false}
                                inputReadOnly={true}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item name="productType" label="Nhà mạng">
                            <Select defaultValue={'ALL'} style={{ width: '100%' }}
                                placeholder="Nhà mạng">
                                <Select.Option key={'ALL'} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'VIETTEL'} value='VIETTEL'>
                                    VIETTEL
                                </Select.Option>
                                <Select.Option key={'VINAPHONE'} value='VINAPHONE'>
                                    VINAPHONE
                                </Select.Option>
                                <Select.Option key={'MOBIFONE'} value='MOBIFONE'>
                                    MOBIFONE
                                </Select.Option>
                                <Select.Option key={'VIETNAMOBILE'} value='VIETNAMOBILE'>
                                    VIETNAMOBILE
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item name="type"
                            label="Loại giao dịch">
                            <Select defaultValue={'ALL'} style={{ width: '100%' }}
                                placeholder="Loại giao dịch">
                                <Select.Option key={'ALL'} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'TOPUP'} value='TOPUP'>
                                    Topup
                                </Select.Option>
                                <Select.Option key={'BUY_CARD'} value='BUY_CARD'>
                                    Mua thẻ cào
                                </Select.Option>
                                <Select.Option key={'TOPUP_BY_FILE'} value='TOPUP_BY_FILE'>
                                    Nạp file
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="end" style={{ marginBottom: '10px' }}>
                    <Button type="primary" onClick={onSearch} icon={<SearchOutlined />}>
                        Search
                    </Button>
                </Row>
            </Form>

            <Row gutter={[20, 20]} style={{ marginTop: '20px' }}>
                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <Card style={{ textAlign: 'center', background: '#ff7f0e', color: 'white' }}>
                        <Title2Styled>{totalTrans}</Title2Styled><br />
                        Tổng số giao dịch
                    </Card>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <Card style={{ textAlign: 'center', background: '#2ca02c', color: 'white' }}>
                        <Title2Styled>{currencyFormat(totalAmount, 'VND')}</Title2Styled><br />
                        Tổng tiền giao dịch
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}
