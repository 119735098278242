import React, { Fragment, useState } from 'react'
import { RateChangeScheduleProviderTab } from './RateChangeScheduleProviderTab';
import { RateChangeSchedulePartnerTab } from './RateChangeSchedulePartnerTab';
import { Tabs } from 'antd';

export const RateChangeSchedulePage = () => {
  const [tabDefaulf, setTabDefaulf] = useState<any>('provider_tab');

  const onChange = (key: string) => {
    setTabDefaulf(key);
  };

  return (
    <Fragment>
      <Tabs
        activeKey={tabDefaulf}
        onChange={onChange}
        items={[
          {
            label: 'Nhà cung cấp',
            key: 'provider_tab',
            children: <RateChangeScheduleProviderTab />,
          },
          {
            label: 'Đối tác',
            key: 'partner_tab',
            children: <RateChangeSchedulePartnerTab />,
          }
        ]}
      />
    </Fragment>
  )
}
