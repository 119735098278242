import React, { Fragment, useEffect, useState } from 'react'
import { Button, Card, Col, DatePicker, Form, Row, Select, Tag, message } from 'antd';
import {
    SearchOutlined
} from '@ant-design/icons';

import { adminService } from '../../services/admin.service';
import { ColContentEndStyled, DividerStyled, SwitchStyled, Title1Styled, Title2Styled } from '../../components/CustomAntStyled';
import { setLoading } from '../../store/loading/actions';
import { useDispatch } from 'react-redux';
import { currencyFormat } from '../../helpers/FunctionUtils';
import dayjs from 'dayjs';
import { partnerService } from '../../services/partner.service';
import Swal from 'sweetalert2';

export const AdmindashboardPage = () => {
    const dispatch = useDispatch();
    const [lstData, setLstData] = useState<any[]>([]);
    const [lstPartner, setLstPartner] = useState<any[]>([]);
    const [formSearch] = Form.useForm();

    const [totalTrans, setTotalTrans] = useState<any>(0);
    const [totalAmount, setTotalAmount] = useState<any>(0);
    const [totalProfit, setTotalProfit] = useState<any>(0);

    const [environment, setEnvironment] = useState<boolean>(true);

    useEffect(() => {
        const initData = async () => {
            dispatch(setLoading(true));
            try {
                const response = await adminService.filterProvider(0, 999, null, null)
                if (response.status === 200) {
                    setLstData(response.data.data);
                } else {
                    message.error({ content: response.message, duration: 3 });
                }

                const resPartner = await adminService.filterPartner(0, 9999, null, null, 0)
                if (resPartner.status === 200) {
                    setLstPartner(resPartner.data.data);
                } else {
                    message.error({ content: resPartner.message, duration: 3 });
                }

                formSearch.setFieldsValue({
                    tuNgay: dayjs(),
                    denNgay: dayjs(),
                });
                const response1 = await partnerService.getTotalTransByDate(
                    dayjs().format('DD/MM/YYYY 00:00:00'),
                    dayjs().format('DD/MM/YYYY 00:00:00'),
                    null,
                    null,
                    null
                );
                if (response1.code === 0) {
                    setTotalTrans(response1.data.totalTrans);
                    setTotalAmount(response1.data.totalAmount);
                    setTotalProfit(response1.data.totalProfit)
                }
                const modeSystem = await adminService.getModeSystem();
                if (modeSystem.status == 200) {
                    setEnvironment(modeSystem.data.valueSetting == '0' ? true : false)
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 5 });
            }
            dispatch(setLoading(false));
        };
        initData();
    }, []);

    const onSearch = async () => {
        dispatch(setLoading(true));
        try {
            const values = await formSearch.validateFields();
            const dayCheck = dayjs(values.denNgay).diff(dayjs(values.tuNgay), 'day');
            if (dayCheck > 90) {
                message.error("Khoản thời gian tìm kiếm không được quá 90 ngày");
                return;
            }
            const response1 = await partnerService.getTotalTransByDate(
                values.tuNgay.format('DD/MM/YYYY 00:00:00'),
                values.denNgay.format('DD/MM/YYYY 00:00:00'),
                values.partnerId,
                values.productType, values.type
            );
            if (response1.code === 0) {
                setTotalTrans(response1.data.totalTrans);
                setTotalAmount(response1.data.totalAmount);
                setTotalProfit(response1.data.totalProfit)
            }
        } catch (error) {
            message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const changeEnvironment = async () => {
        let title = '';
        let valueSetting = '';
        if (environment) {
            title = 'bật';
            valueSetting = '1';
        } else {
            title = 'tắt';
            valueSetting = '0';
        }

        const result = await Swal.fire({
            title: `Xác nhận ${title} chế độ Bảo Trì`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `${title.toUpperCase()}`,
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const modeSystem = await adminService.updateModeSystem(valueSetting);
                if (modeSystem.status == 200) {
                    setEnvironment(modeSystem.data.valueSetting == '0' ? true : false);
                    message.success({ content: "Thành công", duration: 3 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 5 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return (
        <Fragment>
            <DividerStyled orientation="right" orientationMargin="0" dashed style={{ fontSize: '18px' }}>
                <SwitchStyled
                    checked={environment}
                    checkedChildren="Hoạt động"
                    unCheckedChildren="Bảo trì"
                    onChange={changeEnvironment}
                />
            </DividerStyled>
            <DividerStyled orientation="left" orientationMargin="0" dashed style={{ fontSize: '18px' }}>
                Số dư tại nhà cung cấp
            </DividerStyled>
            <Row gutter={[20, 20]} style={{ marginBottom: '10px' }}>
                {lstData?.map((item: any) => (
                    item.status === 'INACTIVE' ? null : (
                        <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Card title={item.user.firstName}>
                                <Row gutter={[20, 20]}>
                                    <Col span={14}>
                                        <Title1Styled>
                                            Số dư: {currencyFormat(item.balance, 'VND')}
                                        </Title1Styled>
                                    </Col>
                                    <ColContentEndStyled span={10}>
                                        <Tag color={item.status === 'ACTIVE' ? 'rgb(52 166 55)' : item.status === 'INACTIVE' ? 'rgba(206, 206, 206, 1)' : 'rgb(255 181 42)'}>
                                            {item.status === 'ACTIVE' ? 'Hoạt động' : item.status === 'INACTIVE' ? 'Tạm khóa' : 'Mới tạo'}
                                        </Tag>
                                    </ColContentEndStyled>
                                </Row>
                            </Card>
                        </Col>
                    )
                ))}
            </Row>
            <DividerStyled orientation="left" orientationMargin="0" dashed style={{ fontSize: '18px', marginTop: '20px' }}>
                Tổng quan số liệu
            </DividerStyled>

            <Form form={formSearch}
                layout="vertical">

                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item
                            name="tuNgay" // Tên của trường trong form
                            label='Từ ngày'
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn ngày đăng',
                                }
                            ]}>
                            <DatePicker
                                format="DD/MM/YYYY"
                                showTime={false}
                                inputReadOnly={true}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item
                            name="denNgay" // Tên của trường trong form
                            label='Đến ngày'
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn ngày đăng',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const tuNgayValue = getFieldValue('tuNgay');
                                        if (value && value.isBefore(tuNgayValue, 'day')) {
                                            return Promise.reject('Phải lớn hơn thời gian bắt đầu');
                                        } else {
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}>
                            <DatePicker
                                format="DD/MM/YYYY"
                                showTime={false}
                                inputReadOnly={true}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                            name="partnerId"
                            label="Đối tác">
                            <Select defaultValue={''} showSearch optionFilterProp="label">
                                <option value="" label=''>-- All --</option>
                                {lstPartner?.map((item: any, index) => (
                                    <Select.Option key={index} value={item.id} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item name="productType" label="Nhà mạng">
                            <Select defaultValue={'ALL'} style={{ width: '100%' }}
                                placeholder="Nhà mạng">
                                <Select.Option key={'ALL'} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'VIETTEL'} value='VIETTEL'>
                                    VIETTEL
                                </Select.Option>
                                <Select.Option key={'VINAPHONE'} value='VINAPHONE'>
                                    VINAPHONE
                                </Select.Option>
                                <Select.Option key={'MOBIFONE'} value='MOBIFONE'>
                                    MOBIFONE
                                </Select.Option>
                                <Select.Option key={'VIETNAMOBILE'} value='VIETNAMOBILE'>
                                    VIETNAMOBILE
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item name="type"
                            label="Loại giao dịch">
                            <Select defaultValue={'ALL'} style={{ width: '100%' }}
                                placeholder="Loại giao dịch">
                                <Select.Option key={'ALL'} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'TOPUP'} value='TOPUP'>
                                    Topup
                                </Select.Option>
                                <Select.Option key={'BUY_CARD'} value='BUY_CARD'>
                                    Mua thẻ cào
                                </Select.Option>
                                <Select.Option key={'TOPUP_BY_FILE'} value='TOPUP_BY_FILE'>
                                    Nạp file
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="end" style={{ marginBottom: '10px' }}>
                    <Button type="primary" onClick={onSearch} icon={<SearchOutlined />}>
                        Search
                    </Button>
                </Row>
            </Form>
            <Row gutter={[20, 20]} style={{ marginTop: '20px' }}>
                <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                    <Card style={{ textAlign: 'center', background: '#ff7f0e', color: 'white' }}>
                        <Title2Styled>{totalTrans}</Title2Styled><br />
                        Tổng số giao dịch
                    </Card>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                    <Card style={{ textAlign: 'center', background: 'rgb(89 101 238)', color: 'white' }}>
                        <Title2Styled>{currencyFormat(totalAmount, 'VND')}</Title2Styled><br />
                        Doanh số
                    </Card>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                    <Card style={{ textAlign: 'center', background: '#2ca02c', color: 'white' }}>
                        <Title2Styled>{currencyFormat(totalProfit, 'VND')}</Title2Styled><br />
                        Lợi nhuận
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}
