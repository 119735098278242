import { Dropdown } from 'antd';
import PropTypes from 'prop-types';

function DropdownActions({ items }: { items: any[] }) {
    const itemsTemp = items.map((item, index) => ({
        label: item.label,
        key: index.toString(),
        icon: item.icon ? item.icon : null,
        onClick: item.onClick(),
    }));
    return (
        <Dropdown menu={{ items: itemsTemp }}>
            <span >
                <img style={{ width: '30px' }} src='/imgs/icon-dots.png' alt="icon" />
            </span>
        </Dropdown>
    );
}

DropdownActions.propTypes = {
    items: PropTypes.array.isRequired,
};

export default DropdownActions;
