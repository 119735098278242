import { Button, Divider, Flex, Form, Input, message, Modal, Row, Tabs } from 'antd';
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { adminService } from '../../services/admin.service';
import { AppState } from '../../store';
import { AccountState } from '../../store/account/types';
import {
  CopyOutlined,
  SaveOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined
} from '@ant-design/icons';
import { ButtonInclubTextAreaStyled, ButtonPlatedGreenStyled, ContentTabStyled } from '../../components/CustomAntStyled';
import { copyToClipboard } from '../../helpers/FunctionUtils';
import { partnerService } from '../../services/partner.service';
import styled from 'styled-components';
import Paragraph from 'antd/es/typography/Paragraph';

const { TextArea } = Input;
export const PartnerSettingPage = () => {
  return (
    <Fragment>
      <Tabs
        items={[
          {
            label: 'RSA Key',
            key: 'Key_RSA',
            children: <RsaKeyTab />,
          }
        ]}
      />

    </Fragment>
  )
}

export const RsaKeyTab = () => {
  const dispatch = useDispatch();
  const accountState = useSelector<AppState>((state) => state.account) as AccountState;
  const [accountLogin, setAccountLogin] = useState<any>(null);
  const [partnerRsaPublicKey, setPartnerRsaPublicKey] = useState('');
  const [formUpdate] = Form.useForm();

  useEffect(() => {
    const initData = async () => {
      dispatch(setLoading(true));
      if (!accountLogin) {
        const response = await adminService.getPartnerById(accountState?.user?.id);
        if (response.status === 200) {
          setAccountLogin(response.data);
          setPartnerRsaPublicKey(response.data.partnerRsaPublicKey);
          formUpdate.setFieldsValue({
            urlCallback: response.data.urlCallBack,
          })
        } else {
          message.error({ content: response.statusText, duration: 5 });
        }
      }
      dispatch(setLoading(false));
    };
    initData();
  }, []);

  const updatePartnerRsaPublicKey = async () => {
    if (partnerRsaPublicKey === '') {
      message.warning('RSA public key của đối tác rỗng');
      return;
    }
    setShowChangeInfo(true);
  };

  const [formConfirm] = Form.useForm();
  const [showChangeInfo, setShowChangeInfo] = useState(false);
  const onSubmitChangeEnvironment = async (values: any) => {
    dispatch(setLoading(true));
    try {
      const valuesInfo = await formUpdate.validateFields();
      const response = await partnerService.changeInfoConnect(values.password, valuesInfo.urlCallback, partnerRsaPublicKey)
      if (response.status === 200) {
        formConfirm.resetFields();
        setShowChangeInfo(false);
        message.success({ content: "Cập nhật thành công", duration: 3 });
      } else {
        message.error({ content: "Đã có lỗi xin thử lại sau", duration: 5 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 5 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <ContentTabStyled>
      <Modal
        title="Xác nhận cập nhật thông tin kết nối"
        open={showChangeInfo}
        onCancel={() => setShowChangeInfo(false)}
        maskClosable={false}
        footer={[
          <Flex gap="small" justify='flex-end'>
            <Button onClick={() => formConfirm.submit()} type="primary">Xác nhận</Button>
            <Button onClick={() => setShowChangeInfo(false)} type="default">
              Cancel
            </Button>
          </Flex>
        ]}>

        <Form form={formConfirm}
          layout="vertical"
          onFinish={onSubmitChangeEnvironment}>
          <Form.Item
            name="password"
            label="Nhập mật khẩu để xác nhận" rules={[
              { required: true, message: 'Nhập mật khẩu để xác nhận' }
            ]}>
            <Input.Password
              size="large"
              placeholder='Mật khẩu'
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
        </Form>
      </Modal>


      <Form layout="vertical"
        form={formUpdate}
        onFinish={updatePartnerRsaPublicKey}>
        <Form.Item label="API nhận kết quả, từ các trường hợp chơ xử lý (code = 1)"
          name="urlCallback"
          rules={[
            {
              message: 'Nhập api callback',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || /^(ftp|http|https):\/\/[^ "]+$/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Nhập một URL hợp lệ'));
              },
            }),
          ]}>
          <Input size='large' placeholder='https://abc.com.vn/abc-abc' />
        </Form.Item>

        <Form.Item label="RSA Publickey (Đối tác Cung cấp cho Link Pay)"
          rules={[
            {
              required: true,
              message: 'Nhập RSA Publickey',
            }
          ]}>
          <TextArea
            rows={9}
            autoSize={{ minRows: 3 }}
            value={partnerRsaPublicKey}
            onChange={(e) => setPartnerRsaPublicKey(e.target.value)}
          />
        </Form.Item>

        <ParagraphTextStyled>
          <strong style={{ color: 'red' }}>* </strong>Trang thao khảo gen RSA online: <a href='https://www.lddgo.net/en/encrypt/rsakey' target='_blank'> https://www.lddgo.net/en/encrypt/rsakey </a>
        </ParagraphTextStyled>
        <Row justify="end">
          <ButtonPlatedGreenStyled type="primary" onClick={() => formUpdate.submit()} icon={<SaveOutlined />}>
            Cập nhật
          </ButtonPlatedGreenStyled>
        </Row>

        <Divider />
        <Form.Item label="RSA Publickey 2048 (Link Pay Cung cấp cho đối tác)">
          <Input.Group compact>
            <TextArea
              rows={9}
              value={accountLogin?.serviceRsaPublicKey}
              readOnly={true}
              autoSize
            />
            <ButtonInclubTextAreaStyled type="primary" icon={<CopyOutlined />}
              onClick={() => copyToClipboard(accountLogin?.serviceRsaPublicKey)}>
              Copy
            </ButtonInclubTextAreaStyled>
          </Input.Group>
        </Form.Item>
      </Form>
    </ContentTabStyled>
  )
}

const ParagraphTextStyled = styled(Paragraph)`
    font-size: 15px;
`