import React, { Fragment, useEffect, useState } from 'react'
import {
    SearchOutlined,
    PlusOutlined
} from '@ant-design/icons';
import {
    FaCheck, FaCheckDouble, FaTimes
} from 'react-icons/all'

import { Breadcrumb, Button, Col, Flex, Form, Input, InputNumber, Modal, Pagination, Row, Select, Tag, message } from 'antd'
import { adminService } from '../../services/admin.service';
import { ColumnsType } from 'antd/es/table';
import { TableContentStyled, PagingStyled, TableContainer, ButtonStyled1 } from '../../components/CustomAntStyled';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { currencyFormat, formatDateTime } from '../../helpers/FunctionUtils';
import DropdownActions from '../../components/DropdownActions';
import Swal from 'sweetalert2';

export const BalanceOrderListPage = () => {
    const dispatch = useDispatch();
    const COUPON_ITEMS_PER_PAGE = 10;
    const [formSearch] = Form.useForm();
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lstData, setLstData] = useState<any[]>([]);
    const [lstPartner, setLstPartner] = useState<any[]>([]);

    const renderActionModal = (item: any) => {
        const props = [
            {
                icon: <FaCheck size={20} color='rgb(2 0 255)' />,
                label: 'Xác nhận lệnh',
                onClick: () => handleConfirm(item.id, item.partner.id, item.balanceOrderStatus),
            },
            {

                icon: <FaCheckDouble size={20} color='rgb(0 177 28)' />,
                label: 'Duyệt lệnh',
                onClick: () => handleApproved(item.id, item.partner.id, item.balanceOrderStatus),
            },
            {
                icon: <FaTimes size={20} color='rgb(255 132 132)' />,
                label: 'Hủy lệnh',
                onClick: () => handleCancel(item.id, item.partner.id, item.balanceOrderStatus),
            }
        ];
        return <DropdownActions items={props} />;
    };

    const handleConfirm = (id: any, partnerId: any, status: any) => async () => {
        if (status !== 'KHOI_TAO') {
            message.error("Không thể xác nhận vì trạng thái lệnh hiện tại đã khác Khởi Tạo");
            onPageChanged(currentPage);
            return;
        }

        const result = await Swal.fire({
            title: 'Xác nhận lệnh?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Xác nhận',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await adminService.confirmBalanceOrder(id, partnerId)
                if (response.code === 0) {
                    message.success({ content: response.message });
                    onPageChanged(currentPage);
                } else {
                    message.error({ content: response.message, duration: 3 });
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handleApproved = (id: any, partnerId: any, status: any) => async () => {
        if (status !== 'XAC_NHAN') {
            message.error("Lệnh phải được xác nhận trước khi duyệt");
            onPageChanged(currentPage);
            return;
        }

        const result = await Swal.fire({
            title: 'Duyệt lệnh?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Xác nhận duyệt',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await adminService.approvedBalanceOrder(id, partnerId)
                if (response.code === 0) {
                    message.success({ content: response.message });
                    onPageChanged(currentPage);
                } else {
                    message.error({ content: response.message, duration: 3 });
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handleCancel = (id: any, partnerId: any, status: any) => async () => {
        if (status === 'THAT_BAI') {
            message.error("Lệnh đã được hủy trước đó");
            onPageChanged(currentPage);
            return;
        }

        if (status === 'THANH_CONG') {
            message.error("Không thể hủy vì lệnh đã được duyệt");
            onPageChanged(currentPage);
            return;
        }

        const result = await Swal.fire({
            title: 'Hủy lệnh?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Xác nhận Hủy',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await adminService.cancelBalanceOrder(id, partnerId)
                if (response.code === 0) {
                    message.success({ content: response.message });
                    onPageChanged(currentPage);
                } else {
                    message.error({ content: response.message, duration: 3 });
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const columns: ColumnsType<any> = [
        {
            title: <p>Tên<br /> đối tác</p>,
            dataIndex: 'partner',
            className: 'center-data',
            render: (partner: any) => <span>{partner.user?.firstName}</span>,
        },
        {
            title: <p>Loại lệnh</p>,
            dataIndex: 'balanceOrderType',
            className: 'center-data',
            render: (balanceOrderType: any) =>
                <Tag color={balanceOrderType === 'NAP' ? 'rgb(52 166 55)' : 'rgb(255 181 42)'}>
                    {balanceOrderType === 'NAP' ? 'Nạp tiền' : 'Rút tiền'}
                </Tag>,
        },
        {
            title: <p>Số trước</p>,
            dataIndex: 'balanceOld',
            render: (balanceOld: any) => <span>{balanceOld ? currencyFormat(balanceOld, 'VND') : '--'}</span>,
        },
        {
            title: <p>Số tiền</p>,
            dataIndex: 'amount',
            render: (amount: any) => <span>{currencyFormat(amount, 'VND')}</span>,
        },
        {
            title: <p>Số sau</p>,
            dataIndex: 'balanceNew',
            render: (balanceNew: any) => <span>{
                balanceNew ? currencyFormat(balanceNew, 'VND') : '--'
            }</span>,
        },
        {
            title: <p>Mô tả</p>,
            dataIndex: 'descriptions',
            render: (descriptions: any) => <span>{
                descriptions ? descriptions : '--'
            }</span>,
        },
        {
            title: <p>Người tạo</p>,
            dataIndex: 'createBy',
            className: 'center-data',
            render: (createBy: any) => <span>{
                createBy ? createBy : '--'
            }</span>,
        },
        {
            title: <p>Người<br />xác nhận</p>,
            dataIndex: 'confirmBy',
            className: 'center-data',
            render: (confirmBy: any) => <span>{
                confirmBy ? confirmBy : '--'
            }</span>,
        },
        {
            title: <p>Người<br />phê duyệt</p>,
            dataIndex: 'confirmBy',
            className: 'center-data',
            render: (confirmBy: any) => <span>{
                confirmBy ? confirmBy : '--'
            }</span>,
        },
        {
            title: <p>Khởi tạo</p>,
            dataIndex: 'createdDate',
            className: 'center-data',
            render: (createdDate: any) => <span>{formatDateTime(createdDate)}</span>,
        },
        {
            title: <p>Trạng thái</p>,
            dataIndex: 'balanceOrderStatus',
            className: 'center-data',
            render: (balanceOrderStatus: string) =>
                <Tag color={balanceOrderStatus === 'KHOI_TAO' ? 'rgb(225 228 15)'
                    : balanceOrderStatus === 'XAC_NHAN' ? 'rgb(2 0 255)'
                        : balanceOrderStatus === 'THANH_CONG' ? 'rgb(0 177 28)'
                            : 'rgb(255 132 132 / 88%)'}>
                    {
                        balanceOrderStatus === 'KHOI_TAO' ? 'Mới tạo'
                            : balanceOrderStatus === 'XAC_NHAN' ? 'Đã xác nhận'
                                : balanceOrderStatus === 'THANH_CONG' ? 'Đã duyệt'
                                    : 'Đã hủy'
                    }
                </Tag>
        },
        {
            title: <p>Actions</p>,
            dataIndex: 'id',
            className: 'action-dots',
            render: (id: any, record: any) =>
                renderActionModal(record)
            ,
        },
    ];

    const getDataList = async (partnerId: any, keySearch: any, type: any, status: any, page: number, size: number) => {
        try {
            const response = await adminService.filterBalanceOrder(page - 1, size, partnerId, keySearch, type, status)
            if (response.status === 200) {
                setTotal(response.headers['x-total-count']);
                setLstData(response.data.data);
            } else {
                message.error({ content: response.message, duration: 3 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 3 });
        }
    };

    useEffect(() => {
        const initData = async () => {
            dispatch(setLoading(true));
            try {
                await getDataList(null, null, null, null, currentPage, COUPON_ITEMS_PER_PAGE);
                const response = await adminService.filterPartner(0, 9999, null, null, 0)
                if (response.status === 200) {
                    setLstPartner(response.data.data);
                } else {
                    message.error({ content: response.message, duration: 3 });
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }
        };
        initData();
    }, []);

    const onPageChanged = async (pageNumber: number) => {
        dispatch(setLoading(true));
        try {
            setCurrentPage(pageNumber);
            const values = await formSearch.validateFields();
            await getDataList(values.partnerId, values.keySearch, values.type, values.status, pageNumber, COUPON_ITEMS_PER_PAGE);
        } catch (error) {
            message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [formNew] = Form.useForm();
    const [showNewLenh, setShowNewLenh] = useState(false);
    const onSubmitNew = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await adminService.createBalanceOrder(values.partnerId, values.type, values.amount, values.descriptions);
            if (response.code === 0) {
                setShowNewLenh(false);
                message.success(response.message);
                formNew.resetFields();
                onPageChanged(1);
            } else {
                message.error({ content: response.message, duration: 3 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <Fragment>
            <Modal
                title={'Tạo lệnh Nạp/Rút'}
                open={showNewLenh}
                onCancel={() => setShowNewLenh(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formNew.submit()} type="primary">Xác nhận</Button>
                        <Button onClick={() => setShowNewLenh(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}
                width={750}>
                <Form form={formNew}
                    layout="vertical"
                    onFinish={onSubmitNew}>

                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="partnerId"
                                label="Đối tác"
                                rules={[
                                    {
                                        required: true,
                                        message: "Chọn đối tác"
                                    }
                                ]}
                            >
                                <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                                    {lstPartner?.map((item: any, index) => (
                                        <Select.Option key={index} value={item.id} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="type"
                                label="Loại lệnh" rules={[
                                    {
                                        required: true,
                                        message: "Chọn loại lệnh"
                                    }
                                ]}>
                                <Select size='large' value={'NAP'} style={{ width: '100%' }}>
                                    <Select.Option key={'NAP'} value='NAP'>
                                        Nạp tiền
                                    </Select.Option>
                                    <Select.Option key={'RUT'} value='RUT'>
                                        Rút tiền
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Số tiền"
                                name="amount"
                                rules={[
                                    { required: true, message: 'Nhập số tiền' }
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    size='large'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Mô tả lệnh"
                                name="descriptions"
                                rules={[
                                    { required: true, message: 'Nhập mô tả' }
                                ]}>
                                <Input size='large' autoComplete='off' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Breadcrumb style={{ marginBottom: '10px' }}>
                <Breadcrumb.Item>Đối tác</Breadcrumb.Item>
                <Breadcrumb.Item>Lệnh nạp tiền đối tác</Breadcrumb.Item>
            </Breadcrumb>

            <Form form={formSearch}
                layout='vertical'>
                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                            name="partnerId"
                            label="Đối tác">
                            <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                                <option value="" label=''>-- All --</option>
                                {lstPartner?.map((item: any, index) => (
                                    <Select.Option key={index} value={item.id} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item name="type"
                            label="Loại lệnh">
                            <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                                placeholder="Trạng thái">
                                <Select.Option key={''} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'NAP'} value='NAP'>
                                    Nạp tiền
                                </Select.Option>
                                <Select.Option key={'RUT'} value='RUT'>
                                    Rút tiền
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item name="status"
                            label="Trạng thái">
                            <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                                placeholder="Trạng thái">
                                <Select.Option key={''} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'INIT'} value='INIT'>
                                    Đăng ký mới
                                </Select.Option>
                                <Select.Option key={'ACTIVE'} value='ACTIVE'>
                                    Hoạt động
                                </Select.Option>
                                <Select.Option key={'INACTIVE'} value='INACTIVE'>
                                    Tạm khóa
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item name="keySearch"
                            label="Từ khóa">
                            <Input size='large' placeholder='Mô tả' autoComplete='off' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end" style={{ marginBottom: '10px' }}>
                    <ButtonStyled1 onClick={() => setShowNewLenh(true)} style={{ marginRight: '10px' }} type='primary' icon={<PlusOutlined />}>
                        Tạo lệnh
                    </ButtonStyled1>
                    <Button onClick={() => onPageChanged(1)} type="primary" icon={<SearchOutlined />}>
                        Search
                    </Button>
                </Row>
            </Form>

            <TableContainer>
                <TableContentStyled
                    columns={columns}
                    bordered
                    dataSource={lstData}
                    rowKey="id"
                    pagination={false} />

                <PagingStyled>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={COUPON_ITEMS_PER_PAGE}
                        onChange={onPageChanged}
                        showSizeChanger={false}
                    />
                </PagingStyled>
            </TableContainer>
        </Fragment>
    )
}
