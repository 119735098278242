import React, { Fragment, useEffect, useState } from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    TeamOutlined,
    UserSwitchOutlined,
    DesktopOutlined,
    DashboardOutlined,
    LogoutOutlined,
    UserOutlined,
    DownOutlined,
    DropboxOutlined,
    SettingOutlined,
    FileTextOutlined,
    MergeCellsOutlined,
    PercentageOutlined,
    DollarOutlined,
    EyeTwoTone,
    EyeInvisibleOutlined
} from '@ant-design/icons';
import { Layout, Menu, Button, theme, Dropdown, Row, Col, Divider, message, Form, Modal, Flex, Input, QRCode } from 'antd';
import type { MenuProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store';
import { AccountState } from '../store/account/types';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import { logout } from '../store/account/actions';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaGoogle, FaHandshake, FaKey } from 'react-icons/fa';
import { setLoading } from '../store/loading/actions';
import { adminService } from '../services/admin.service';
import { SwitchStyled } from '../components/CustomAntStyled';

const { Header, Sider, Content } = Layout;
type MenuItem = Required<MenuProps>['items'][number];
function getItem(label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

export const LayoutAdmin = ({ children }: { children: React.ReactNode }) => {
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const accountLogin = useSelector<AppState>((state) => state.account) as AccountState;
    const navigate = useNavigate();

    const location = useLocation();
    const [menuOpenKey, setMenuOpenKey] = useState<string>('');
    const [menuSelectKey, setMenuSelect] = useState<string>('');

    useEffect(() => {
        const handleResize = () => {
            // Check the screen width and set the collapsed state accordingly
            const isMobile = window.innerWidth < 768; // Adjust the threshold as needed
            setCollapsed(isMobile);
        };
        // Initial check on component mount
        handleResize();
        if (!menuSelectKey && accountLogin.user) {
            if (location.pathname === '/') {
                if (accountLogin.user.userRole === 'ROLE_ADMIN') {
                    setMenuOpenKey('admin_partner')
                    setMenuSelect('admin_partner_list');
                } else if (accountLogin.user.userRole === 'ROLE_PARTNER') {
                    setMenuOpenKey('')
                    setMenuSelect('partner_dashboard');
                }
            } else {
                if (location.pathname.includes("admin/products")) {
                    setMenuSelect('admin_system_danh_sach_dich_vu');
                    setMenuOpenKey('admin_system');
                } else if (location.pathname.includes("admin/rate-change-schedules")) {
                    setMenuSelect('admin_system_rate_schedule');
                    setMenuOpenKey('admin_system');
                } else if (location.pathname.includes("admin/partner-list")) {
                    setMenuOpenKey('admin_partner');
                    setMenuSelect('admin_partner_list');
                } else if (location.pathname.includes("admin/balance-partner")) {
                    setMenuOpenKey('admin_partner');
                    setMenuSelect('admin_balance_order_list');
                } else if (location.pathname.includes('admin/partner-discount')) {
                    setMenuOpenKey('admin_partner');
                    setMenuSelect('admin_partner_discount');
                } else if (location.pathname.includes('admin/balance-start-of-day')) {
                    setMenuOpenKey('admin_partner');
                    setMenuSelect('admin_balance_start_of_day');
                } else if (location.pathname.includes('/admin/wasehouse')) {
                    setMenuOpenKey('admin_partner');
                    setMenuSelect('admin_wasehousr_list');
                } else if (location.pathname.includes('/admin/balance-fluctuations')) {
                    setMenuOpenKey('admin_partner');
                    setMenuSelect('admin_partner_fluctuations');
                } else if (location.pathname.includes('/partner/dashboard')) {
                    setMenuOpenKey('')
                    setMenuSelect('partner_dashboard');
                } else if (location.pathname.includes('/partner/setting')) {
                    setMenuOpenKey('')
                    setMenuSelect('partner_setting');
                } else if (location.pathname.includes('/partner/document')) {
                    setMenuOpenKey('')
                    setMenuSelect('partner_document');
                } else if (location.pathname.includes('/partner/trans')) {
                    setMenuOpenKey('partner_trans_root');
                    setMenuSelect('partner_trans');
                } else if (location.pathname.includes('/partner/control')) {
                    setMenuOpenKey('partner_trans_root');
                    setMenuSelect('partner_trans_control');
                } else if (location.pathname.includes('/partner/topup-by-file')) {
                    setMenuOpenKey('partner_trans_root');
                    setMenuSelect('partner_topup_by_file');
                } else if (location.pathname.includes('/partner/discount')) {
                    setMenuOpenKey('');
                    setMenuSelect('partner_discount');
                } else if (location.pathname.includes('/partner/deposit')) {
                    setMenuOpenKey('partner_balance');
                    setMenuSelect('partner_deposit');
                } else if (location.pathname.includes('/partner/balance-fluctuation')) {
                    setMenuOpenKey('partner_balance');
                    setMenuSelect('partner_fluctuations');
                } else if (location.pathname.includes('/admin/provider-list')) {
                    setMenuOpenKey('admin_provider');
                    setMenuSelect('admin_provider_list');
                } else if (location.pathname.includes('/admin/provider-discount')) {
                    setMenuOpenKey('admin_provider');
                    setMenuSelect('admin_provider_discount');
                } else if (location.pathname.includes('/admin/trans')) {
                    setMenuOpenKey('admin_provider');
                    setMenuSelect('admin_trans');
                } else if (location.pathname.includes('/admin/dashboard')) {
                    setMenuOpenKey('admin_provider');
                    setMenuSelect('admin_dashboard');
                } else if (location.pathname.includes('/admin/control')) {
                    setMenuOpenKey('admin_trans_root');
                    setMenuSelect('admin_trans_control');
                }
            }
        }
    }, [location.pathname, accountLogin, menuSelectKey]);

    const itemsAdmin: MenuItem[] = [
        getItem('Dashboard', 'admin_dashboard', <DashboardOutlined style={{ fontSize: '18px' }} />),

        getItem('Hệ thống', 'admin_system', <DesktopOutlined style={{ fontSize: '18px' }} />, [
            getItem('Dịch vụ', 'admin_system_danh_sach_dich_vu'),
            getItem('Lịch set CK', 'admin_system_rate_schedule'),
        ]),

        getItem('Kho hàng', 'admin_wasehousr', <DropboxOutlined style={{ fontSize: '18px' }} />, [
            getItem('Danh sách kho', 'admin_wasehousr_list'),
        ]),

        getItem('Giao dịch', 'admin_trans_root', <MergeCellsOutlined style={{ fontSize: '18px' }} />, [
            getItem('Danh sách', 'admin_trans'),
            getItem('Đối soát', 'admin_trans_control'),
        ]),

        getItem('Đối tác', 'admin_partner', <FaHandshake style={{ fontSize: '18px' }} />, [
            getItem('Danh Sách', 'admin_partner_list'),
            getItem('Chiết Khấu', 'admin_partner_discount'),
            getItem('Số dư đầu ngày', 'admin_balance_start_of_day'),
            getItem('Lệnh số dư', 'admin_balance_order_list'),
            getItem('Biến động', 'admin_partner_fluctuations'),
        ]),

        getItem('Nhà cung cấp', 'admin_provider', <UserSwitchOutlined style={{ fontSize: '18px' }} />, [
            getItem('Danh Sách', 'admin_provider_list'),
            getItem('Chiết Khấu', 'admin_provider_discount')
        ]),
    ];

    const itemsPartner: MenuItem[] = [
        getItem('Dashboard', 'partner_dashboard', <DashboardOutlined style={{ fontSize: '18px' }} />),
        getItem('Chiết khấu', 'partner_discount', <PercentageOutlined style={{ fontSize: '18px' }} />),

        getItem('Lệnh số dư', 'partner_balance', <DollarOutlined style={{ fontSize: '18px' }} />, [
            getItem('Lệnh nạp', 'partner_deposit'),
            getItem('Biến động', 'partner_fluctuations'),
        ]),

        getItem('Giao dịch', 'partner_trans_root', <MergeCellsOutlined style={{ fontSize: '18px' }} />, [
            getItem('Danh sách', 'partner_trans'),
            getItem('Nạp theo file', 'partner_topup_by_file'),
            getItem('Đối soát', 'partner_trans_control'),
        ]),

        getItem('Cài đặt', 'partner_setting', <SettingOutlined style={{ fontSize: '18px' }} />),
        getItem('Tài liệu', 'partner_document', <FileTextOutlined style={{ fontSize: '18px' }} />),
    ];

    const handleMenuItemClick = (key: React.Key) => {
        setMenuSelect(key.toString());
        switch (key) {
            case 'admin_system_danh_sach_dich_vu':
                setMenuOpenKey('admin_system');
                setMenuSelect('admin_system_danh_sach_dich_vu');
                navigate('/admin/products')
                break;
            case 'admin_system_rate_schedule':
                setMenuOpenKey('admin_system');
                setMenuSelect('admin_system_rate_schedule');
                navigate('/admin/rate-change-schedules')
                break;
            // case 'admin_wasehousr_list':
            //     setMenuOpenKey('admin_system');
            //     navigate('/admin/wasehouse')
            //     break;
            case 'admin_balance_order_list':
                setMenuOpenKey('admin_partner');
                setMenuSelect('admin_balance_order_list');
                navigate('/admin/balance-partner');
                break;
            case 'admin_partner_list':
                setMenuOpenKey('admin_partner');
                setMenuSelect('admin_partner_list');
                navigate('/admin/partner-list')
                break;
            case 'admin_partner_discount':
                setMenuOpenKey('admin_partner');
                setMenuSelect('admin_partner_discount');
                navigate('/admin/partner-discount')
                break;
            case 'admin_balance_start_of_day':
                setMenuOpenKey('admin_partner');
                setMenuSelect('admin_balance_start_of_day');
                navigate('/admin/balance-start-of-day')
                break;

            case 'admin_partner_fluctuations':
                setMenuOpenKey('admin_partner');
                setMenuSelect('admin_partner_fluctuations');
                navigate('/admin/balance-fluctuations')
                break;
            case 'partner_dashboard':
                setMenuOpenKey('');
                setMenuSelect('partner_dashboard');
                navigate('/partner/dashboard')
                break;
            case 'partner_setting':
                setMenuOpenKey('');
                setMenuSelect('partner_setting');
                navigate('/partner/setting')
                break;
            case 'partner_document':
                setMenuOpenKey('');
                setMenuSelect('partner_document');
                navigate('/partner/document')
                break;
            case 'partner_trans':
                setMenuOpenKey('partner_trans_root');
                setMenuSelect('partner_trans');
                navigate('/partner/trans')
                break;
            case 'partner_trans_control':
                setMenuOpenKey('partner_trans_root');
                setMenuSelect('partner_trans_control');
                navigate('/partner/control')
                break;
            case 'partner_topup_by_file':
                setMenuOpenKey('partner_trans_root');
                setMenuSelect('partner_topup_by_file');
                navigate('/partner/topup-by-file')
                break;
            case 'partner_discount':
                setMenuOpenKey('');
                setMenuSelect('partner_discount');
                navigate('/partner/discount')
                break;
            case 'partner_deposit':
                setMenuOpenKey('partner_balance');
                setMenuSelect('partner_deposit');
                navigate('/partner/deposit')
                break;
            case 'partner_fluctuations':
                setMenuOpenKey('partner_balance');
                setMenuSelect('partner_fluctuations');
                navigate('/partner/balance-fluctuation')
                break;
            case 'admin_provider_list':
                setMenuOpenKey('admin_provider');
                setMenuSelect('admin_provider_list');
                navigate('/admin/provider-list')
                break;
            case 'admin_provider_discount':
                setMenuOpenKey('admin_provider');
                setMenuSelect('admin_provider_discount');
                navigate('/admin/provider-discount')
                break;
            case 'admin_trans':
                setMenuOpenKey('admin_trans_root');
                setMenuSelect('admin_trans');
                navigate('/admin/trans')
                break;
            case 'admin_trans_control':
                setMenuOpenKey('admin_trans_root');
                setMenuSelect('admin_trans_control');
                navigate('/admin/control')
                break;
            case 'admin_dashboard':
                setMenuOpenKey('admin_provider');
                setMenuSelect('admin_dashboard');
                navigate('/admin/dashboard')
                break;
            default:
                message.warning("Tính năng đang được phát triển");
        }
    };

    const dispatch = useDispatch();
    const handleItemClick = async (key: any) => {
        if (key === '1') {
            const result = await Swal.fire({
                title: 'Xác nhận thoát',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, Logout',
                confirmButtonColor: '#d42b3c',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            });
            if (result.isConfirmed) {
                dispatch(logout());
                navigate('/')
            }
        } else if (key === '2') {
            setShowChangePassword(true);
        } else if (key === '3') {
            onInitAuthenticator();
        }
    };

    const dropDownitemsPartner = [
        {
            label: accountLogin.user?.fullName,
            key: '0',
            onClick: () => handleItemClick('0'),
        },
        {
            label: 'Logout',
            key: '1',
            icon: <LogoutOutlined />,
            onClick: () => handleItemClick('1'),
        }
    ];

    const dropDownitemsAdmin = [
        {
            key: '0',
            label: accountLogin.user?.fullName,
            onClick: () => handleItemClick('0'),
        },
        {
            key: '2',
            label: 'Change password',
            icon: <FaKey />,
            onClick: () => handleItemClick('2'),
        },
        {
            key: '3',
            label: 'Google authenticator',
            icon: <FaGoogle />,
            onClick: () => handleItemClick('3'),
        },
        {
            label: 'Logout',
            key: '1',
            icon: <LogoutOutlined />,
            onClick: () => handleItemClick('1'),
        }
    ];

    const [formChangePassword] = Form.useForm();
    const [showChangePassword, setShowChangePassword] = useState(false);
    const onSubmitChangePwd = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await adminService.adminChangePwd(values.currentPassword, values.newPassword)
            if (response.status === 200) {
                message.success({ content: "Đổi mật khẩu thành công", duration: 3 });
                formChangePassword.resetFields();
                setShowChangePassword(false);
            } else {
                message.error({ content: "Đã có lỗi xin thử lại sau", duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [formAuthenticator] = Form.useForm();
    const [showAuthenticator, setShowAuthenticator] = useState(false);
    const [qrCode, setQrCode] = useState<string>('');
    const [enabled2Fa, setEnabled2Fa] = useState<boolean>(true);
    const onInitAuthenticator = async () => {
        dispatch(setLoading(true));
        try {
            const response = await adminService.initGoogle2Fa();
            if (response.code === 0) {
                setQrCode(response.data.strQRCode);
                setEnabled2Fa(response.data.enabled2fa);
                setShowAuthenticator(true);
            } else {
                message.error({ content: response.data.message, duration: 3 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const onEnble2Fa = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await adminService.onOffAuthenticatorConfirm(true, values.code);
            if (response.code === 0) {
                setQrCode(response.data.strQRCode);
                setEnabled2Fa(response.data.enabled2Fa);
                setShowAuthenticator(false);
                formAuthenticator.resetFields();
                message.success({ content: "Bật bảo mật đăng nhập bằng Google Authenticator thành công", duration: 5 });
            } else {
                message.error({ content: response.message, duration: 5 });
            }

        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const changeEnvironment = async () => {
        dispatch(setLoading(true));
        try {
            const response = await adminService.onOffAuthenticatorConfirm(false, '');
            if (response.code === 0) {
                setQrCode(response.data.strQRCode);
                setEnabled2Fa(response.data.enabled2Fa);
                formAuthenticator.resetFields();
                setShowAuthenticator(false);
                message.success({ content: "Tắt thành công", duration: 5 });
            } else {
                message.error({ content: response.message, duration: 5 });
            }

        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <Layout>
            <Modal
                title={'Cập nhật mật khẩu'}
                open={showChangePassword}
                onCancel={() => setShowChangePassword(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formChangePassword.submit()} type="primary">Xác nhận</Button>
                        <Button onClick={() => setShowChangePassword(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}>
                <Form form={formChangePassword}
                    layout="vertical"
                    onFinish={onSubmitChangePwd}>

                    <Form.Item
                        name="currentPassword"
                        rules={[
                            { required: true, message: 'Nhập mật khẩu hiện tại!' },
                            {
                                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
                                message: 'Mật khẩu phải trên 8 ký tự và phải chứa chữ và số!',
                            },
                        ]}>
                        <Input.Password
                            size="large"
                            placeholder='Mật khẩu hiện tại'
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>

                    <Form.Item
                        name="newPassword"
                        rules={[
                            { required: true, message: 'Nhập mật khẩu!' },
                            {
                                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
                                message: 'Mật khẩu phải trên 8 ký tự và phải chứa chữ và số!',
                            },
                        ]}>
                        <Input.Password
                            size="large"
                            placeholder='Mật khẩu mới'
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>

                    <Form.Item
                        name="confrimPassword"
                        rules={[
                            { required: true, message: 'Nhập xác nhận mật khẩu mới!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Xác nhận mật khẩu không khớp!'));
                                },
                            }),
                        ]}>
                        <Input.Password
                            size="large"
                            placeholder='Xác Nhận Mật khẩu mới'
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={'Bật bảo mật đăng nhập Google Authenticator'}
                open={showAuthenticator}
                onCancel={() => setShowAuthenticator(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        {enabled2Fa === true ? null : <Button onClick={() => formAuthenticator.submit()} type="primary">Xác nhận</Button>}
                        <Button onClick={() => setShowAuthenticator(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}>
                <Form form={formAuthenticator}
                    layout="vertical"
                    onFinish={onEnble2Fa}>
                    {
                        enabled2Fa === true ?
                            (
                                <Fragment>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <SwitchStyled
                                            checked={enabled2Fa}
                                            checkedChildren="Đang hoạt động"
                                            unCheckedChildren="Ngưng hoạt động"
                                            onChange={changeEnvironment}
                                        />
                                        <p>Click để tắt cài đặt</p>
                                    </div>
                                </Fragment>
                            )
                            :
                            (
                                <Fragment>
                                    <Form.Item>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <QRCode value={qrCode} size={200} />
                                        </div>
                                    </Form.Item>

                                    <Form.Item
                                        name="code"
                                        label='Quét mã Google Authenticator trên và nhập mật mã'
                                        rules={[
                                            { required: true, message: 'Nhập mã từ ứng dụng Google Authenticator' },
                                            { pattern: /^[0-9]{6}$/, message: 'Mã phải gồm 6 chữ số' }
                                        ]}>
                                        <Input
                                            size="large"
                                            placeholder='Gồm 6 số'
                                        />
                                    </Form.Item>
                                </Fragment>
                            )
                    }
                </Form>
            </Modal>

            <Sider collapsed={collapsed}>
                <div className="demo-logo-vertical" style={{ textAlign: 'center', marginTop: '10px' }}>
                    <img src='/imgs/logo_icon.png' alt='icon' style={{ width: '50px' }} />
                </div>
                <Divider style={{ background: 'white', color: 'white' }} />
                {
                    accountLogin.user?.userRole === 'ROLE_ADMIN' ?
                        (<Menu
                            theme="dark"
                            mode="inline"
                            items={itemsAdmin}
                            defaultOpenKeys={[menuOpenKey]}
                            selectedKeys={[menuSelectKey]}
                            onClick={({ key }) => handleMenuItemClick(key)}
                        />)
                        :
                        (<Menu
                            theme="dark"
                            mode="inline"
                            items={itemsPartner}
                            defaultOpenKeys={[menuOpenKey]}
                            selectedKeys={[menuSelectKey]}
                            onClick={({ key }) => handleMenuItemClick(key)}
                        />)
                }

            </Sider>
            <Layout>
                <Header style={{ padding: 0, background: colorBgContainer }}>
                    <Row style={{ marginRight: '15px' }}>
                        <HeaderColStyled>
                            <Button
                                type="text"
                                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                onClick={() => setCollapsed(!collapsed)}
                                style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                }}
                            />
                        </HeaderColStyled>
                        <Col flex={'auto'} />

                        <HeaderColStyled>
                            <HeaderNotificationStyled>
                                <img src="/imgs/notification.png" alt="Notification" />
                                <NumberNotificationStyled>
                                    <span>0</span>
                                </NumberNotificationStyled>
                            </HeaderNotificationStyled>
                        </HeaderColStyled>

                        <HeaderColStyled>
                            <HeaderAccountStyled>
                                {
                                    accountLogin.user?.userRole === 'ROLE_ADMIN' ?
                                        (
                                            <Dropdown menu={{ items: dropDownitemsAdmin }}>
                                                <span>
                                                    <UserOutlined style={{ fontSize: '150%' }} /><DownOutlined style={{ fontSize: '80%' }} />
                                                </span>
                                            </Dropdown>
                                        ) :
                                        (
                                            <Dropdown menu={{ items: dropDownitemsPartner }}>
                                                <span>
                                                    <UserOutlined style={{ fontSize: '150%' }} /><DownOutlined style={{ fontSize: '80%' }} />
                                                </span>
                                            </Dropdown>
                                        )

                                }
                            </HeaderAccountStyled>
                        </HeaderColStyled>
                    </Row>
                </Header>
                <Content
                    style={{
                        minHeight: '100vh',
                        padding: '10px'
                    }}>
                    <div
                        style={{
                            minHeight: '100vh',
                            background: 'white',
                            padding: '15px'
                        }}>
                        {children}
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default LayoutAdmin;

const HeaderColStyled = styled(Col)`
  display: flex;
  align-items: center;
`;

const HeaderNotificationStyled = styled(Content)`
  position: relative;
    && img {
      vertical-align: middle;
    }
`;

const NumberNotificationStyled = styled(Content)`
  position: absolute;
  top: 10px;
  right: -8px;
  width: 18px;
  height: 18px;
  background: rgba(255, 136, 144, 1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
    && span {
      color: white;
      font-size: 14px;
    }
`;

const HeaderAccountStyled = styled(Content)`
  margin-left: 20px;
  border-radius: 67px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
    && span {
      color: rgb(0 36 255);
      position: relative;
      top: -30%
    }
`;