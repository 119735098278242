import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { adminService } from '../../services/admin.service';
import { Breadcrumb, Button, Col, Flex, Form, Input, InputNumber, Modal, Pagination, Row, Select, Tag, message } from 'antd';
import { ButtonStyled1, PagingStyled, TableContainer, TableContentStyled } from '../../components/CustomAntStyled';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { currencyFormat } from '../../helpers/FunctionUtils';
import { MdLock, MdModeEdit } from 'react-icons/md';
import { FaUnlock } from 'react-icons/fa';
import DropdownActions from '../../components/DropdownActions';
import Swal from 'sweetalert2';
import { RuleObject } from 'antd/es/form';

export const PartnerDiscountListPage = () => {
    const dispatch = useDispatch();
    const [lstPartner, setLstPartner] = useState<any[]>([]);
    const [lstProvider, setLstProvider] = useState<any[]>([]);

    const [formSearch] = Form.useForm();
    const ITEMS_PER_PAGE = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotal] = useState(0);
    const [lstData, setLstData] = useState<any[]>([]);

    const validateJSON = (_: RuleObject, value: string) => {
        try {
            if (!value) {
                return Promise.resolve(); // Trả về true nếu chuỗi rỗng
            }

            JSON.parse(value);
            return Promise.resolve();
        } catch (error) {
            return Promise.reject('Chuỗi nhập vào không phải là JSON hợp lệ!');
        }
    };

    const getDataList = async (partnerId: any, keySearch: any, type: any, status: any, page: number, size: number) => {
        try {
            const response = await adminService.filterProductByPartner(page - 1, size, partnerId, keySearch, type, status)
            if (response.status === 200) {
                setTotal(response.headers['x-total-count']);
                setLstData(response.data.data);
            } else {
                message.error({ content: response.message, duration: 3 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 3 });
        }
    };

    useEffect(() => {
        const initData = async () => {
            dispatch(setLoading(true));
            try {
                const resPartner = await adminService.filterPartner(0, 9999, null, null, 0)
                if (resPartner.status === 200) {
                    setLstPartner(resPartner.data.data);
                } else {
                    message.error({ content: resPartner.message, duration: 3 });
                }

                const resProvider = await adminService.filterProvider(0, 999, null, null)
                if (resProvider.status === 200) {
                    setLstProvider(resProvider.data.data);
                } else {
                    message.error({ content: resProvider.message, duration: 3 });
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }
        };
        initData();
    }, []);

    const onPageChanged = async (pageNumber: number) => {
        dispatch(setLoading(true));
        try {
            setCurrentPage(pageNumber);
            const values = await formSearch.validateFields();
            await getDataList(values.partnerId, values.keySearch, values.type, values.status, pageNumber, ITEMS_PER_PAGE);
        } catch (error) {
            message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleActiveProduct = (id: any) => async () => {
        const result = await Swal.fire({
            title: 'Xác nhận mở khóa sản phẩm',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Mở khóa',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await adminService.upodateStatusProductPartner(id, 'ACTIVE');
                if (response.code === 0) {
                    onPageChanged(currentPage);
                    message.success({ content: response.message, duration: 3 });
                } else {
                    message.error({ content: response.message, duration: 5 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 5 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handleLockProduct = (id: any) => async () => {
        const result = await Swal.fire({
            title: 'Xác nhận khóa sản phẩm',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'red',
            confirmButtonText: 'Khóa',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await adminService.upodateStatusProductPartner(id, 'INACTIVE');
                if (response.code === 0) {
                    onPageChanged(currentPage);
                    message.success({ content: response.message, duration: 3 });
                } else {
                    message.error({ content: response.message, duration: 5 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 5 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const renderActionModal = (id: any, item: any) => {
        const props = [
            {
                icon: <MdModeEdit size={20} color='#006aff' />,
                label: 'Edit',
                onClick: () => handleEdit(id, item.partner.user.firstName, item.productRoot.name,
                    item.discountPercent, item.discountAmount, item.feePercent, item.feeAmount, item.discountTopupPercent, item.discountTopupAmount,
                    item.partner.id, item.directional, item.directionalPercent
                ),
            },
            (item.status === 'INACTIVE')
                ? {

                    icon: <FaUnlock size={20} color='green' />,
                    label: 'Mở khóa',
                    onClick: () => handleActiveProduct(item.id),
                }
                :
                {
                    icon: <MdLock size={20} color='#34343' />,
                    label: 'Tạm đóng',
                    onClick: () => handleLockProduct(item.id),
                },
        ];
        return <DropdownActions items={props} />;
    };

    const columns: ColumnsType<any> = [
        {
            title: <p>Đối tác</p>,
            dataIndex: 'partner',
            className: 'center-data',
            render: (partner: any) => <span>{partner.user.firstName}</span>,
        },
        {
            title: <p>Nhà mạng</p>,
            dataIndex: 'productRoot',
            className: 'center-data',
            render: (productRoot: any) => <span>{productRoot.type}</span>,
        },
        {
            title: <p>Mã sản phẩm</p>,
            dataIndex: 'productRoot',
            className: 'center-data',
            render: (productRoot: any) => <span>{productRoot.productCode}</span>,
        },
        {
            title: <p>Tên</p>,
            dataIndex: 'productRoot',
            className: 'center-data',
            render: (productRoot: any) => <span>{productRoot.name}</span>,
        },
        {
            title: <p>Mệnh giá</p>,
            dataIndex: 'productRoot',
            className: 'center-data',
            render: (productRoot: any) => <span>{currencyFormat(productRoot.cardAmount, 'VND')}</span>,
        },
        {
            title: <p>Chiết khấu <br /> topup (%)</p>,
            dataIndex: 'discountTopupPercent',
            className: 'center-data',
            render: (discountPercent: any) => <span>{`${discountPercent} %`}</span>,
        },
        {
            title: <p>Chiết khấu<br /> topup (VNĐ)</p>,
            dataIndex: 'discountTopupAmount',
            className: 'center-data',
            render: (discountAmount: any) => <span>{currencyFormat(discountAmount, 'VND')}</span>,
        },
        {
            title: <p>Chiết khấu<br />mua thẻ (%)</p>,
            dataIndex: 'discountPercent',
            className: 'center-data',
            render: (discountPercent: any) => <span>{`${discountPercent} %`}</span>,
        },
        {
            title: <p>Chiết khấu<br />mua thẻ (VNĐ)</p>,
            dataIndex: 'discountAmount',
            className: 'center-data',
            render: (discountAmount: any) => <span>{currencyFormat(discountAmount, 'VND')}</span>,
        },
        {
            title: <p>Phí %</p>,
            dataIndex: 'feePercent',
            className: 'center-data',
            render: (feePercent: any) => <span>{`${feePercent} %`}</span>,
        },
        {
            title: <p>Phí VNĐ</p>,
            dataIndex: 'feeAmount',
            className: 'center-data',
            render: (feeAmount: any) => <span>{currencyFormat(feeAmount, 'VND')}</span>,
        },
        {
            title: <p>Trạng thái</p>,
            dataIndex: 'status',
            className: 'center-data',
            render: (status: string) =>
                <Tag color={status === 'ACTIVE' ? 'rgb(52 166 55)' : 'rgba(206, 206, 206, 1)'}>
                    {status === 'ACTIVE' ? 'Hoạt động' : 'Tạm khóa'}
                </Tag>
        },
        {
            title: <p>Điều hướng</p>,
            dataIndex: 'directional',
            className: 'center-data',
            render: (directional: string) =>
                <span>
                    {directional === 'cmsthecao' ? 'CMS Thẻ cào'
                        : directional === 'nap69' ? "Nạp 69"
                            : directional === 'wpay' ? "Linh San - Wpay"
                                : directional === 'ncctopup' ? "NCC Topup"
                                    : directional === 'nap69_btkc' ? "Nạp 69 BTKC"
                                        : directional === 'eco' ? "Eco"
                                            : '--'}
                </span>,
        },
        {
            title: <p>Actions</p>,
            dataIndex: 'id',
            className: 'action-dots',
            render: (id: any, record: any) =>
                renderActionModal(id, record)
            ,
        },
    ];

    const [lstProductByProvider, setLstProductByProvider] = useState<any[]>([]);
    const onChangeSelectProvider = async (selectedValue: any, selectedOption: any) => {
        formCreateProduct.setFieldsValue({
            productRootId: '',
        })
        if (selectedValue === '') {
            setLstProductByProvider([]);
            return;
        }
        try {
            const dataTempValue = selectedOption?.dataTemp;
            dispatch(setLoading(true));
            const response = await adminService.filterProductByProvider(0, 9999, dataTempValue, null, null, null);
            if (response.status === 200) {
                setLstProductByProvider(response.data.data);
            } else {
                setLstProductByProvider([]);
                message.error({ content: response.message, duration: 3 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 5 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [formCreateProduct] = Form.useForm();
    const [showCreateProduct, setShowCreateProduct] = useState(false);
    const onSubmitCreateProduct = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await adminService.createProductPartner(values.directional, values.productRootId, values.partnerId, values.discountPercent,
                values.discountAmount, values.feePercent, values.feeAmount, values.discountTopupPercent, values.discountTopupAmount)
            if (response.code === 0) {
                // setShowCreateProduct(false);
                // formCreateProduct.resetFields();
                formSearch.setFieldsValue({
                    partnerId: values.partnerId,
                })
                onPageChanged(currentPage);
                message.success({ content: "Thêm mới thành công", duration: 3 });
            } else {
                message.error({ content: response.message, duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 5 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleEdit = (id: any, tenDoiTac: any, tenDv: any, discountPercent: any, discountAmount: any, feePercent: any, feeAmount: any, discountTopupPercent: any, discountTopupAmount: any, partnerId: any, directional: any, directionalPercent: any) => async () => {
        dispatch(setLoading(true));
        try {
            formEditProduct.setFieldsValue({
                productId: id,
                partnerName: tenDoiTac,
                productRootName: tenDv,
                discountPercent: discountPercent,
                discountAmount: discountAmount,
                feePercent: feePercent,
                feeAmount: feeAmount,
                discountTopupPercent: discountTopupPercent,
                discountTopupAmount: discountTopupAmount,
                partnerId: partnerId,
                directional: directional,
                directionalPercent: directionalPercent
            });
            setShowEditProduct(true);
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 5 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [formEditProduct] = Form.useForm();
    const [showEditProduct, setShowEditProduct] = useState(false);
    const onSubmitEditProduct = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await adminService.updateProduct(
                values.productId,
                values.directional,
                values.discountPercent,
                values.discountAmount,
                values.feePercent,
                values.feeAmount,
                values.discountTopupPercent,
                values.discountTopupAmount,
                values.directionalPercent
            )
            if (response.status === 200) {
                setShowEditProduct(false);
                formEditProduct.resetFields();
                formSearch.setFieldsValue({
                    partnerId: values.partnerId,
                })
                onPageChanged(currentPage);
                message.success({ content: "Cập nhật thành công", duration: 3 });
            } else {
                message.error({ content: response.message, duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <Fragment>
            <Modal
                title={'Thêm mới dịch vụ'}
                open={showCreateProduct}
                onCancel={() => setShowCreateProduct(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formCreateProduct.submit()} type="primary">Tạo mới</Button>
                        <Button onClick={() => setShowCreateProduct(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}
                width={720}>
                <Form form={formCreateProduct}
                    layout="vertical"
                    onFinish={onSubmitCreateProduct}>

                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                            <Form.Item
                                name="directional"
                                label="Điều hướng dịch vụ tới nhà cung cấp"
                                rules={[
                                    {
                                        required: true,
                                        message: "Chọn nhà cung cấp"
                                    }
                                ]}>
                                <Select defaultValue={''} size="large" showSearch optionFilterProp="label" onChange={(value, option) => onChangeSelectProvider(value, option)}>
                                    <option value="" label=''>-- All --</option>
                                    {lstProvider?.map((item: any, index) => (
                                        <Select.Option key={index} dataTemp={item.id} value={item.user.login} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="partnerId"
                                label="Đối tác"
                                rules={[
                                    {
                                        required: true,
                                        message: "Chọn đối tác"
                                    }
                                ]}>
                                <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                                    <option value="" label=''>-- All --</option>
                                    {lstPartner?.map((item: any, index) => (
                                        <Select.Option key={index} value={item.id} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="productRootId"
                                label="Dịch vụ"
                                rules={[
                                    {
                                        required: true,
                                        message: "Chọn dịch vụ gốc"
                                    }
                                ]}>
                                <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                                    {lstProductByProvider?.map((item: any, index) => (
                                        <Select.Option key={index} value={item.productRoot.id} label={item.productRoot.name}>{item.productRoot.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Chiết khấu topup tính bằng %"
                                name="discountTopupPercent"
                                rules={[
                                    { required: true, message: 'Nhập chiết khấu topup tính bằng %' }
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    size='large'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Chiết khấu topup tính bằng VNĐ"
                                name="discountTopupAmount"
                                rules={[
                                    { required: true, message: 'Nhập chiết khấu topup tính bằng VNĐ' }
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    size='large'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Chiết khấu mua thẻ tính bằng %"
                                name="discountPercent"
                                rules={[
                                    { required: true, message: 'Nhập chiết khấu mua thẻ tính bằng %' }
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    size='large'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Chiết khấu mua thẻ tính bằng VNĐ"
                                name="discountAmount"
                                rules={[
                                    { required: true, message: 'Nhập chiết khấu mua thẻ tính bằng VNĐ' }
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    size='large'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Phí dịch vụ tính bằng %"
                                name="feePercent"
                                rules={[
                                    { required: true, message: 'Nhập phí dịch vụ tính bằng %' }
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    size='large'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Phí dịch vụ tính bằng VNĐ"
                                name="feeAmount"
                                rules={[
                                    { required: true, message: 'Nhập Phí dịch vụ tính bằng VNĐ' }
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    size='large'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal
                title={'Câp nhật dịch vụ'}
                open={showEditProduct}
                onCancel={() => setShowEditProduct(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formEditProduct.submit()} type="primary">Cập nhật</Button>
                        <Button onClick={() => setShowEditProduct(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}
                width={720}>
                <Form form={formEditProduct}
                    layout="vertical"
                    onFinish={onSubmitEditProduct}>
                    <Form.Item name="partnerId" hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="productId" hidden={true}>
                        <Input />
                    </Form.Item>

                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                            <Form.Item
                                name="directional"
                                label="Điều hướng dịch vụ tới nhà cung cấp"
                                rules={[
                                    {
                                        required: true,
                                        message: "Chọn nhà cung cấp"
                                    }
                                ]}>
                                <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                                    <option value="" label=''>-- All --</option>
                                    {lstProvider?.map((item: any, index) => (
                                        <Select.Option key={index} value={item.user.login} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                            <Form.Item
                                name="directionalPercent"
                                label="Điều hướng theo %"
                                rules={[
                                    {
                                        validator: validateJSON,
                                        message: "Nhập vào json"
                                    }
                                ]}>
                                <Input size='large' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="partnerName"
                                label="Đối tác">
                                <Input size='large' disabled />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="productRootName"
                                label="Dịch vụ">
                                <Input size='large' disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Chiết khấu topup tính bằng %"
                                name="discountTopupPercent"
                                rules={[
                                    { required: true, message: 'Nhập chiết khấu topup tính bằng %' }
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    size='large'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Chiết khấu topup tính bằng VNĐ"
                                name="discountTopupAmount"
                                rules={[
                                    { required: true, message: 'Nhập chiết khấu topup tính bằng VNĐ' }
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    size='large'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Chiết khấu mua thẻ tính bằng %"
                                name="discountPercent"
                                rules={[
                                    { required: true, message: 'Nhập chiết khấu tính bằng %' }
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    size='large'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Chiết khấu mua thẻ tính bằng VNĐ"
                                name="discountAmount"
                                rules={[
                                    { required: true, message: 'Nhập chiết khấu tính bằng VNĐ' }
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    size='large'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Phí dịch vụ tính bằng %"
                                name="feePercent"
                                rules={[
                                    { required: true, message: 'Nhập phí dịch vụ tính bằng %' }
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    size='large'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Phí dịch vụ tính bằng VNĐ"
                                name="feeAmount"
                                rules={[
                                    { required: true, message: 'Nhập Phí dịch vụ tính bằng VNĐ' }
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    size='large'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Breadcrumb style={{ marginBottom: '10px' }}>
                <Breadcrumb.Item>Đối tác</Breadcrumb.Item>
                <Breadcrumb.Item>Chiết khấu</Breadcrumb.Item>
            </Breadcrumb>

            <Form form={formSearch}
                layout='vertical'>
                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                            name="partnerId"
                            label="Đối tác"
                            rules={[
                                {
                                    required: true,
                                    message: "Chọn đối tác"
                                }
                            ]}>
                            <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                                <option value="" label=''>-- All --</option>
                                {lstPartner?.map((item: any, index) => (
                                    <Select.Option key={index} value={item.id} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item name="type" label="Nhà mạng">
                            <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}>
                                <Select.Option key={'ALL'} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'VIETTEL'} value='VIETTEL'>
                                    VIETTEL
                                </Select.Option>
                                <Select.Option key={'VINAPHONE'} value='VINAPHONE'>
                                    VINAPHONE
                                </Select.Option>
                                <Select.Option key={'MOBIFONE'} value='MOBIFONE'>
                                    MOBIFONE
                                </Select.Option>
                                <Select.Option key={'VIETNAMOBILE'} value='VIETNAMOBILE'>
                                    VIETNAMOBILE
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item name="status"
                            label="Trạng thái">
                            <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                                placeholder="Trạng thái">
                                <Select.Option key={''} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'ACTIVE'} value='ACTIVE'>
                                    Hoạt động
                                </Select.Option>
                                <Select.Option key={'INACTIVE'} value='INACTIVE'>
                                    Tạm khóa
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item name="keySearch"
                            label="Từ khóa">
                            <Input size='large' placeholder='Tên/ SĐT/ Email' autoComplete='off' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end" style={{ marginBottom: '10px' }}>
                    <ButtonStyled1 onClick={() => setShowCreateProduct(true)} style={{ marginRight: '10px' }} type='primary' icon={<PlusOutlined />}>
                        Thêm mới
                    </ButtonStyled1>
                    <Button onClick={() => onPageChanged(1)} type="primary" icon={<SearchOutlined />}>
                        Search
                    </Button>
                </Row>
            </Form>

            <TableContainer>
                <TableContentStyled
                    columns={columns}
                    bordered
                    dataSource={lstData}
                    rowKey="id"
                    pagination={false} />

                <PagingStyled>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={ITEMS_PER_PAGE}
                        onChange={onPageChanged}
                        showSizeChanger={false}
                    />
                </PagingStyled>
            </TableContainer>
        </Fragment>
    )
}
