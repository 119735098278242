export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const AUTHY_VERIFY = 'AUTHY_VERIFY';
export const LOG_OUT = 'LOG_OUT';

export interface AuthenticatedUser {
    id: 0;
    fullName: string;
    imageUrl: string;
    userRole: string
    phone: string;
    email: string;
    environment: string;
}

interface LoginRequest {
    type: typeof LOGIN_REQUEST;
    payload: {
        userName: string;
        password: string;
    };
}

interface LoginSuccess {
    type: typeof LOGIN_SUCCESS;
    payload: {
        token: string;
        user: AuthenticatedUser;
    };
}

interface LoginFailure {
    type: typeof LOGIN_FAILURE;
    payload: {
        error: string;
    };
}

interface LoginAuthyVerify {
    type: typeof AUTHY_VERIFY;
    payload: {
        userName: string;
        password: string;
    };
}

interface Logout {
    type: typeof LOG_OUT;
    payload: {
        token: string;
        user: AuthenticatedUser | null;
        userName: string | null,
        password: string | null,
    };
}

export interface AccountState {
    user: AuthenticatedUser | null;
    loading: boolean;
    error: string | null;
    token: string | null;

    userName: string | null;
    password: string | null;
}


export type AccountActionTypes =
    | LoginRequest
    | LoginSuccess
    | LoginFailure
    | LoginAuthyVerify
    | Logout