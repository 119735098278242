import { AccountState } from '../store/account/types';
import { AppState } from '../store';
import { useSelector } from 'react-redux';
import { LayoutAdmin } from '../pages/LayoutAdmin';
import { PartnerListPage } from '../pages/admin/PartnerList';
import { Login } from '../pages/login/Login';
import { PartnerDashboardPage } from '../pages/partner/dashboard';

export const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
    const account: AccountState = useSelector((state: AppState) => state.account);
    if (account.token) {
        if (account.user?.userRole === "ROLE_ADMIN") {
            return <LayoutAdmin> {children == null ? children = <PartnerListPage /> : children} </LayoutAdmin>
        } else if (account.user?.userRole === "ROLE_PARTNER") {
            return <LayoutAdmin> {children == null ? children = <PartnerDashboardPage /> : children} </LayoutAdmin>
        } else {
            return <Login />
        }
    } else {
        return <Login />
    }
};