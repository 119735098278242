import { Button, Col, DatePicker, Form, Input, Pagination, Row, Select, Tag, message } from 'antd';
import {
  SearchOutlined,
  UndoOutlined,
  CheckOutlined
} from '@ant-design/icons';
import React, { Fragment, useEffect, useState } from 'react'
import { setLoading } from '../../store/loading/actions';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { adminService } from '../../services/admin.service';
import { ButtonPlatedGreenStyled, PagingStyled, TableContainer, TableContentStyled } from '../../components/CustomAntStyled';
import { ColumnsType } from 'antd/es/table';
import { currencyFormat, formatDateTime } from '../../helpers/FunctionUtils';
import { FaDownload } from 'react-icons/fa';
import Swal from 'sweetalert2';
import TextTruncate from '../../components/TextTruncate';
import ExportExcelHeaderModal from '../../components/ExportExcelHeaderModal';

export const AdminTransactionsPage = () => {
  const dispatch = useDispatch();
  const [lstPartner, setLstPartner] = useState<any[]>([]);
  const [lstProvider, setLstProvider] = useState<any[]>([]);
  const [formSearch] = Form.useForm();
  const ITEMS_PER_PAGE = 10;
  const [totalItems, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lstData, setLstData] = useState<any[]>([]);

  const getDataList = async (page: number, partnerId: any, providerId: any, productType: any, type: any, status: any, keySearch: any, tuNgay: any, denNgay: any) => {
    try {
      const response = await adminService.adminFilterListTrans(page - 1, ITEMS_PER_PAGE, partnerId, providerId, productType, type, status, keySearch, tuNgay, denNgay)
      if (response.status === 200) {
        setTotal(response.headers['x-total-count']);
        setLstData(response.data.data);
      } else {
        message.error({ content: response.message, duration: 3 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 5 });
    }
  };

  const onPageChanged = async (pageNumber: number) => {
    dispatch(setLoading(true));
    try {
      setCurrentPage(pageNumber);
      const values = await formSearch.validateFields();
      const dayCheck = dayjs(values.denNgay).diff(dayjs(values.tuNgay), 'day');
      if (dayCheck > 90) {
        message.error("Khoản thời gian tìm kiếm không được quá 90 ngày");
        return;
      }
      await getDataList(pageNumber, values.partnerId, values.providerId, values.productType, values.type, values.status, values.keySearch,
        values.tuNgay.format('DD/MM/YYYY 00:00:00'), values.denNgay.format('DD/MM/YYYY 00:00:00'));
    } catch (error) {
      message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    const initData = async () => {
      dispatch(setLoading(true));
      try {
        formSearch.setFieldsValue({
          tuNgay: dayjs(),
          denNgay: dayjs(),
        });

        const resPartner = await adminService.filterPartner(0, 9999, null, null, 0)
        if (resPartner.status === 200) {
          setLstPartner(resPartner.data.data);
        } else {
          message.error({ content: resPartner.message, duration: 3 });
        }

        const resProvider = await adminService.filterProvider(0, 999, null, null)
        if (resProvider.status === 200) {
          setLstProvider(resProvider.data.data);
        } else {
          message.error({ content: resProvider.message, duration: 3 });
        }

        onPageChanged(1);
      }
      catch (error: any) {
        message.error({ content: error.message as string, duration: 2 });
      } finally {
        dispatch(setLoading(false));
      }
    };
    initData();
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: <p>Mã đối tác/NCC</p>,
      dataIndex: 'transCodePartner',
      className: 'center-data',
      render: (transCodePartner: any, record: any) =>
        <Fragment>
          <TextTruncate text={transCodePartner} maxWords={8} /> <br />
          <TextTruncate text={record.transCodeProvider} maxWords={8} />
        </Fragment>,
    },
    {
      title: <p>Loại<br /> giao dịch</p>,
      dataIndex: 'type',
      className: 'center-data',
      render: (type: any) =>
        <span>
          {
            type === 'TOPUP' ? 'Topup'
              : type === 'TOPUP_BY_FILE' ? 'Nạp file'
                : 'Mua thẻ'
          }
        </span>,
    },
    {
      title: <p>Số lượng</p>,
      dataIndex: 'quantity',
      className: 'center-data',
      render: (quantity: any) =>
        <span>{quantity}</span>,
    },
    {
      title: <p>Mệnh giá</p>,
      dataIndex: 'cardAmount',
      className: 'center-data',
      render: (cardAmount: any) =>
        <span>{currencyFormat(cardAmount, 'VND')}</span>,
    },
    {
      title: <p>Nhà mạng</p>,
      dataIndex: 'productType',
      className: 'center-data',
      render: (productType: any) =>
        <span>{productType}</span>,
    },
    {
      title: <p>SĐT<br />thụ hưởng</p>,
      dataIndex: 'phoneBeneficiary',
      className: 'center-data',
      render: (phoneBeneficiary: any, record: any) =>
        <Fragment>
          <span>
            {phoneBeneficiary}
          </span> <br />
          {record.phoneBeneficiaryType === 'PRE_PAID' ? 'Trả trước' : 'Trả sau'}
        </Fragment>,
    },
    {
      title: <p>Nhà CC</p>,
      className: 'center-data',
      render: (record: any) =>
        <TextTruncate text={record.evn === 'TEST' ? 'TEST' : record.provider.user.firstName} maxWords={5} />,
    },
    {
      title: <p>Chiết khấu mua</p>,
      className: 'center-data',
      dataIndex: 'providerDiscountPercent',
      render: (providerDiscountPercent: any) =>
        <span>
          {`${providerDiscountPercent}%`}
        </span>,
    },
    {
      title: <p>Thanh toán nhà CC</p>,
      dataIndex: 'providerAmountBuy',
      className: 'center-data',
      render: (providerAmountBuy: any) => <span>{currencyFormat(providerAmountBuy, 'VND')}</span>,
    },
    {
      title: <p>Đối tác</p>,
      className: 'center-data',
      render: (record: any) =>
        <TextTruncate text={record.partner.user.firstName} maxWords={5} />,
    },
    {
      title: <p>Chiết khấu bán</p>,
      className: 'center-data',
      dataIndex: 'partnerDiscountPercent',
      render: (partnerDiscountPercent: any) => <span>{`${partnerDiscountPercent}%`}</span>,
    },
    {
      title: <p>Đối tác<br /> thanh toán</p>,
      dataIndex: 'partnerAmountPayment',
      className: 'center-data',
      render: (partnerAmountPayment: any) => <span>{currencyFormat(partnerAmountPayment, 'VND')}</span>,
    },
    {
      title: <p>Lợi nhuận</p>,
      dataIndex: 'transactionStatus',
      className: 'center-data',
      render: (transactionStatus: any, record: any) =>
        <span>
          {transactionStatus === 'THANH_CONG' || transactionStatus === 'THANH_CONG_BUT_MISS' ? currencyFormat(record.profit, 'VND') : currencyFormat(0, 'VND')}
        </span>,
    },
    {
      title: <p>Message<br />NCC</p>,
      dataIndex: 'messageProvider',
      className: 'center-data',
      render: (messageProvider: any) =>
        <TextTruncate text={messageProvider ? messageProvider : '--'} maxWords={5} />,
    },
    {
      title: <p>Trạng thái</p>,
      dataIndex: 'transactionStatus',
      className: 'center-data',
      render: (transactionStatus: string, record: any) =>
        <Fragment>
          {record.evn === 'TEST' ? 'TEST' : null}
          <Tag color={transactionStatus === 'THANH_CONG' || transactionStatus === 'THANH_CONG_BUT_MISS' ? 'rgb(52 166 55)'
            : transactionStatus === 'THAT_BAI' ? 'rgba(206, 206, 206, 1)'
              : 'rgb(255 181 42)'}>
            {
              transactionStatus === 'THANH_CONG' ? 'Thành công'
                : transactionStatus === 'THANH_CONG_BUT_MISS' ? 'Nạp thiếu'
                  : transactionStatus === 'THAT_BAI' ? 'Thất bại' : 'Đang xử lý'
            }
          </Tag><br />
          <TextTruncate text={record.message} maxWords={5} />
        </Fragment>
    },
    {
      title: <p>Khởi tạo</p>,
      dataIndex: 'createdDate',
      className: 'center-data',
      render: (createdDate: any) => <span>{formatDateTime(createdDate)}</span>,
    },
    {
      title: <p>Hoàn thành</p>,
      dataIndex: 'createdDate',
      className: 'center-data',
      render: (createdDate, record) => (
        <span>
          {dayjs(record.lastModifiedDate).diff(dayjs(createdDate), 'second') + ' s'}
          <br />
          <div style={{ display: 'flex' }}>
            <Button onClick={() => onCallbackData(record.id)} icon={<UndoOutlined />} />
            {record.transactionStatus === 'DANG_XU_LY' && (
              <Button onClick={() => onCheckDataTrans(record.id)} icon={<CheckOutlined />} />
            )}
          </div>
        </span>
      ),
    }

  ];

  const [modalVisible, setModalVisible] = useState(false);
  const handleExportExcel = async (selectedHeaders: string[]) => {
    try {
      setLoading(true);
      setModalVisible(false);

      const values = await formSearch.validateFields();
      const dayCheck = dayjs(values.denNgay).diff(dayjs(values.tuNgay), 'day');
      if (dayCheck > 90) {
        message.error("Khoảng thời gian tìm kiếm không được quá 90 ngày");
        return;
      }

      if (selectedHeaders.length == 0) {
        message.error("Bạn cần chọn thông tin cần xuất");
        return;
      }

      const result = await Swal.fire({
        title: 'Kiểm tra dữ liệu search, xác nhận xuất theo form search',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Xuất',
        cancelButtonText: 'Hủy',
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        dispatch(setLoading(true));
        const params = {
          partnerId: values.partnerId,
          providerId: values.providerId,
          productType: values.productType,
          type: values.type,
          status: values.status,
          keySearch: values.keySearch,
          tuNgay: values.tuNgay.format('DD/MM/YYYY 00:00:00'),
          denNgay: values.denNgay.format('DD/MM/YYYY 00:00:00'),
          headers: selectedHeaders.join("|"),
        };

        try {
          // Gọi API để lấy file Excel từ BE
          const response = await adminService.adminFilterListTransExport(params);

          if (response.status === 200) {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;
            link.download = `${dayjs().format('DDMMYYYYHHmmss')}.xlsx`;
            link.click();
            window.URL.revokeObjectURL(url);
          } else {
            message.error({ content: response.message || 'Xuất file thất bại', duration: 3 });
          }
        } catch (error) {
          console.error('Error exporting Excel:', error);
          message.error({ content: 'Lỗi trong quá trình xuất file', duration: 3 });
        }
      }
    } catch (error) {
      message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
    } finally {
      dispatch(setLoading(false));
    }
  };


  const onCallbackData = async (id: any) => {
    const result = await Swal.fire({
      title: 'Xác nhận call back data trả kết quả cho đối tác',
      html: '<span style="color: red">Lưu ý: chức năng chỉ được sử dụng khi đối tác yêu cầu<span>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Call Back',
      cancelButtonText: 'Hủy',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      dispatch(setLoading(true));
      try {
        const resPartner = await adminService.adminCallBackData(id);
        if (resPartner.code === 0) {
          message.success(resPartner.message);
        } else {
          message.error(resPartner.message);
        }
      }
      catch (error: any) {
        message.error({ content: error.message as string, duration: 2 });
      } finally {
        dispatch(setLoading(false));
      }
    }
  }

  const onCheckDataTrans = async (id: any) => {
    const result = await Swal.fire({
      title: 'Xác nhận kiểm tra và cập nhật giao dịch',
      html: '<span style="color: red">Lưu ý: chức năng sử dụng khi không nhận được call back từ đối tác<span>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Xác nhận',
      cancelButtonText: 'Hủy',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      dispatch(setLoading(true));
      try {
        const resPartner = await adminService.checkAndUpdateTrans(id);
        if (resPartner.code === 0) {
          message.success({ content: resPartner.message as string, duration: 5 });
          await adminService.adminCallBackData(id);
          await onPageChanged(currentPage);
        } else {
          message.error(resPartner.message);
        }
      }
      catch (error: any) {
        message.error({ content: error.message as string, duration: 2 });
      } finally {
        dispatch(setLoading(false));
      }
    }
  }

  return (
    <Fragment>
      <Form
        form={formSearch}
        layout='vertical'>
        <Row gutter={[20, 20]}>
          <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="tuNgay" // Tên của trường trong form
              label='Từ ngày'
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn ngày đăng',
                }
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                showTime={false}
                inputReadOnly={true}
                size='large'
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="denNgay" // Tên của trường trong form
              label='Đến ngày'
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn ngày đăng',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const tuNgayValue = getFieldValue('tuNgay');
                    if (value && value.isBefore(tuNgayValue, 'day')) {
                      return Promise.reject('Phải lớn hơn thời gian bắt đầu');
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}>
              <DatePicker
                format="DD/MM/YYYY"
                showTime={false}
                inputReadOnly={true}
                size='large'
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="providerId"
              label="Nhà cung cấp">
              <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                <option value="" label=''>-- All --</option>
                {lstProvider?.map((item: any, index) => (
                  <Select.Option key={index} value={item.id} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="partnerId"
              label="Đối tác">
              <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                <option value="" label=''>-- All --</option>
                {lstPartner?.map((item: any, index) => (
                  <Select.Option key={index} value={item.id} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="type"
              label="Loại giao dịch">
              <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                placeholder="Loại giao dịch">
                <Select.Option key={'ALL'} value='ALL'>
                  -- All --
                </Select.Option>
                <Select.Option key={'TOPUP'} value='TOPUP'>
                  Topup
                </Select.Option>
                <Select.Option key={'BUY_CARD'} value='BUY_CARD'>
                  Mua thẻ cào
                </Select.Option>
                <Select.Option key={'TOPUP_BY_FILE'} value='TOPUP_BY_FILE'>
                  Nạp file
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="keySearch" label='Từ khóa tìm kiếm'>
              <Input size='large' placeholder='Mã GD/ SĐT' />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="productType" label="Nhà mạng">
              <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                placeholder="Nhà mạng">
                <Select.Option key={'ALL'} value='ALL'>
                  -- All --
                </Select.Option>
                <Select.Option key={'VIETTEL'} value='VIETTEL'>
                  VIETTEL
                </Select.Option>
                <Select.Option key={'VINAPHONE'} value='VINAPHONE'>
                  VINAPHONE
                </Select.Option>
                <Select.Option key={'MOBIFONE'} value='MOBIFONE'>
                  MOBIFONE
                </Select.Option>
                <Select.Option key={'VIETNAMOBILE'} value='VIETNAMOBILE'>
                  VIETNAMOBILE
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="status" label="Trạng thái">
              <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                placeholder="Trạng thái">
                <Select.Option key={'ALL'} value='ALL'>
                  -- All --
                </Select.Option>
                <Select.Option key={'THANH_CONG'} value='THANH_CONG'>
                  Thành công
                </Select.Option>
                <Select.Option key={'THANH_CONG_BUT_MISS'} value='THANH_CONG_BUT_MISS'>
                  Nạp thiếu
                </Select.Option>
                <Select.Option key={'DANG_XU_LY'} value='DANG_XU_LY'>
                  Đang xử lý
                </Select.Option>
                <Select.Option key={'THAT_BAI'} value='THAT_BAI'>
                  Thất bại
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row justify="end" style={{ marginBottom: '10px' }}>
        <ButtonPlatedGreenStyled style={{ marginRight: '10px' }} onClick={() => setModalVisible(true)} type="primary" icon={<FaDownload />}>
          Xuất excel
        </ButtonPlatedGreenStyled>
        <Button onClick={() => onPageChanged(1)} type="primary" icon={<SearchOutlined />}>
          Search
        </Button>
      </Row>

      <ExportExcelHeaderModal visible={modalVisible} onOk={handleExportExcel} onCancel={() => setModalVisible(false)} />

      <TableContainer>
        <TableContentStyled
          columns={columns}
          dataSource={lstData}
          rowKey="id"
          bordered
          pagination={false} />

        <PagingStyled>
          <Pagination
            current={currentPage}
            total={totalItems}
            pageSize={ITEMS_PER_PAGE}
            onChange={onPageChanged}
            showSizeChanger={false}
          />
        </PagingStyled>
      </TableContainer>
    </Fragment>
  )
}
