import { Breadcrumb, Button, Col, DatePicker, Form, Input, Pagination, Row, Select, Spin, message } from 'antd';
import {
    SearchOutlined
} from '@ant-design/icons';
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { adminService } from '../../services/admin.service';
import { setLoading } from '../../store/loading/actions';
import { ColumnsType } from 'antd/es/table';
import { currencyFormat, formatDateTime } from '../../helpers/FunctionUtils';
import { PagingStyled, TableContainer, TableContentStyled } from '../../components/CustomAntStyled';
import dayjs from 'dayjs';

export const AdminFluctuationsPage = () => {
    const dispatch = useDispatch();

    const ITEMS_PER_PAGE = 10;
    const [formSearch] = Form.useForm();
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lstData, setLstData] = useState<any[]>([]);
    const [lstPartner, setLstPartner] = useState<any[]>([]);

    const columns: ColumnsType<any> = [
        {
            title: <p>Tên<br /> đối tác</p>,
            dataIndex: 'partner',
            className: 'center-data',
            render: (partner: any) => <span>{partner?.user?.firstName}</span>,
        },
        {
            title: <p>Loại thay đổi</p>,
            dataIndex: 'type',
            className: 'center-data',
            render: (type: any) =>
                <span>{type === 'LENH_NAP_RUT' ? 'Lệnh Nạp/Rút tiền' : 'Dùng dịch vụ'}</span>
        },
        {
            title: <p>Số dư trước</p>,
            dataIndex: 'balanceOld',
            render: (balanceOld: any) => <span>{balanceOld ? currencyFormat(balanceOld, 'VND') : <Spin />}</span>,
        },
        {
            title: <p>Số tiền</p>,
            dataIndex: 'amount',
            render: (amount: any) => <span>{currencyFormat(amount, 'VND')}</span>,
        },
        {
            title: <p>Số dư sau</p>,
            dataIndex: 'balanceNew',
            render: (balanceNew: any) => <span>{
                balanceNew ? currencyFormat(balanceNew, 'VND') : <Spin />
            }</span>,
        },
        {
            title: <p>Mô tả</p>,
            dataIndex: 'descriptions',
            render: (descriptions: any) => <span>{
                descriptions ? descriptions : '--'
            }</span>,
        },
        {
            title: <p>Thời gian</p>,
            dataIndex: 'createdDate',
            className: 'center-data',
            render: (createdDate: any) => <span>{formatDateTime(createdDate)}</span>,
        }
    ];

    const getDataList = async (partnerId: any, keySearch: any, type: any, page: number, tuNgay: any, denNgay: any) => {
        try {
            const response = await adminService.filterBalanceChange(page - 1, ITEMS_PER_PAGE, partnerId, keySearch, type, tuNgay, denNgay)
            if (response.status === 200) {
                setTotal(response.headers['x-total-count']);
                setLstData(response.data.data);
            } else {
                message.error({ content: response.message, duration: 3 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 3 });
        }
    };

    useEffect(() => {
        const initData = async () => {
            dispatch(setLoading(true));
            try {
                formSearch.setFieldsValue({
                    tuNgay: dayjs(),
                    denNgay: dayjs(),
                });

                await getDataList(null, null, null, currentPage, dayjs().format('DD/MM/YYYY 00:00:00'), dayjs().format('DD/MM/YYYY 00:00:00'));

                const response = await adminService.filterPartner(0, 9999, null, null, 0)
                if (response.status === 200) {
                    setLstPartner(response.data.data);
                } else {
                    message.error({ content: response.message, duration: 3 });
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }
        };
        initData();
    }, []);

    const onPageChanged = async (pageNumber: number) => {
        dispatch(setLoading(true));
        try {
            setCurrentPage(pageNumber);
            const values = await formSearch.validateFields();
            await getDataList(values.partnerId, values.keySearch, values.type, pageNumber, values.tuNgay.format('DD/MM/YYYY 00:00:00'), values.denNgay.format('DD/MM/YYYY 00:00:00'));
        } catch (error) {
            message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };
    return (
        <Fragment>

            <Breadcrumb style={{ marginBottom: '10px' }}>
                <Breadcrumb.Item>Đối tác</Breadcrumb.Item>
                <Breadcrumb.Item>Biến động số dư</Breadcrumb.Item>
            </Breadcrumb>

            <Form form={formSearch}
                layout='vertical'>
                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item
                            name="tuNgay" // Tên của trường trong form
                            label='Từ ngày'
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn ngày đăng',
                                }
                            ]}>
                            <DatePicker
                                format="DD/MM/YYYY"
                                showTime={false}
                                inputReadOnly={true}
                                size='large'
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item
                            name="denNgay" // Tên của trường trong form
                            label='Đến ngày'
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn ngày đăng',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const tuNgayValue = getFieldValue('tuNgay');
                                        if (value && value.isBefore(tuNgayValue, 'day')) {
                                            return Promise.reject('Phải lớn hơn thời gian bắt đầu');
                                        } else {
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}>
                            <DatePicker
                                format="DD/MM/YYYY"
                                showTime={false}
                                inputReadOnly={true}
                                size='large'
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                            name="partnerId"
                            label="Đối tác">
                            <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                                <option value="" label=''>-- All --</option>
                                {lstPartner?.map((item: any, index) => (
                                    <Select.Option key={index} value={item.id} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item name="type"
                            label="Loại biến động">
                            <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                                placeholder="Trạng thái">
                                <Select.Option key={''} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'DICH_VU'} value='DICH_VU'>
                                    Dùng dịch vụ
                                </Select.Option>
                                <Select.Option key={'LENH_NAP_RUT'} value='LENH_NAP_RUT'>
                                    Lệnh Nạp/Rút tiền
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item name="keySearch"
                            label="Từ khóa">
                            <Input size='large' placeholder='Mô tả' autoComplete='off' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end" style={{ marginBottom: '10px' }}>
                    <Button onClick={() => onPageChanged(1)} type="primary" icon={<SearchOutlined />}>
                        Search
                    </Button>
                </Row>
            </Form>

            <TableContainer>
                <TableContentStyled
                    columns={columns}
                    bordered
                    dataSource={lstData}
                    rowKey="id"
                    pagination={false} />

                <PagingStyled>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={ITEMS_PER_PAGE}
                        onChange={onPageChanged}
                        showSizeChanger={false}
                    />
                </PagingStyled>
            </TableContainer>

        </Fragment>
    )
}
