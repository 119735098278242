import React, { Fragment, useEffect, useState } from 'react'
import { adminService } from '../../services/admin.service';
import { Breadcrumb, Button, Col, Flex, Form, Input, InputNumber, Modal, Pagination, Row, Select, Tag, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { currencyFormat } from '../../helpers/FunctionUtils';
import { ButtonStyled1, PagingStyled, TableContainer, TableContentStyled } from '../../components/CustomAntStyled';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import DropdownActions from '../../components/DropdownActions';
import { MdLock, MdModeEdit } from 'react-icons/md';
import { FaUnlock } from 'react-icons/fa';
import Swal from 'sweetalert2';

export const SystemProductListPage = () => {
    const dispatch = useDispatch();
    const COUPON_ITEMS_PER_PAGE = 10;
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lstData, setLstData] = useState<any[]>([]);
    const [formSearch] = Form.useForm();

    const getDataList = async (type: any, keySearch: any, page: number, size: number) => {
        try {
            const response = await adminService.getListProductRoot(page - 1, size, type, keySearch)
            if (response.status === 200) {
                setTotal(response.headers['x-total-count']);
                setLstData(response.data.data);
            } else {
                message.error({ content: response.message, duration: 3 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 3 });
        }
    };

    const onPageChanged = async (pageNumber: number) => {
        dispatch(setLoading(true));
        try {
            setCurrentPage(pageNumber);
            const values = await formSearch.validateFields();
            await getDataList(values.type, values.keySearch, pageNumber, COUPON_ITEMS_PER_PAGE);
        } catch (error) {
            message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleEdit = (id: any) => async () => {
        dispatch(setLoading(true));
        try {
            const response = await adminService.getProductRootById(id);
            if (response.status === 200) {
                formEditProduct.setFieldsValue({
                    productId: id,
                    type: response.data.type,
                    name: response.data.name,
                    productCode: response.data.productCode,
                    cardAmount: response.data.cardAmount
                });
                setShowEditProduct(true);
            } else {
                message.error({ content: response.statusText, duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 5 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleActiveProduct = (id: any) => async () => {
        const result = await Swal.fire({
            title: 'Xác nhận mở khóa sản phẩm',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Mở khóa',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await adminService.activeProductRoot(id);
                if (response.code === 0) {
                    onPageChanged(currentPage);
                    message.success({ content: response.message, duration: 3 });
                } else {
                    message.error({ content: response.message, duration: 5 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 5 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handleLockProduct = (id: any) => async () => {
        const result = await Swal.fire({
            title: 'Xác nhận khóa sản phẩm',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'red',
            confirmButtonText: 'Khóa',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await adminService.inActiveProductRoot(id);
                if (response.code === 0) {
                    onPageChanged(currentPage);
                    message.success({ content: response.message, duration: 3 });
                } else {
                    message.error({ content: response.message, duration: 5 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 5 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const renderActionModal = (item: any) => {
        const props = [
            {
                icon: <MdModeEdit size={20} color='#006aff' />,
                label: 'Edit',
                onClick: () => handleEdit(item.id),
            },
            (item.status === 'INACTIVE')
                ? {

                    icon: <FaUnlock size={20} color='green' />,
                    label: 'Mở khóa',
                    onClick: () => handleActiveProduct(item.id),
                }
                :
                {
                    icon: <MdLock size={20} color='#34343' />,
                    label: 'Tạm đóng',
                    onClick: () => handleLockProduct(item.id),
                },
        ];
        return <DropdownActions items={props} />;
    };

    const columns: ColumnsType<any> = [

        {
            title: <p>Nhà Mạng</p>,
            dataIndex: 'type',
            className: 'center-data',
            render: (type: any) => <span>{type}</span>,
        },
        {
            title: <p>Mã sản phẩm</p>,
            dataIndex: 'productCode',
            className: 'center-data',
            render: (productCode: any) => <span>{productCode}</span>,
        },
        {
            title: <p>Tên dịch vụ</p>,
            dataIndex: 'name',
            className: 'center-data',
            render: (name: any) => <span>{name}</span>,
        },
        {
            title: <p>Mệnh giá</p>,
            dataIndex: 'cardAmount',
            className: 'center-data',
            render: (cardAmount: any) => <span>{currencyFormat(cardAmount, 'VND')}</span>,
        },
        {
            title: <p>Trạng thái</p>,
            dataIndex: 'status',
            className: 'center-data',
            render: (status: string) =>
                <Tag color={status === 'ACTIVE' ? 'rgb(52 166 55)' : 'rgba(206, 206, 206, 1)'}>
                    {status === 'ACTIVE' ? 'Hoạt động' : 'Tạm khóa'}
                </Tag>
        },
        {
            title: <p>Actions</p>,
            dataIndex: 'id',
            className: 'action-dots',
            render: (id: any, record: any) =>
                renderActionModal(record)
            ,
        },
    ];

    useEffect(() => {
        const initData = async () => {
            dispatch(setLoading(true));
            try {
                await getDataList(null, null, currentPage, COUPON_ITEMS_PER_PAGE);
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }
        };
        initData();
    }, []);

    const [formCreateProduct] = Form.useForm();
    const [showCreateProduct, setShowCreateProduct] = useState(false);
    const onSubmitCreateProduct = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await adminService.createProductRoot(values.type, values.name, values.productCode, values.cardAmount)
            if (response.status === 201) {
                onPageChanged(currentPage);
                setShowCreateProduct(false);
                formCreateProduct.resetFields();
                message.success({ content: "Thêm mới thành công", duration: 3 });
            } else {
                message.error({ content: response.message, duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 5 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [formEditProduct] = Form.useForm();
    const [showEditProduct, setShowEditProduct] = useState(false);
    const onSubmitEditProduct = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await adminService.updateProductRoot(values.productId, values.type, values.name,
                values.productCode, values.cardAmount)
            if (response.status === 200) {
                onPageChanged(currentPage);
                setShowEditProduct(false);
                formEditProduct.resetFields();
                message.success({ content: "Cập nhật thành công", duration: 3 });
            } else {
                message.error({ content: response.message, duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <Fragment>
            <Modal
                title={'Thêm mới dịch vụ'}
                open={showCreateProduct}
                onCancel={() => setShowCreateProduct(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formCreateProduct.submit()} type="primary">Tạo mới</Button>
                        <Button onClick={() => setShowCreateProduct(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}
                width={720}>
                <Form form={formCreateProduct}
                    layout="vertical"
                    onFinish={onSubmitCreateProduct}>

                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Nhà mạng" name="type"
                                rules={[
                                    { required: true, message: 'Chọn loại dịch vụ' },

                                ]}>
                                <Select size='large' style={{ width: '100%' }}>
                                    <Select.Option key={'VIETTEL'} value='VIETTEL'>
                                        VIETTEL
                                    </Select.Option>
                                    <Select.Option key={'VINAPHONE'} value='VINAPHONE'>
                                        VINAPHONE
                                    </Select.Option>
                                    <Select.Option key={'MOBIFONE'} value='MOBIFONE'>
                                        MOBIFONE
                                    </Select.Option>
                                    <Select.Option key={'VIETNAMOBILE'} value='VIETNAMOBILE'>
                                        VIETNAMOBILE
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="productCode"
                                label="Mã sản phẩm"
                                rules={[
                                    { required: true, message: 'Nhập mã sản phẩm!' },
                                    {
                                        pattern: /^[a-zA-Z0-9._]+$/,
                                        message: 'Mã sản phẩm không được có khoảng trắng hoặc ký tự đặc biệt!',
                                    },
                                ]}>
                                <Input size='large' placeholder='ACBD' autoComplete='off' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Mệnh giá"
                                name="cardAmount"
                                rules={[
                                    { required: true, message: 'Nhập mệnh giá' }
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    size='large'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="name"
                                label="Tên sản phẩm"
                                rules={[
                                    { required: true, message: 'Nhập tên sản phẩm!' }
                                ]}>
                                <Input size='large' placeholder='Tên sản phẩm' autoComplete='off' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal
                title={'Cập nhật sản phẩm'}
                open={showEditProduct}
                onCancel={() => setShowEditProduct(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formEditProduct.submit()} type="primary">Cập nhật</Button>
                        <Button onClick={() => setShowEditProduct(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}
                width={720}>
                <Form form={formEditProduct}
                    layout="vertical"
                    onFinish={onSubmitEditProduct}>
                    <Form.Item name="productId" hidden={true}>
                        <Input />
                    </Form.Item>

                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Nhà mạng" name="type"
                                rules={[
                                    { required: true, message: 'Chọn loại dịch vụ' },

                                ]}>
                                <Select size='large' style={{ width: '100%' }}>
                                    <Select.Option key={'VIETTEL'} value='VIETTEL'>
                                        VIETTEL
                                    </Select.Option>
                                    <Select.Option key={'VINAPHONE'} value='VINAPHONE'>
                                        VINAPHONE
                                    </Select.Option>
                                    <Select.Option key={'MOBIFONE'} value='MOBIFONE'>
                                        MOBIFONE
                                    </Select.Option>
                                    <Select.Option key={'VIETNAMOBILE'} value='VIETNAMOBILE'>
                                        VIETNAMOBILE
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="productCode"
                                label="Mã sản phẩm"
                                rules={[
                                    { required: true, message: 'Nhập mã sản phẩm!' },
                                    {
                                        pattern: /^[a-zA-Z0-9._]+$/,
                                        message: 'Mã sản phẩm không được có khoảng trắng hoặc ký tự đặc biệt!',
                                    },
                                ]}>
                                <Input size='large' placeholder='ACBD' autoComplete='off' />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item label="Mệnh giá"
                                name="cardAmount"
                                rules={[
                                    { required: true, message: 'Nhập mệnh giá' }
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    size='large'
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="name"
                                label="Tên sản phẩm"
                                rules={[
                                    { required: true, message: 'Nhập tên sản phẩm!' }
                                ]}>
                                <Input size='large' placeholder='Tên sản phẩm' autoComplete='off' />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Modal>

            <Breadcrumb style={{ marginBottom: '10px' }}>
                <Breadcrumb.Item>Hệ thống</Breadcrumb.Item>
                <Breadcrumb.Item>Danh sách dịch vụ</Breadcrumb.Item>
            </Breadcrumb>

            <TableContainer>
                <Form form={formSearch}
                    layout='vertical'>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item name="type"
                                label="Nhà mạng">
                                <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                                    placeholder="Nhà Mạng">
                                    <Select.Option key={''} value='ALL'>
                                        -- All --
                                    </Select.Option>
                                    <Select.Option key={'VIETTEL'} value='VIETTEL'>
                                        VIETTEL
                                    </Select.Option>
                                    <Select.Option key={'VINAPHONE'} value='VINAPHONE'>
                                        VINAPHONE
                                    </Select.Option>
                                    <Select.Option key={'MOBIFONE'} value='MOBIFONE'>
                                        MOBIFONE
                                    </Select.Option>
                                    <Select.Option key={'VIETNAMOBILE'} value='VIETNAMOBILE'>
                                        VIETNAMOBILE
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 8 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item name="keySearch"
                                label="Từ khoán tìm kiếm">
                                <Input size='large' placeholder='Tên dịch vụ' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="end" style={{ marginBottom: '10px' }}>
                        <ButtonStyled1 onClick={() => setShowCreateProduct(true)} style={{ marginRight: '10px' }} type='primary' icon={<PlusOutlined />}>
                            Thêm dịch vụ
                        </ButtonStyled1>
                        <Button onClick={() => onPageChanged(1)} type="primary" icon={<SearchOutlined />}>
                            Search
                        </Button>
                    </Row>
                </Form>

                <TableContentStyled
                    columns={columns}
                    dataSource={lstData}
                    rowKey="id"
                    bordered
                    pagination={false} />

                <PagingStyled>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={COUPON_ITEMS_PER_PAGE}
                        onChange={onPageChanged}
                        showSizeChanger={false}
                    />
                </PagingStyled>

            </TableContainer>
        </Fragment>
    )
}
