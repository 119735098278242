import { api, apiReal } from './';

export const setAuthToken = (token: string) => {
    if (token) {
        api.defaults.headers['Authorization'] = `Bearer ${token}`;
        apiReal.defaults.headers['Authorization'] = `Bearer ${token}`;
    } else {
        delete api.defaults.headers['Authorization'];
        delete apiReal.defaults.headers['Authorization'];
    }
};