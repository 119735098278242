import { api, apiReal } from '../helpers';

const register = async (nameLogin: string, fullName: string, email: string, phone: string, password: string) => {
    const body = { nameLogin, fullName, email, phone, password };
    return await api.post('/partners/register', body).then((response) => {
        return response.data;
    });
};

const changeEnvironment = async (password: any) => {
    const params = { password };
    return await api.get('/partners/change-environment', { params }).then((response) => {
        return response;
    });
};

const changeInfo = async (email: any, phone: any, balanceReminder: any) => {
    const body = { email, phone, balanceReminder };
    return await api.post('/partners/change-info', body).then((response) => {
        return response;
    });
};

const changeInfoConnect = async (password: any, urlCallBack: any, partnerRsaPublicKey: any) => {
    const body = { password, urlCallBack, partnerRsaPublicKey };
    return await api.post('/partners/change-info-connect', body).then((response) => {
        return response;
    });
};

const changePwd = async (currentPassword: any, newPassword: any) => {
    const body = { currentPassword, newPassword };
    return await api.post('/account/change-password', body).then((response) => {
        return response;
    });
};

const filterTransForPartner = async (page: number | null, size: number | null, partnerId: any, productType: any, type: any, status: any, keySearch: any,
    tuNgay: any, denNgay: any) => {
    const params = { page, size, partnerId, productType, type, status, keySearch, tuNgay, denNgay };
    return await api.get('/transactions/partner/history', { params }).then((response: any) => {
        return response;
    });
};

const getTotalTransByDate = async (tuNgay: any, denNgay: any, partnerId: any, productType: any, type: any) => {
    const params = { tuNgay, denNgay, partnerId, productType, type };
    return await api.get('/transactions/total', { params }).then((response: any) => {
        return response.data;
    });
};

const filterTransPartnerExport = async (partnerId: any, productType: any, type: any, status: any, keySearch: any,
    tuNgay: any, denNgay: any) => {
    const params = { partnerId, productType, type, status, keySearch, tuNgay, denNgay };
    return await apiReal.get('/transactions/partner/history/export', {
        params,
        responseType: 'blob', // Quan trọng để lấy dữ liệu file
    }).then((response: any) => {
        return response;
    });
};

const filterTopupByFile = async (page: number | null, size: number | null, partnerId: any, productType: any, status: any, keySearch: any,
    tuNgay: any, denNgay: any) => {
    const params = { page, size, partnerId, productType, status, keySearch, tuNgay, denNgay };
    return await api.get('/topup-by-files/partner-filter', { params }).then((response: any) => {
        return response;
    });
};

const topupItemByFile = async (productCode: any, phone: any, type: any, pwd: any, fileCode: any) => {
    const body = { productCode, phone, type, pwd, fileCode };
    return await api.post('/topup-by-files/push-topup', body).then((response) => {
        return response.data;
    });
};

export const partnerService = {
    register,
    changeEnvironment,
    changeInfo,
    changePwd,
    getTotalTransByDate,
    changeInfoConnect,
    filterTransForPartner,
    filterTransPartnerExport,
    filterTopupByFile,
    topupItemByFile
};