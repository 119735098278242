import { api, apiReal } from '../helpers';

const filterPartner = async (
    page: number | null, size: number | null,
    keySearch: any, 
    status: any,
    getBalance: number
) => {
    const params = { page, size, keySearch, status, getBalance };
    return await api.get('/admin-services/partner/list', { params }).then((response: any) => {
        return response;
    });
};

const filterProvider = async (
    page: number | null, size: number | null,
    keySearch: any, status: any) => {
    const params = { page, size, keySearch, status };
    return await api.get('/admin-services/provider/list', { params }).then((response: any) => {
        return response;
    });
};

const initActivePartner = async (partnerId: any, partnerCode: any) => {
    const payLoad = { partnerId, partnerCode };
    return await api.post('/admin-services/partner/init-active', payLoad).then((response: any) => {
        return response.data;
    });
};

const inActivePartner = async (id: any) => {
    return await api.post(`/admin-services/partner/${id}/inactive`).then((response: any) => {
        return response.data;
    });
};

const activePartner = async (id: any) => {
    return await api.post(`/admin-services/partner/${id}/active`).then((response: any) => {
        return response.data;
    });
};

const inActiveProvider = async (id: any) => {
    return await api.post(`/admin-services/provider/${id}/inactive`).then((response: any) => {
        return response.data;
    });
};

const activeProvider = async (id: any) => {
    return await api.post(`/admin-services/provider/${id}/active`).then((response: any) => {
        return response.data;
    });
};

const getPartnerById = async (id: any) => {
    return await api.get(`/partners/cms/${id}`).then((response) => {
        return response;
    });
};

const deletePartnerInit = async (login: any) => {
    return await api.delete(`/admin-services/${login}/delete-account-init`).then((response) => {
        return response.data;
    });
};

const getProviderById = async (id: any) => {
    return await api.get(`/providers/${id}`).then((response) => {
        return response;
    });
};

const editPartner = async (id: any, firstName: any, maxBuyCard: any, balanceReminder: any, email: any, phone: any) => {
    const payLoad = { firstName, maxBuyCard, balanceReminder, email, phone };
    return await api.post(`/admin-services/partner/${id}/edit`, payLoad).then((response: any) => {
        return response.data;
    });
};

const editProvider = async (id: any, firstName: any, balanceReminder: any, maxTimeOut: number) => {
    const payLoad = { firstName, balanceReminder, maxTimeOut };
    return await api.post(`/admin-services/provider/${id}/edit`, payLoad).then((response: any) => {
        return response.data;
    });
};

const updateIpPartner = async (id: any, ipWhiteList: any) => {
    const payLoad = { ipWhiteList };
    return await api.post(`/admin-services/partner/${id}/update-ip`, payLoad).then((response: any) => {
        return response.data;
    });
};

const filterBalanceOrder = async (page: number | null, size: number | null, partnerId: any, keySearch: any, type: any, status: any) => {
    const params = { page, size, partnerId, type, keySearch, status };
    return await api.get('/admin-services/partner/balance-order/list', { params }).then((response: any) => {
        return response;
    });
};

const filterBalanceChange = async (page: number | null, size: number | null, partnerId: any, keySearch: any, type: any, tuNgay: any, denNgay: any,) => {
    const params = { page, size, partnerId, type, keySearch, tuNgay, denNgay };
    return await api.get('/admin-services/partner/balance-change', { params }).then((response: any) => {
        return response;
    });
};

const createBalanceOrder = async (partnerId: any, type: any, amount: any, descriptions: any) => {
    const payLoad = { partnerId, type, amount, descriptions };
    return await api.post('/admin-services/partner/balance-order/create', payLoad).then((response: any) => {
        return response.data;
    });
};

const confirmBalanceOrder = async (id: any, partnerId: any) => {
    return await api.post(`/admin-services/balance-order/${id}/${partnerId}/confirm`).then((response: any) => {
        return response.data;
    });
};

const cancelBalanceOrder = async (id: any, partnerId: any) => {
    return await api.post(`/admin-services/balance-order/${id}/${partnerId}/cancel`).then((response: any) => {
        return response.data;
    });
};

const approvedBalanceOrder = async (id: any, partnerId: any) => {
    return await api.post(`/admin-services/balance-order/${id}/${partnerId}/approved`).then((response: any) => {
        return response.data;
    });
};

const getListProductRoot = async (page: number | null, size: number | null, type: any, keySearch: any) => {
    const params = { page, size, type, keySearch };
    return await api.get('/admin-services/product/list', { params }).then((response: any) => {
        return response;
    });
};

const createProductRoot = async (type: any, name: any, productCode: any, cardAmount: any) => {
    const payLoad = { type, name, productCode, cardAmount };
    return await api.post('/product-roots', payLoad).then((response: any) => {
        return response;
    });
};

const getProductRootById = async (id: any) => {
    return await api.get(`/product-roots/${id}`).then((response) => {
        return response;
    });
};

const updateProductRoot = async (id: any, type: any, name: any, productCode: any, cardAmount: any) => {
    const payLoad = { id, type, name, productCode, cardAmount };
    return await api.put(`/product-roots/${id}`, payLoad).then((response: any) => {
        return response;
    });
};

const activeProductRoot = async (id: any) => {
    return await api.post(`/admin-services/product-root/${id}/active`).then((response: any) => {
        return response.data;
    });
};

const inActiveProductRoot = async (id: any) => {
    return await api.post(`/admin-services/product-root/${id}/inactive`).then((response: any) => {
        return response.data;
    });
};

const filterProductByPartner = async (page: number | null, size: number | null, partnerId: any, keySearch: any, type: any, status: any) => {
    const params = { page, size, partnerId, type, keySearch, status };
    return await api.get('/admin-services/partner/products', { params }).then((response: any) => {
        return response;
    });
};

const filterProductByProvider = async (page: number | null, size: number | null, providerId: any, keySearch: any, type: any, status: any) => {
    const params = { page, size, providerId, type, keySearch, status };
    return await api.get('/admin-services/provider/products', { params }).then((response: any) => {
        return response;
    });
};

const createProductPartner = async (directional: any, productRootId: any, partnerId: any, discountPercent: any,
    discountAmount: any, feePercent: any, feeAmount: any, discountTopupPercent: any, discountTopupAmount: any) => {
    const payLoad = { directional, partnerId, discountPercent, discountAmount, feePercent, feeAmount, productRootId, discountTopupPercent, discountTopupAmount };
    return await api.post(`/admin-services/partner/products/create`, payLoad).then((response: any) => {
        return response.data;
    });
};

const createProductProvider = async (productRootId: any, providerId: any, discountPercent: any, discountTopupPercent: any) => {
    const payLoad = { productRootId, providerId, discountPercent, discountTopupPercent };
    return await api.post(`/admin-services/partner/provider/create`, payLoad).then((response: any) => {
        return response.data;
    });
};

const upodateStatusProductPartner = async (id: any, status: any) => {
    const params = { status };
    return await api.get(`/admin-services/product/${id}/update-status`, { params }).then((response: any) => {
        return response.data;
    });
};

const updateProduct = async (id: any, directional: any, discountPercent: any, discountAmount: any, feePercent: any, feeAmount: any, discountTopupPercent: any, discountTopupAmount: any, directionalPercent: string) => {
    const payLoad = { id, directional, discountPercent, discountAmount, feePercent, feeAmount, discountTopupPercent, discountTopupAmount, directionalPercent };
    return await api.post(`/admin-services/partner/products/update`, payLoad).then((response: any) => {
        return response;
    });
};

const updateProductProvider = async (id: any, discountPercent: any, discountTopupPercent: any) => {
    const payLoad = { id, discountPercent, discountTopupPercent };
    return await api.post(`/admin-services/provider/products/update`, payLoad).then((response: any) => {
        return response;
    });
};

const createWarehouse = async (name: any, description: any, status: any) => {
    const payLoad = { name, description, status };
    return await api.post('/warehouses', payLoad).then((response: any) => {
        return response;
    });
};

const adminFilterListTrans = async (page: number | null, size: number | null, partnerId: any, providerId: any, productType: any, type: any, status: any, keySearch: any,
    tuNgay: any, denNgay: any) => {
    const params = { page, size, partnerId, providerId, productType, type, status, keySearch, tuNgay, denNgay };
    return await api.get('/transactions/history', { params }).then((response: any) => {
        return response;
    });
};

const filterListTrans = async (page: number | null, size: number | null, partnerId: any, productType: any, type: any, status: any, keySearch: any,
    tuNgay: any, denNgay: any) => {
    const params = { page, size, partnerId, productType, type, status, keySearch, tuNgay, denNgay };
    return await api.get('/transactions/history', { params }).then((response: any) => {
        return response;
    });
};


const adminFilterListTransExport = async (params: any) => {
    return await apiReal.get('/transactions/history/export', {
        params,
        responseType: 'blob', // Quan trọng để lấy dữ liệu file
    }).then((response: any) => {
        return response;
    });
};


const adminChangePwd = async (currentPassword: any, newPassword: any) => {
    const body = { currentPassword, newPassword };
    return await api.post('/account/change-password', body).then((response) => {
        return response;
    });
};

const adminForControl = async (tuNgay: any, denNgay: any, partnerId: any, providerId: any, productType: any, type: any) => {
    const params = { tuNgay, denNgay, partnerId, providerId, productType, type };
    return await api.get('/transactions/for-control', { params }).then((response) => {
        return response.data;
    });
};

const adminCallBackData = async (tranId: any) => {
    return await api.post(`/admin-services/${tranId}/call-back-again`).then((response) => {
        return response.data;
    });
};

const checkAndUpdateTrans = async (tranId: any) => {
    return await api.post(`/admin-services/${tranId}/check-and-update-trans`).then((response) => {
        return response.data;
    });
};

const filterRateChangeSchedule = async (page: number, size: number, type: any, objectId: any, productId: any, status: any) => {
    const params = { page, size, type, objectId, productId, status };
    return await api.get('/rate-change-schedules/filter', { params }).then((response: any) => {
        return response;
    });
};

const createOrUpdateRateSchedule = async (
    id: any,
    type: any,
    objectId: any,
    discountPercent: any,
    discountAmount: any,
    feePercent: any,
    feeAmount: any,
    discountTopupPercent: any,
    discountTopupAmount: any,
    productId: any,
    timeUpdate: any
) => {
    const payLoad = { id, type, objectId, discountPercent, discountAmount, feePercent, feeAmount, discountTopupPercent, discountTopupAmount, productId, timeUpdate };
    return await api.post(`/rate-change-schedules/create-or-update`, payLoad).then((response: any) => {
        return response.data;
    });
};

const getReteChangeSchedule = async (id: any) => {
    return await api.get(`/rate-change-schedules/${id}`).then((response) => {
        return response;
    });
};

const deleteReteChangeSchedule = async (id: any) => {
    return await api.delete(`/rate-change-schedules/${id}`).then((response) => {
        return response;
    });
};

const getModeSystem = async () => {
    return await api.get('/system-settings/1000').then((response: any) => {
        return response;
    });
};

const updateModeSystem = async (valueSetting: string) => {
    const payLoad = {
        id: 1000,
        keySetting: 'UPGRADE_SYSTEM',
        valueSetting: valueSetting
    };
    return await api.patch('/system-settings/1000', payLoad).then((response: any) => {
        return response;
    });
};

const initGoogle2Fa = async () => {
    return await api.get('/admin-services/google-authy/on-init').then((response: any) => {
        return response.data;
    });
}

const onOffAuthenticatorConfirm = async (isOn: boolean, authyCode: string) => {
    const body = { isOn, authyCode };
    return await api.post('/admin-services/google-authy/on-off-confirm', body).then((response) => {
        return response.data;
    });
}

const balanceStartOfDay = async (partnerId: number, strDates: string) => {
    const params = { partnerId, strDates };
    return await api.get('/admin-services/balance-beginning-of-day', { params }).then((response: any) => {
        return response;
    });
};

export const adminService = {
    filterPartner,
    initActivePartner,
    inActivePartner,
    activePartner,
    getPartnerById,
    editPartner,
    updateIpPartner,
    filterBalanceOrder,
    createBalanceOrder,
    confirmBalanceOrder,
    cancelBalanceOrder,
    approvedBalanceOrder,
    getListProductRoot,
    createProductRoot,
    getProductRootById,
    updateProductRoot,
    activeProductRoot,
    inActiveProductRoot,
    filterProductByPartner,
    createProductPartner,
    upodateStatusProductPartner,
    updateProduct,
    createWarehouse,
    filterProvider,
    activeProvider,
    inActiveProvider,
    filterProductByProvider,
    createProductProvider,
    updateProductProvider,
    filterBalanceChange,
    editProvider,
    getProviderById,
    filterListTrans,
    adminChangePwd,
    adminForControl,
    deletePartnerInit,
    adminCallBackData,
    filterRateChangeSchedule,
    createOrUpdateRateSchedule,
    getReteChangeSchedule,
    deleteReteChangeSchedule,
    getModeSystem,
    updateModeSystem,
    checkAndUpdateTrans,
    adminFilterListTrans,
    adminFilterListTransExport,
    initGoogle2Fa,
    onOffAuthenticatorConfirm,
    balanceStartOfDay
};