import React, { useState } from 'react';
import { Modal, Checkbox, Button, Row, Col } from 'antd'

interface HeaderOption {
    label: string;
    value: string;
}
const headersOptions: HeaderOption[] = [
    { label: "Mã đối tác", value: "Mã đối tác" },
    { label: "Mã nhà CC", value: "Mã nhà CC" },
    { label: "Loại giao dịch", value: "Loại giao dịch" },
    { label: "Số lượng", value: "Số lượng" },
    { label: "Mệnh giá", value: "Mệnh giá" },
    { label: "Nhà mạng", value: "Nhà mạng" },
    { label: "SĐT thụ hưởng", value: "SĐT thụ hưởng" },
    { label: "Nhà CC", value: "Nhà CC" },
    { label: "Chiết khẩu Mua", value: "Chiết khẩu Mua" },
    { label: "Đối tác", value: "Đối tác" },
    { label: "Chiết khấu", value: "Chiết khấu" },
    { label: "Đối tác thanh toán", value: "Đối tác thanh toán" },
    { label: "Trạng thái", value: "Trạng thái" },
    { label: "Thời gian giao dịch", value: "Thời gian" },
    { label: "Thời gian hoàn thành", value: "Hoàn thành" }
];

const ExportExcelHeaderModal: React.FC<{ visible: boolean, onOk: (selectedHeaders: string[]) => void, onCancel: () => void }> = ({ visible, onOk, onCancel }) => {
    const [selectedHeaders, setSelectedHeaders] = useState<string[]>(headersOptions.map(option => option.value));

    const handleOk = () => {
        onOk(selectedHeaders);
    };

    const handleCheckboxChange = (checkedValues: any) => {
        setSelectedHeaders(checkedValues);
    };

    return (<Modal title="Chọn Header" open={visible} onOk={handleOk} onCancel={onCancel} width={800}> <Checkbox.Group style={{ width: '100%' }} defaultValue={selectedHeaders} onChange={handleCheckboxChange}> <Row> {headersOptions.map(option => (<Col span={8} key={option.value} style={{ marginBottom: '10px' }}> <Checkbox value={option.value}>{option.label}</Checkbox> </Col>))} </Row> </Checkbox.Group> </Modal>);
};

export default ExportExcelHeaderModal;