import { message } from "antd";
import ClipboardJS from "clipboard";
import dayjs from 'dayjs';

export const currencyFormat = (value: any, currency: any) => {
    const formattedValue = new Intl.NumberFormat('en-US', {
        currency: currency,
        style: 'currency',
    }).format(value);
    return formattedValue;
};

export const copyToClipboard = (textToCopy: string) => {
    const fakeButton = document.createElement('button');
    fakeButton.setAttribute('data-clipboard-text', textToCopy);
    const clipboard = new ClipboardJS(fakeButton);
    clipboard.on('success', () => {
        console.log('Text copied to clipboard');
        clipboard.destroy();
        message.success('copied');
    });

    clipboard.on('error', () => {
        console.error('Unable to copy text to clipboard');
        clipboard.destroy();
    });
    fakeButton.click();
};

export const formatNumber = (number: any) => {
    try {
        return new Intl.NumberFormat('en-US', { style: 'decimal' }).format(number);
    } catch {
        return 0;
    }
};

export const isValidVietnamPhoneNumber = (phoneNumber: string): boolean => {
    // Biểu thức chính quy kiểm tra số điện thoại Việt Nam
    const regex = /^0[0-9]{9}$/;

    const matches = phoneNumber.match(regex);

    // Kiểm tra xem chuỗi có khớp với biểu thức chính quy hay không
    return !!matches;
};

export const formatDateTime = (isoDateString: any) => {
    const dateObject = dayjs(isoDateString); // Sử dụng .utc() để xác định ngày giờ ở dạng UTC
    return dateObject.format('DD/MM/YYYY HH:mm:ss');
};

export const generateRandomString = (length: number): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};