import React, { useState } from 'react';

interface TextTruncateProps {
    text: string;
    maxWords: number;
}

const TextTruncate: React.FC<TextTruncateProps> = ({ text, maxWords }) => {
    const [isTruncated, setIsTruncated] = useState(true);

    const truncatedText = isTruncated && text.length > maxWords ? text.slice(0, maxWords) + '<strong style="color: blue;">...</strong>' : text;
    const toggleTruncate = () => {
        setIsTruncated(!isTruncated);
    };

    const renderedText = {
        __html: truncatedText
    };

    return (
        <span onClick={toggleTruncate} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }}
            dangerouslySetInnerHTML={renderedText}
        />
    );
};

export default TextTruncate;
