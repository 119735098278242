import { Breadcrumb, Button, Col, Flex, Form, Input, InputNumber, Modal, Pagination, Row, Select, Tag, message } from 'antd';
import {
  SearchOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { currencyFormat } from '../../helpers/FunctionUtils';
import { adminService } from '../../services/admin.service';
import { AppState } from '../../store';
import { AccountState } from '../../store/account/types';
import { setLoading } from '../../store/loading/actions';
import { ButtonStyled1, PagingStyled, TableContainer, TableContentStyled } from '../../components/CustomAntStyled';

export const DepositPage = () => {
  const accountState = useSelector<AppState>((state) => state.account) as AccountState;
  const dispatch = useDispatch();

  const ITEMS_PER_PAGE = 10;
  const [formSearch] = Form.useForm();
  const [totalItems, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lstData, setLstData] = useState<any[]>([]);

  const getDataList = async (keySearch: any, type: any, status: any, page: number) => {
    try {
      const response = await adminService.filterBalanceOrder(page - 1, ITEMS_PER_PAGE, accountState?.user?.id, keySearch, type, status)
      if (response.status === 200) {
        setTotal(response.headers['x-total-count']);
        setLstData(response.data.data);
      } else {
        message.error({ content: response.message, duration: 3 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 3 });
    }
  };

  useEffect(() => {
    const initData = async () => {
      dispatch(setLoading(true));
      try {
        await getDataList(null, null, null, currentPage);
      }
      catch (error: any) {
        message.error({ content: error.message as string, duration: 2 });
      } finally {
        dispatch(setLoading(false));
      }
    };
    initData();
  }, []);

  const onPageChanged = async (pageNumber: number) => {
    dispatch(setLoading(true));
    try {
      setCurrentPage(pageNumber);
      const values = await formSearch.validateFields();
      await getDataList(values.keySearch, values.type, values.status, pageNumber);
    } catch (error) {
      message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: <p>Loại lệnh</p>,
      dataIndex: 'balanceOrderType',
      className: 'center-data',
      render: (balanceOrderType: any) =>
        <Tag color={balanceOrderType === 'NAP' ? 'rgb(52 166 55)' : 'rgb(255 181 42)'}>
          {balanceOrderType === 'NAP' ? 'Nạp tiền' : 'Rút tiền'}
        </Tag>,
    },
    {
      title: <p>Số trước</p>,
      dataIndex: 'balanceOld',
      render: (balanceOld: any) => <span>{balanceOld ? currencyFormat(balanceOld, 'VND') : '--'}</span>,
    },
    {
      title: <p>Số tiền</p>,
      dataIndex: 'amount',
      render: (amount: any) => <span>{currencyFormat(amount, 'VND')}</span>,
    },
    {
      title: <p>Số sau</p>,
      dataIndex: 'balanceNew',
      render: (balanceNew: any) => <span>{
        balanceNew ? currencyFormat(balanceNew, 'VND') : '--'
      }</span>,
    },
    {
      title: <p>Mô tả</p>,
      dataIndex: 'descriptions',
      render: (descriptions: any) => <span>{
        descriptions ? descriptions : '--'
      }</span>,
    },
    {
      title: <p>Người tạo</p>,
      dataIndex: 'createBy',
      className: 'center-data',
      render: (createBy: any) => <span>{
        createBy ? createBy : '--'
      }</span>,
    },
    {
      title: <p>Người<br />xác nhận</p>,
      dataIndex: 'confirmBy',
      className: 'center-data',
      render: (confirmBy: any) => <span>{
        confirmBy ? confirmBy : '--'
      }</span>,
    },
    {
      title: <p>Người<br />phê duyệt</p>,
      dataIndex: 'confirmBy',
      className: 'center-data',
      render: (confirmBy: any) => <span>{
        confirmBy ? confirmBy : '--'
      }</span>,
    },
    {
      title: <p>Trạng thái</p>,
      dataIndex: 'balanceOrderStatus',
      className: 'center-data',
      render: (balanceOrderStatus: string) =>
        <Tag color={balanceOrderStatus === 'KHOI_TAO' ? 'rgb(225 228 15)'
          : balanceOrderStatus === 'XAC_NHAN' ? 'rgb(2 0 255)'
            : balanceOrderStatus === 'THANH_CONG' ? 'rgb(0 177 28)'
              : 'rgb(255 132 132 / 88%)'}>
          {
            balanceOrderStatus === 'KHOI_TAO' ? 'Mới tạo'
              : balanceOrderStatus === 'XAC_NHAN' ? 'Đã xác nhận'
                : balanceOrderStatus === 'THANH_CONG' ? 'Đã duyệt'
                  : 'Đã hủy'
          }
        </Tag>
    }
  ];

  const [formNew] = Form.useForm();
  const [showNewLenh, setShowNewLenh] = useState(false);
  const onSubmitNew = async (values: any) => {
    dispatch(setLoading(true));
    try {
      const response = await adminService.createBalanceOrder(accountState?.user?.id, values.type, values.amount, values.descriptions);
      if (response.code === 0) {
        setShowNewLenh(false);
        message.success(response.message);
        formNew.resetFields();
        onPageChanged(1);
      } else {
        message.error({ content: response.message, duration: 3 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 1 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Fragment>
      <Modal
        title={'Tạo lệnh Nạp/Rút'}
        open={showNewLenh}
        onCancel={() => setShowNewLenh(false)}
        maskClosable={false}
        footer={[
          <Flex gap="small" justify='flex-end'>
            <Button onClick={() => formNew.submit()} type="primary">Tạo lệnh</Button>
            <Button onClick={() => setShowNewLenh(false)} type="default">
              Cancel
            </Button>
          </Flex>
        ]}
        width={750}>
        <Form form={formNew}
          layout="vertical"
          onFinish={onSubmitNew}>
          <Row gutter={[20, 20]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item
                name="type"
                label="Loại lệnh" rules={[
                  {
                    required: true,
                    message: "Chọn loại lệnh"
                  }
                ]}>
                <Select size='large' value={'NAP'} style={{ width: '100%' }}>
                  <Select.Option key={'NAP'} value='NAP'>
                    Nạp tiền
                  </Select.Option>
                  <Select.Option key={'RUT'} value='RUT'>
                    Rút tiền
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item label="Số tiền"
                name="amount"
                rules={[
                  { required: true, message: 'Nhập số tiền' }
                ]}>
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  size='large'
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
              <Form.Item label="Mô tả lệnh"
                name="descriptions"
                rules={[
                  { required: true, message: 'Nhập mô tả' }
                ]}>
                <Input size='large' autoComplete='off' />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Breadcrumb style={{ marginBottom: '10px' }}>
        <Breadcrumb.Item>Lệnh số dư</Breadcrumb.Item>
        <Breadcrumb.Item>Lệnh nạp</Breadcrumb.Item>
      </Breadcrumb>

      <Form form={formSearch}
        layout='vertical'>
        <Row gutter={[20, 20]}>

          <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
            <Form.Item name="type"
              label="Loại lệnh">
              <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                placeholder="Trạng thái">
                <Select.Option key={''} value='ALL'>
                  -- All --
                </Select.Option>
                <Select.Option key={'NAP'} value='NAP'>
                  Nạp tiền
                </Select.Option>
                <Select.Option key={'RUT'} value='RUT'>
                  Rút tiền
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
            <Form.Item name="status"
              label="Trạng thái">
              <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                placeholder="Trạng thái">
                <Select.Option key={''} value='ALL'>
                  -- All --
                </Select.Option>
                <Select.Option key={'INIT'} value='INIT'>
                  Đăng ký mới
                </Select.Option>
                <Select.Option key={'ACTIVE'} value='ACTIVE'>
                  Hoạt động
                </Select.Option>
                <Select.Option key={'INACTIVE'} value='INACTIVE'>
                  Tạm khóa
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
            <Form.Item name="keySearch"
              label="Từ khóa">
              <Input size='large' placeholder='Mô tả' autoComplete='off' />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" style={{ marginBottom: '10px' }}>
          <ButtonStyled1 onClick={() => setShowNewLenh(true)} style={{ marginRight: '10px' }} type='primary' icon={<PlusOutlined />}>
            Tạo lệnh
          </ButtonStyled1>
          <Button onClick={() => onPageChanged(1)} type="primary" icon={<SearchOutlined />}>
            Search
          </Button>
        </Row>
      </Form>
      <TableContainer>
        <TableContentStyled
          columns={columns}
          bordered
          dataSource={lstData}
          rowKey="id"
          pagination={false} />

        <PagingStyled>
          <Pagination
            current={currentPage}
            total={totalItems}
            pageSize={ITEMS_PER_PAGE}
            onChange={onPageChanged}
            showSizeChanger={false}
          />
        </PagingStyled>
      </TableContainer>
    </Fragment>
  )
}
