import {
    AccountActionTypes,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    AUTHY_VERIFY,
    LOG_OUT,
} from './types';

import { publicService } from './../../services/public.service';
export const login = (userName: string, password: string, otp: string) => {
    return async (dispatch: React.Dispatch<AccountActionTypes>) => {
        dispatch({
            type: LOGIN_REQUEST,
            payload: {
                userName: userName,
                password: password,
            },
        });
        try {
            let response;
            if (otp) {
                response = await publicService.authenticateVerify(userName, password, otp);
            } else {
                response = await publicService.authenticate(userName, password, true);
            }
            if (response.code === 0) {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: {
                        token: response.data.jwt,
                        user: response.data
                    },
                });
            } else if (response.code === 2) {
                dispatch({
                    type: AUTHY_VERIFY,
                    payload: {
                        userName: userName,
                        password: password,
                    },
                });
            } else {
                dispatch({
                    type: LOGIN_FAILURE,
                    payload: {
                        error: response.message
                    },
                });
            }
        } catch (error: any) {
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.toString();
            dispatch({
                type: LOGIN_FAILURE,
                payload: {
                    error: message
                },
            });
        }
    };
};

export const logout = (): AccountActionTypes => {
    return {
        type: LOG_OUT, payload: {
            token: '',
            userName: '',
            password: '',
            user: null
        },
    };
};