import React, { Fragment, useEffect, useState } from 'react'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { adminService } from '../../services/admin.service';
import { Button, Col, Flex, Form, Input, InputNumber, Modal, Pagination, Row, Select, Tag, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { currencyFormat } from '../../helpers/FunctionUtils';
import { MdLock, MdModeEdit } from 'react-icons/md';
import { FaUnlock } from 'react-icons/fa';
import DropdownActions from '../../components/DropdownActions';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { ButtonStyled1, PagingStyled, TableContainer, TableContentStyled } from '../../components/CustomAntStyled';
import Swal from 'sweetalert2';

export const ProviderDiscountPage = () => {
  const dispatch = useDispatch();

  const [formSearch] = Form.useForm();
  const [lstProvider, setLstProvider] = useState<any[]>([]);
  const [lstProductRoot, setLstProductRoot] = useState<any[]>([]);

  const ITEMS_PER_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotal] = useState(0);
  const [lstData, setLstData] = useState<any[]>([]);

  const columns: ColumnsType<any> = [
    {
      title: <p>Nhà cung cấp</p>,
      dataIndex: 'provider',
      className: 'center-data',
      render: (provider: any) => <span>{provider?.user?.firstName}</span>,
    },
    {
      title: <p>Nhà mạng</p>,
      dataIndex: 'productRoot',
      className: 'center-data',
      render: (productRoot: any) => <span>{productRoot.type}</span>,
    },
    {
      title: <p>Mã sản phẩm</p>,
      dataIndex: 'productRoot',
      className: 'center-data',
      render: (productRoot: any) => <span>{productRoot.productCode}</span>,
    },
    {
      title: <p>Tên</p>,
      dataIndex: 'productRoot',
      className: 'center-data',
      render: (productRoot: any) => <span>{productRoot.name}</span>,
    },
    {
      title: <p>Mệnh giá</p>,
      dataIndex: 'productRoot',
      className: 'center-data',
      render: (productRoot: any) => <span>{currencyFormat(productRoot.cardAmount, 'VND')}</span>,
    },
    {
      title: <p>Chiết khấu<br />topup (%)</p>,
      dataIndex: 'discountTopupPercent',
      className: 'center-data',
      render: (discountTopupPercent: any) => <span>{`${discountTopupPercent} %`}</span>,
    },
    {
      title: <p>Chiết khấu<br />mua thẻ (%)</p>,
      dataIndex: 'discountPercent',
      className: 'center-data',
      render: (discountPercent: any) => <span>{`${discountPercent} %`}</span>,
    },
    {
      title: <p>Trạng thái</p>,
      dataIndex: 'status',
      className: 'center-data',
      render: (status: string) =>
        <Tag color={status === 'ACTIVE' ? 'rgb(52 166 55)' : 'rgba(206, 206, 206, 1)'}>
          {status === 'ACTIVE' ? 'Hoạt động' : 'Tạm khóa'}
        </Tag>
    },
    {
      title: <p>Actions</p>,
      dataIndex: 'id',
      className: 'action-dots',
      render: (id: any, record: any) =>
        renderActionModal(record)
      ,
    },
  ];

  const [formEditProduct] = Form.useForm();
  const [showEditProduct, setShowEditProduct] = useState(false);
  const handleEdit = (id: any, providerName: any, productRootName: any, discountPercent: any, discountTopupPercent: any, providerId: any) => async () => {
    dispatch(setLoading(true));
    try {
      formEditProduct.setFieldsValue({
        productId: id,
        providerName: providerName,
        productRootName: productRootName,
        discountPercent: discountPercent,
        discountTopupPercent: discountTopupPercent,
        providerId: providerId
      });
      setShowEditProduct(true);
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 5 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onSubmitEditProduct = async (values: any) => {
    dispatch(setLoading(true));
    try {
      const response = await adminService.updateProductProvider(
        values.productId,
        values.discountPercent,
        values.discountTopupPercent,
      )
      if (response.status === 200) {
        setShowEditProduct(false);
        formEditProduct.resetFields();

        formSearch.setFieldsValue({
          providerId: values.providerId,
        })
        onPageChanged(currentPage);
        message.success({ content: "Cập nhật thành công", duration: 3 });
      } else {
        message.error({ content: response.message, duration: 5 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 1 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleActiveProduct = (id: any) => async () => {
    const result = await Swal.fire({
      title: 'Xác nhận mở khóa sản phẩm',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Mở khóa',
      cancelButtonText: 'Hủy',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      dispatch(setLoading(true));
      try {
        const response = await adminService.upodateStatusProductPartner(id, 'ACTIVE');
        if (response.code === 0) {
          onPageChanged(currentPage);
          message.success({ content: response.message, duration: 3 });
        } else {
          message.error({ content: response.message, duration: 5 });
        }
      } catch (error: any) {
        message.error({ content: error.message as string, duration: 5 });
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const handleLockProduct = (id: any) => async () => {
    const result = await Swal.fire({
      title: 'Xác nhận khóa sản phẩm',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'red',
      confirmButtonText: 'Khóa',
      cancelButtonText: 'Hủy',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      dispatch(setLoading(true));
      try {
        const response = await adminService.upodateStatusProductPartner(id, 'INACTIVE');
        if (response.code === 0) {
          onPageChanged(currentPage);
          message.success({ content: response.message, duration: 3 });
        } else {
          message.error({ content: response.message, duration: 5 });
        }
      } catch (error: any) {
        message.error({ content: error.message as string, duration: 5 });
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const renderActionModal = (item: any) => {
    const props = [
      {
        icon: <MdModeEdit size={20} color='#006aff' />,
        label: 'Edit',
        onClick: () => handleEdit(item.id, item.provider.user.firstName, item.productRoot.name,
          item.discountPercent, item.discountTopupPercent, item.provider.id),
      },
      (item.status === 'INACTIVE')
        ? {

          icon: <FaUnlock size={20} color='green' />,
          label: 'Mở khóa',
          onClick: () => handleActiveProduct(item.id),
        }
        :
        {
          icon: <MdLock size={20} color='#34343' />,
          label: 'Tạm đóng',
          onClick: () => handleLockProduct(item.id),
        },
    ];
    return <DropdownActions items={props} />;
  };

  const getDataList = async (providerId: any, keySearch: any, type: any, status: any, page: number) => {
    try {
      const response = await adminService.filterProductByProvider(page - 1, ITEMS_PER_PAGE, providerId, keySearch, type, status);
      if (response.status === 200) {
        setTotal(response.headers['x-total-count']);
        setLstData(response.data.data);
      } else {
        message.error({ content: response.message, duration: 3 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 3 });
    }
  };

  useEffect(() => {
    const initData = async () => {
      dispatch(setLoading(true));
      try {
        const response = await adminService.filterProvider(0, 9999, null, null)
        if (response.status === 200) {
          setLstProvider(response.data.data);
        } else {
          message.error({ content: response.message, duration: 3 });
        }

        const response1 = await adminService.getListProductRoot(0, 9999, null, null)
        if (response1.status === 200) {
          setLstProductRoot(response1.data.data);
        } else {
          message.error({ content: response1.message, duration: 3 });
        }

      }
      catch (error: any) {
        message.error({ content: error.message as string, duration: 2 });
      } finally {
        dispatch(setLoading(false));
      }
    };
    initData();
  }, []);

  const onPageChanged = async (pageNumber: number) => {
    dispatch(setLoading(true));
    try {
      setCurrentPage(pageNumber);
      const values = await formSearch.validateFields();
      await getDataList(values.providerId, values.keySearch, values.type, values.status, pageNumber);
    } catch (error) {
      message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const [formCreateProduct] = Form.useForm();
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const onSubmitCreateProduct = async (values: any) => {
    dispatch(setLoading(true));
    try {
      const response = await adminService.createProductProvider(values.productRootId, values.providerId, values.discountPercent, values.discountTopupPercent)
      if (response.code === 0) {
        // setShowCreateProduct(false);
        // formCreateProduct.resetFields();
        formSearch.setFieldsValue({
          providerId: values.providerId,
        })
        onPageChanged(currentPage);
        message.success({ content: "Thêm mới thành công", duration: 3 });
      } else {
        message.error({ content: response.message, duration: 5 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 5 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Fragment>
      <Modal
        title={'Thêm mới chiết khấu từ nhà cung cấp'}
        open={showCreateProduct}
        onCancel={() => setShowCreateProduct(false)}
        maskClosable={false}
        footer={[
          <Flex gap="small" justify='flex-end'>
            <Button onClick={() => formCreateProduct.submit()} type="primary">Tạo mới</Button>
            <Button onClick={() => setShowCreateProduct(false)} type="default">
              Cancel
            </Button>
          </Flex>
        ]}
        width={720}>
        <Form form={formCreateProduct}
          layout="vertical"
          onFinish={onSubmitCreateProduct}>

          <Row gutter={[20, 20]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item
                name="providerId"
                label="Nhà cung cấp"
                rules={[
                  {
                    required: true,
                    message: "Nhà cung cấp"
                  }
                ]}>
                <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                  <option value="" label=''>-- All --</option>
                  {lstProvider?.map((item: any, index) => (
                    <Select.Option key={index} value={item.id} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item
                name="productRootId"
                label="Dịch vụ"
                rules={[
                  {
                    required: true,
                    message: "Chọn dịch vụ gốc"
                  }
                ]}>
                <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                  {lstProductRoot?.map((item: any, index) => (
                    <Select.Option key={index} value={item.id} label={item.name}>{item.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item label="Chiết khấu topup"
                name="discountTopupPercent"
                rules={[
                  { required: true, message: 'Nhập chiết khấu topup' }
                ]}>
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  size='large'
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item label="Chiết khấu mua thẻ"
                name="discountPercent"
                rules={[
                  { required: true, message: 'Nhập chiết khấu mua thẻ' }
                ]}>
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  size='large'
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={'Cập nhật chiết khấu từ nhà cung cấp'}
        open={showEditProduct}
        onCancel={() => setShowEditProduct(false)}
        maskClosable={false}
        footer={[
          <Flex gap="small" justify='flex-end'>
            <Button onClick={() => formEditProduct.submit()} type="primary">Cập nhật</Button>
            <Button onClick={() => setShowEditProduct(false)} type="default">
              Cancel
            </Button>
          </Flex>
        ]}
        width={720}>
        <Form form={formEditProduct}
          layout="vertical"
          onFinish={onSubmitEditProduct}>
          <Form.Item name="providerId" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name="productId" hidden={true}>
            <Input />
          </Form.Item>
          <Row gutter={[20, 20]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item
                name="providerName"
                label="Nhà cung cấp">
                <Input size='large' disabled />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item
                name="productRootName"
                label="Dịch vụ">
                <Input size='large' disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item label="Chiết khấu topup tính bằng %"
                name="discountTopupPercent"
                rules={[
                  { required: true, message: 'Nhập chiết khấu topup tính bằng %' }
                ]}>
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  size='large'
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item label="Chiết khấu mua thẻ tính bằng %"
                name="discountPercent"
                rules={[
                  { required: true, message: 'Nhập chiết khấu tính bằng %' }
                ]}>
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  size='large'
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Form form={formSearch}
        layout='vertical'>
        <Row gutter={[20, 20]}>
          <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="providerId"
              label="Nhà cung cấp"
              rules={[
                {
                  required: true,
                  message: "Chọn nhà cung cấp"
                }
              ]}>
              <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                <option value="" label=''>-- All --</option>
                {lstProvider?.map((item: any, index) => (
                  <Select.Option key={index} value={item.id} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="type" label="Nhà mạng">
              <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}>
                <Select.Option key={'ALL'} value='ALL'>
                  -- All --
                </Select.Option>
                <Select.Option key={'VIETTEL'} value='VIETTEL'>
                  VIETTEL
                </Select.Option>
                <Select.Option key={'VINAPHONE'} value='VINAPHONE'>
                  VINAPHONE
                </Select.Option>
                <Select.Option key={'MOBIFONE'} value='MOBIFONE'>
                  MOBIFONE
                </Select.Option>
                <Select.Option key={'VIETNAMOBILE'} value='VIETNAMOBILE'>
                  VIETNAMOBILE
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="status"
              label="Trạng thái">
              <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                placeholder="Trạng thái">
                <Select.Option key={''} value='ALL'>
                  -- All --
                </Select.Option>
                <Select.Option key={'ACTIVE'} value='ACTIVE'>
                  Hoạt động
                </Select.Option>
                <Select.Option key={'INACTIVE'} value='INACTIVE'>
                  Tạm khóa
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item name="keySearch"
              label="Từ khóa">
              <Input size='large' placeholder='Tên/ SĐT/ Email' autoComplete='off' />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" style={{ marginBottom: '10px' }}>
          <ButtonStyled1 onClick={() => setShowCreateProduct(true)} style={{ marginRight: '10px' }} type='primary' icon={<PlusOutlined />}>
            Thêm mới
          </ButtonStyled1>
          <Button onClick={() => onPageChanged(1)} type="primary" icon={<SearchOutlined />}>
            Search
          </Button>
        </Row>
      </Form>

      <TableContainer>
        <TableContentStyled
          columns={columns}
          bordered
          dataSource={lstData}
          rowKey="id"
          pagination={false} />

        <PagingStyled>
          <Pagination
            current={currentPage}
            total={totalItems}
            pageSize={ITEMS_PER_PAGE}
            onChange={onPageChanged}
            showSizeChanger={false}
          />
        </PagingStyled>
      </TableContainer>
    </Fragment>
  )
}
