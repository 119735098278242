import React, { Fragment, useEffect, useState } from 'react'
import { Breadcrumb, Button, Col, Flex, Form, Input, Modal, Pagination, Row, Select, Tag, message } from 'antd'
import {
    SearchOutlined
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { adminService } from '../../services/admin.service';
import { ColumnsType } from 'antd/es/table';
import { currencyFormat } from '../../helpers/FunctionUtils';
import DropdownActions from '../../components/DropdownActions';
import { FaUnlock } from 'react-icons/fa';
import { MdLock, MdModeEdit } from 'react-icons/md';
import { PagingStyled, TableContainer, TableContentStyled } from '../../components/CustomAntStyled';
import Swal from 'sweetalert2';

export const ProviderListPage = () => {
    const dispatch = useDispatch();
    const [formSearch] = Form.useForm();

    const ITEMS_PER_PAGE = 20;
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lstData, setLstData] = useState<any[]>([]);

    useEffect(() => {
        const initData = async () => {
            dispatch(setLoading(true));
            try {
                await getDataList(null, null, currentPage);
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }
        };
        initData();
    }, []);

    const getDataList = async (keySearch: any, status: any, page: number) => {
        try {
            const response = await adminService.filterProvider(page - 1, ITEMS_PER_PAGE, keySearch, status)
            if (response.status === 200) {
                setTotal(response.headers['x-total-count']);
                setLstData(response.data.data);
            } else {
                message.error({ content: response.message, duration: 3 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 5 });
        }
    };

    const onPageChanged = async (pageNumber: number) => {
        dispatch(setLoading(true));
        try {
            setCurrentPage(pageNumber);
            const values = await formSearch.validateFields();
            await getDataList(values.keySearch, values.status, pageNumber);
        } catch (error) {
            message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleLockAccount = (id: any) => async () => {
        const result = await Swal.fire({
            title: 'Xác nhận tạm đóng nhà cung cấp',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'red',
            confirmButtonText: 'Khóa',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await adminService.inActiveProvider(id);
                if (response.code === 0) {
                    onPageChanged(currentPage);
                    message.success({ content: response.message, duration: 3 });
                } else {
                    message.error({ content: response.message, duration: 5 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 5 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handleActivePartner = (id: any) => async () => {
        const result = await Swal.fire({
            title: 'Xác mở Khóa nhà Cung cấp',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Mở khóa',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await adminService.activeProvider(id);
                if (response.code === 0) {
                    onPageChanged(currentPage);
                    message.success({ content: response.message, duration: 3 });
                } else {
                    message.error({ content: response.message, duration: 5 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handleEdit = (id: any) => async () => {
        dispatch(setLoading(true));
        try {
            const response = await adminService.getProviderById(id);
            if (response.status === 200) {
                formEditProvider.setFieldsValue({
                    providerId: id,
                    firstName: response.data.user.firstName,
                    balanceReminder: response.data.balanceUsed,
                    maxTimeOut: response.data.maxTimeOut,
                });
                setShowEditProvider(true);
            } else {
                message.error({ content: response.statusText, duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 5 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const renderActionModal = (item: any) => {
        const props = [
            {
                icon: <MdModeEdit size={20} color='#006aff' />,
                label: 'Edit',
                onClick: () => handleEdit(item.id),
            },
            (item.status === 'INACTIVE' || item.status === 'INIT')
                ? {

                    icon: <FaUnlock size={20} color='green' />,
                    label: 'Mở khóa',
                    onClick: () => handleActivePartner(item.id),
                }
                :
                {
                    icon: <MdLock size={20} color='#34343' />,
                    label: 'Tạm khóa',
                    onClick: () => handleLockAccount(item.id),
                },
        ];

        return <DropdownActions items={props} />;
    };

    const columns: ColumnsType<any> = [
        {
            title: <p>Tên<br /> nhà CC</p>,
            dataIndex: 'user',
            render: (user: any) =>
                <span>
                    {user.firstName} <br />
                </span>,
        },
        {
            title: <p>Số dư<br /> khả dụng</p>,
            dataIndex: 'balance',
            render: (balance: any) => <span>{currencyFormat(balance, 'VND')}</span>,
        },
        {
            title: <p>Cảnh báo<br /> số dư</p>,
            dataIndex: 'balanceUsed',
            render: (balanceUsed: any) => <span>{currencyFormat(balanceUsed, 'VND')}</span>,
        },
        {
            title: <p>Max giao dịch<br /> chờ kết quả</p>,
            dataIndex: 'maxTimeOut',
            render: (maxTimeOut: any) => <span>{maxTimeOut && maxTimeOut > 0 ? `${maxTimeOut} giao dịch` : 'Vô hạn'}</span>,
        },
        {
            title: <p>Trạng thái</p>,
            dataIndex: 'status',
            render: (status: string) =>
                <Tag color={status === 'ACTIVE' ? 'rgb(52 166 55)' : status === 'INACTIVE' ? 'rgba(206, 206, 206, 1)' : 'rgb(255 181 42)'}>
                    {status === 'ACTIVE' ? 'Hoạt động' : status === 'INACTIVE' ? 'Tạm khóa' : 'Mới tạo'}
                </Tag>
        },
        {
            title: <p>Actions</p>,
            dataIndex: 'id',
            className: 'action-dots',
            render: (id: any, record: any) =>
                renderActionModal(record)
            ,
        },
    ];

    const [formEditProvider] = Form.useForm();
    const [showEditProvider, setShowEditProvider] = useState(false);
    const onSubmitEditPartner = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await adminService.editProvider(values.providerId, values.firstName, values.balanceReminder, values.maxTimeOut)
            if (response.code === 0) {
                onPageChanged(currentPage);
                setShowEditProvider(false);
                message.success({ content: response.message, duration: 3 });
            } else {
                message.error({ content: response.message, duration: 5 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <Fragment>
            <Modal
                title={'Cập nhật thông tin nhà cung cấp'}
                open={showEditProvider}
                onCancel={() => setShowEditProvider(false)}
                maskClosable={false}
                footer={[
                    <Flex gap="small" justify='flex-end'>
                        <Button onClick={() => formEditProvider.submit()} type="primary">Xác nhận</Button>
                        <Button onClick={() => setShowEditProvider(false)} type="default">
                            Cancel
                        </Button>
                    </Flex>
                ]}
                width={720}>
                <Form form={formEditProvider}
                    layout="vertical"
                    onFinish={onSubmitEditPartner}>
                    <Form.Item
                        name="providerId"
                        hidden={true}>
                        <Input />
                    </Form.Item>

                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="firstName"
                                label="Tên đối tác"
                                rules={[
                                    { required: true, message: 'Nhập tên đối tác' }
                                ]}
                            >
                                <Input size='large' autoComplete='off' />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="balanceReminder"
                                label="Cảnh báo số dư" rules={[
                                    { required: true, message: 'Nhập cảnh báo số dư' }
                                ]}>
                                <Input type="number" size='large' autoComplete='off' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                            <Form.Item
                                name="maxTimeOut"
                                label="Max time chờ kết quả (nhập 0 sẽ vô thơi hạn)" rules={[
                                    { required: true, message: 'Nhập thời gian chờ kết quả' }
                                ]}>
                                <Input min={0} type="number" size='large' autoComplete='off' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Breadcrumb style={{ marginBottom: '10px' }}>
                <Breadcrumb.Item>Nhà cung cấp</Breadcrumb.Item>
                <Breadcrumb.Item>Danh sách</Breadcrumb.Item>
            </Breadcrumb>

            <Form form={formSearch}>
                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item name="keySearch">
                            <Input size='large' placeholder='Tên/ SĐT/ Email' />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item name="status">
                            <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                                placeholder="Trạng thái">
                                <Select.Option key={''} value='ALL'>
                                    -- All --
                                </Select.Option>
                                <Select.Option key={'INIT'} value='INIT'>
                                    Đăng ký mới
                                </Select.Option>
                                <Select.Option key={'ACTIVE'} value='ACTIVE'>
                                    Hoạt động
                                </Select.Option>
                                <Select.Option key={'INACTIVE'} value='INACTIVE'>
                                    Tạm khóa
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end" style={{ marginBottom: '10px' }}>
                    <Button onClick={() => onPageChanged(1)} type="primary" icon={<SearchOutlined />}>
                        Search
                    </Button>
                </Row>
            </Form>

            <TableContainer>
                <TableContentStyled
                    columns={columns}
                    dataSource={lstData}
                    rowKey="id"
                    bordered
                    pagination={false} />

                <PagingStyled>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={ITEMS_PER_PAGE}
                        onChange={onPageChanged}
                        showSizeChanger={false}
                    />
                </PagingStyled>
            </TableContainer>
        </Fragment>
    )
}
