import { api } from '../helpers';

const authenticate = async (username: string, password: string, rememberMe: boolean) => {
    const body = { username, password, rememberMe };
    return await api.post('/authenticate/link-pay', body).then((response) => {
        return response.data;
    });
};

const authenticateVerify = async (username: string, password: string, authyCode: string) => {
    const body = { username, password, authyCode };
    return await api.post('/authenticate/link-pay/google-authy-verify', body).then((response) => {
        return response.data;
    });
};

export const publicService = {
    authenticate,
    authenticateVerify
};