import { Button, Col, DatePicker, Flex, Form, Input, InputNumber, Modal, Pagination, Row, Select, Tag, message } from 'antd';
import React, { Fragment, useEffect, useState } from 'react'
import {
  SearchOutlined,
  DeleteOutlined,
  PlusOutlined,
  EditOutlined
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { adminService } from '../../services/admin.service';
import { ColumnsType } from 'antd/es/table';
import { formatDateTime } from '../../helpers/FunctionUtils';
import { ButtonStyled1, PagingStyled, TableContainer, TableContentStyled } from '../../components/CustomAntStyled';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

export const RateChangeSchedulePartnerTab = () => {
  const dispatch = useDispatch();
  const [lstPartner, setLstPartner] = useState<any[]>([]);

  const [formSearch] = Form.useForm();
  const ITEMS_PER_PAGE = 10;
  const [totalItems, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lstData, setLstData] = useState<any[]>([]);

  const getDataList = async (page: number, objectId: any, productId: any, status: any) => {
    try {
      const response = await adminService.filterRateChangeSchedule(page - 1, ITEMS_PER_PAGE, 'PARTNER', objectId, productId, status);
      if (response.status === 200) {
        setTotal(response.headers['x-total-count']);
        setLstData(response.data);
      } else {
        message.error({ content: response.message, duration: 3 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 5 });
    }
  };

  const onPageChanged = async (pageNumber: number) => {
    dispatch(setLoading(true));
    try {
      setCurrentPage(pageNumber);
      const values = await formSearch.validateFields();
      await getDataList(pageNumber, values.objectId, values.productId, values.status);
    } catch (error) {
      message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    const initData = async () => {
      dispatch(setLoading(true));
      try {
        const resPartner = await adminService.filterPartner(0, 9999, null, null, 0)
        if (resPartner.status === 200) {
          setLstPartner(resPartner.data.data);
        } else {
          message.error({ content: resPartner.message, duration: 3 });
        }
        onPageChanged(1);
      }
      catch (error: any) {
        message.error({ content: error.message as string, duration: 2 });
      } finally {
        dispatch(setLoading(false));
      }
    };
    initData();
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: <p>Tên đối tác</p>,
      dataIndex: 'product',
      render: (product: any) =>
        <span>{product?.partner?.user?.firstName}</span>,
    },
    {
      title: <p>Dịch vụ</p>,
      dataIndex: 'product',
      className: 'center-data',
      render: (product: any) =>
        <span>{product?.productRoot?.name}</span>,
    },
    {
      title: <p>Chiết khấu mới<br />topup (%)</p>,
      dataIndex: 'discountTopupPercent',
      className: 'center-data',
      render: (discountTopupPercent: any) => <span>{`${discountTopupPercent} %`}</span>,
    },
    {
      title: <p>Chiết khấu mới<br />mua thẻ (%)</p>,
      dataIndex: 'discountPercent',
      className: 'center-data',
      render: (discountPercent: any) => <span>{`${discountPercent} %`}</span>,
    },
    {
      title: <p>Thời gian cập nhật</p>,
      dataIndex: 'timeUpdate',
      className: 'center-data',
      render: (timeUpdate: any) => <span>{formatDateTime(timeUpdate)}</span>,
    },
    {
      title: <p>Trạng thái</p>,
      dataIndex: 'transactionStatus',
      className: 'center-data',
      render: (status: string, record: any) =>
        <Fragment>
          <Tag color={status === 'SUCCESS' ? 'rgb(52 166 55)'
            : status === 'PROCESSING' ? '#FFFF00'
              : status === 'PAUSE' ? '#CC0000' : '#0000FF'}>
            {
              status === 'THANH_CONG' ? 'Thành công'
                : status === 'PROCESSING' ? 'Đang xử lý'
                  : status === 'PAUSE' ? 'Đã hủy' : 'Mới khởi tạo'
            }
          </Tag><br />
        </Fragment>
    },
    {
      title: <p>Actions</p>,
      dataIndex: 'id',
      className: 'center-data',
      render: (id: any) =>
        <Fragment>
          <Button style={{ marginRight: '5px', color: 'red' }} onClick={handleDelete(id)} icon={<DeleteOutlined />}></Button>
          <Button type="primary" onClick={handleEdit(id)} icon={<EditOutlined />}></Button>
        </Fragment>
      ,
    },
  ];

  const [lstProductByPartner, setLstProductByPartner] = useState<any[]>([]);
  const onChangeSelectPartner = async (selectedValue: any) => {
    formCreateSchecdule.setFieldsValue({
      productId: '',
    })

    formEditSchecdule.setFieldsValue({
      productId: '',
    })
    if (selectedValue === '') {
      setLstProductByPartner([]);
      return;
    }
    try {
      dispatch(setLoading(true));
      const response = await adminService.filterProductByPartner(0, 9999, selectedValue, null, null, null);
      if (response.status === 200) {
        setLstProductByPartner(response.data.data);
      } else {
        setLstProductByPartner([]);
        message.error({ content: response.message, duration: 3 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 5 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const [formCreateSchecdule] = Form.useForm();
  const [showCreateSchedule, setShowCreateSchedule] = useState(false);
  const onSubmitCreateSchedule = async (values: any) => {
    dispatch(setLoading(true));
    try {
      const response = await adminService.createOrUpdateRateSchedule(
        values.id ? values.id : null,
        'PARTNER',
        values.objectId,
        values.discountPercent ? values.discountPercent : 0,
        values.discountAmount ? values.discountAmount : 0,
        values.feePercent ? values.feePercent : 0,
        values.feeAmount ? values.feeAmount : 0,
        values.discountTopupPercent ? values.discountTopupPercent : 0,
        values.discountTopupAmount ? values.discountTopupAmount : 0,
        values.productId ? values.productId : 0,
        formatDateTime(values.timeUpdate)
      )
      if (response.code === 0) {
        setShowCreateSchedule(false);
        formCreateSchecdule.resetFields();
        onPageChanged(currentPage);
        message.success({ content: response.message, duration: 3 });
      } else {
        message.error({ content: response.message, duration: 5 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 5 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const [formEditSchecdule] = Form.useForm();
  const [showEditSchedule, setShowEditSchedule] = useState(false);
  const onSubmitEditSchedule = async (values: any) => {
    dispatch(setLoading(true));
    try {
      const response = await adminService.createOrUpdateRateSchedule(
        values.id ? values.id : null,
        'PARTNER',
        values.objectId,
        values.discountPercent ? values.discountPercent : 0,
        values.discountAmount ? values.discountAmount : 0,
        values.feePercent ? values.feePercent : 0,
        values.feeAmount ? values.feeAmount : 0,
        values.discountTopupPercent ? values.discountTopupPercent : 0,
        values.discountTopupAmount ? values.discountTopupAmount : 0,
        values.productId ? values.productId : 0,
        formatDateTime(values.timeUpdate)
      )
      if (response.code === 0) {
        onPageChanged(currentPage);
        setShowEditSchedule(false);
        formEditSchecdule.resetFields();
        message.success({ content: response.message, duration: 3 });
      } else {
        message.error({ content: response.message, duration: 5 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 5 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleDelete = (id: any) => async () => {

    try {
      const result = await Swal.fire({
        title: 'Xác nhận Xóa',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: 'red',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        dispatch(setLoading(true));
        const resDetail = await adminService.getReteChangeSchedule(id);
        if (resDetail.status === 200) {
          if (resDetail.data.status !== 'INIT') {
            message.error({ content: "Bản ghi đang xử lý không thể thao tác", duration: 5 });
            return;
          }

          const resDelete = await adminService.deleteReteChangeSchedule(id);
          if (resDelete.status === 204) {
            message.success("Xóa thành công");
            onPageChanged(currentPage);
          } else {
            message.error("Có lỗi xin thử lại sau");
          }
        } else {
          message.error({ content: "Bản ghi đã không còn tồn tại", duration: 5 });
        }
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 5 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleEdit = (id: any) => async () => {
    dispatch(setLoading(true));
    try {
      const resDetail = await adminService.getReteChangeSchedule(id);
      if (resDetail.status === 200) {
        if (resDetail.data.status !== 'INIT') {
          message.error({ content: "Bản ghi đang xử lý không thể thao tác", duration: 5 });
          return;
        }
        onChangeSelectPartner(resDetail.data.product.partner.id);

        formEditSchecdule.setFieldsValue({
          id: resDetail.data.id,
          objectId: resDetail.data.objectId,
          discountPercent: resDetail.data.discountPercent,
          discountAmount: resDetail.data.discountAmount,
          feePercent: resDetail.data.feePercent,
          feeAmount: resDetail.data.feeAmount,
          discountTopupPercent: resDetail.data.discountTopupPercent,
          discountTopupAmount: resDetail.data.discountTopupAmount,
          productId: resDetail.data.product.id,
          timeUpdate: dayjs(resDetail.data.timeUpdate)
        });
        setShowEditSchedule(true);
      } else {
        message.error({ content: "Bản ghi đã không còn tồn tại", duration: 5 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 5 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Fragment>
      <Modal
        title={'Thêm mới lịch set chiết khấu'}
        open={showCreateSchedule}
        onCancel={() => setShowCreateSchedule(false)}
        maskClosable={false}
        footer={[
          <Flex gap="small" justify='flex-end'>
            <Button onClick={() => formCreateSchecdule.submit()} type="primary">Khởi tạo</Button>
            <Button onClick={() => setShowCreateSchedule(false)} type="default">
              Cancel
            </Button>
          </Flex>
        ]}
        width={720}>
        <Form form={formCreateSchecdule}
          layout="vertical"
          onFinish={onSubmitCreateSchedule}>
          <Row gutter={[20, 20]}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
              <Form.Item
                name="timeUpdate" // Tên của trường trong form
                label='Thời gian cập nhật'
                rules={[
                  {
                    required: true,
                    message: 'Send time is requied'
                  }
                ]}>
                <DatePicker
                  style={{ width: '100%' }}
                  size="large"
                  showTime={true}
                  inputReadOnly={true}
                  disabledDate={(current) => {
                    // Disable all dates before today
                    return current && current.isBefore(dayjs().startOf('day'), 'day');
                  }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item
                name="objectId"
                label="Đối tác"
                rules={[
                  {
                    required: true,
                    message: "Chọn đối tác"
                  }
                ]}>
                <Select defaultValue={''} size="large" showSearch optionFilterProp="label" onChange={(value) => onChangeSelectPartner(value)}>
                  <option value="" label=''>-- All --</option>
                  {lstPartner?.map((item: any, index) => (
                    <Select.Option key={index} value={item.id} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item
                name="productId"
                label="Dịch vụ"
                rules={[
                  {
                    required: true,
                    message: "Chọn dịch vụ"
                  }
                ]}>
                <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                  {lstProductByPartner?.map((item: any, index) => (
                    <Select.Option key={index} value={item.id} label={item.productRoot.name}>{item.productRoot.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>

            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item label="Chiết khấu topup"
                name="discountTopupPercent"
                rules={[
                  { required: true, message: 'Nhập chiết khấu topup' }
                ]}>
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  size='large'
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item label="Chiết khấu mua thẻ"
                name="discountPercent"
                rules={[
                  { required: true, message: 'Nhập chiết khấu mua thẻ' }
                ]}>
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  size='large'
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={'Chỉnh sửa lịch set chiết khấu'}
        open={showEditSchedule}
        onCancel={() => setShowEditSchedule(false)}
        maskClosable={false}
        footer={[
          <Flex gap="small" justify='flex-end'>
            <Button onClick={() => formEditSchecdule.submit()} type="primary">Cập nhật</Button>
            <Button onClick={() => setShowEditSchedule(false)} type="default">
              Cancel
            </Button>
          </Flex>
        ]}
        width={720}>
        <Form form={formEditSchecdule}
          layout="vertical"
          onFinish={onSubmitEditSchedule}>
          <Form.Item
            name="id"
            hidden={true}>
            <Input />
          </Form.Item>
          <Row gutter={[20, 20]}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
              <Form.Item
                name="timeUpdate"
                label='Thời gian cập nhật'
                rules={[
                  {
                    required: true,
                    message: 'Send time is requied'
                  }
                ]}>
                <DatePicker
                  style={{ width: '100%' }}
                  size="large"
                  showTime={true}
                  inputReadOnly={true}
                  disabledDate={(current) => {
                    return current && current.isBefore(dayjs().startOf('day'), 'day');
                  }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item
                name="objectId"
                label="Đối tác"
                rules={[
                  {
                    required: true,
                    message: "Chọn đối tác"
                  }
                ]}>
                <Select defaultValue={''} size="large" showSearch optionFilterProp="label" onChange={(value) => onChangeSelectPartner(value)}>
                  <option value="" label=''>-- All --</option>
                  {lstPartner?.map((item: any, index) => (
                    <Select.Option key={index} value={item.id} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item
                name="productId"
                label="Dịch vụ"
                rules={[
                  {
                    required: true,
                    message: "Chọn dịch vụ"
                  }
                ]}>
                <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                  {lstProductByPartner?.map((item: any, index) => (
                    <Select.Option key={index} value={item.id} label={item.productRoot.name}>{item.productRoot.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>

            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item label="Chiết khấu topup"
                name="discountTopupPercent"
                rules={[
                  { required: true, message: 'Nhập chiết khấu topup' }
                ]}>
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  size='large'
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <Form.Item label="Chiết khấu mua thẻ"
                name="discountPercent"
                rules={[
                  { required: true, message: 'Nhập chiết khấu mua thẻ' }
                ]}>
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  size='large'
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Form form={formSearch} layout='vertical'>
        <Row gutter={[20, 20]}>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
            <Form.Item
              name="objectId"
              label="Đối tác">
              <Select defaultValue={''} size="large" showSearch optionFilterProp="label">
                <option value="" label=''>-- All --</option>
                {lstPartner?.map((item: any, index) => (
                  <Select.Option key={index} value={item.id} dataTemp={item.user.firstName} label={item.user.firstName}>{item.user.firstName}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
            <Form.Item name="status" label="Trạng thái">
              <Select size='large' defaultValue={'ALL'} style={{ width: '100%' }}
                placeholder="Trạng thái">
                <Select.Option key={''} value='ALL'>
                  -- All --
                </Select.Option>
                <Select.Option key={'INIT'} value='INIT'>
                  Mới khởi tạo
                </Select.Option>
                <Select.Option key={'PROCESSING'} value='PROCESSING'>
                  Đang xử lý
                </Select.Option>
                <Select.Option key={'SUCCESS'} value='SUCCESS'>
                  Hoàn Thành
                </Select.Option>
                <Select.Option key={'PAUSE'} value='PAUSE'>
                  Đã hủy
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" style={{ marginBottom: '10px' }}>
          <ButtonStyled1 onClick={() => setShowCreateSchedule(true)} style={{ marginRight: '10px' }} type='primary' icon={<PlusOutlined />}>
            Thêm mới
          </ButtonStyled1>
          <Button onClick={() => onPageChanged(1)} type="primary" icon={<SearchOutlined />}>
            Search
          </Button>
        </Row>
      </Form>

      <TableContainer>
        <TableContentStyled
          columns={columns}
          bordered
          dataSource={lstData}
          rowKey="id"
          pagination={false} />

        <PagingStyled>
          <Pagination
            current={currentPage}
            total={totalItems}
            pageSize={ITEMS_PER_PAGE}
            onChange={onPageChanged}
            showSizeChanger={false}
          />
        </PagingStyled>
      </TableContainer>
    </Fragment>
  )
}
