import styled from 'styled-components';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { AppState } from '../store';

const LoadingPage = () => {
  const loading = useSelector<AppState>((state) => state.loadState.loading) as boolean;
  if (!loading) {
    return null;
  }
  return (
    <LoadingPageStyled>
      <Spin />
    </LoadingPageStyled>
  );
};

export default LoadingPage;

const LoadingPageStyled = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content:center;
  z-index: 9999;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 95px 132px;
    gap: 16px;
    background: #FFFFFF;
    border-radius: 6px;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #363565;
    }
  }
`;
